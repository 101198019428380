import { categoryType } from '../../../lib/utils';

interface IrecordTarget {
  [key: string]: {
    day: {
      label: string;
      key: string;
    }[];
    dayDetail?: {
      label: string;
      key: any;
      unit: any;
    }[];
    week?: {
      label: string;
      key: string;
    }[];
  };
}

export const recordTarget: IrecordTarget = {
  [categoryType.blood]: {
    day: [
      {
        label: '수축기 혈압',
        key: 'sbp',
      },
      {
        label: '이완기 혈압',
        key: 'dbp',
      },
      {
        label: '맥박',
        key: 'heart_rate',
      },
    ],
    week: [
      {
        label: '고혈압 횟수',
        key: 'high_blood_pressure_cnt',
      },
      {
        label: '저혈압 횟수',
        key: 'low_blood_pressure_cnt',
      },
    ],
  },
  [categoryType.sugar]: {
    day: [
      {
        label: '식전/공복 혈당',
        key: 'beforeEmptyBs',
      },
      {
        label: '식후 혈당',
        key: 'afterBs',
      },
    ],
    week: [
      {
        label: '고혈당 횟수',
        key: 'high_blood_sugar_cnt',
      },
      {
        label: '저혈당 횟수',
        key: 'low_blood_sugar_cnt',
      },
      {
        label: '식전/공복 평균혈당',
        key: 'before_avg_blood_sugar',
      },
      {
        label: '식후 평균혈당',
        key: 'after_avg_blood_sugar',
      },
    ],
  },
  [categoryType.meal]: {
    day: [
      {
        label: '섭취량',
        key: 'total_calories',
      },
    ],
  },
  [categoryType.weight]: {
    day: [
      {
        label: '체중',
        key: 'weight',
      },
    ],
    dayDetail: [
      {
        label: '체지방',
        key: 'body_fat',
        unit: 'kg',
      },
      {
        label: '골격근',
        key: 'sk_muscle_pct',
        unit: 'kg',
      },
      {
        label: '허리둘레',
        key: ['waist', 'waist_inch'],
        unit: ['cm', '인치'],
      },
    ],
  },
  [categoryType.step]: {
    day: [
      {
        label: '걸음 수',
        key: 'step_count',
      },
    ],
    dayDetail: [
      {
        label: '이동거리',
        key: 'distance',
        unit: 'km',
      },
    ],
  },
  [categoryType.exercise]: {
    day: [
      {
        label: '소모열량',
        key: 'calories_out',
      },
    ],
    dayDetail: [
      {
        label: '총 운동시간',
        key: 'duration',
        unit: '분',
      },
    ],
  },
};
