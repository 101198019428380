import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { diseaseType, numberToCategory } from '../../lib/utils';
import Calendar from './calendar';
import CategoryList from './categoryList';
import { useUser } from '../../hooks';
import { useRangeState } from './lib/hooks';
import Statistics from './statistics';

function Report() {
  const { user, userDisease } = useUser();
  const [userType] = useState(userDisease.label);
  const { time = '' } = useParams();
  const { category: categoryNumber = '' } = useParams();
  const [category, setCategory] = useState(
    numberToCategory?.[categoryNumber] ?? '',
  );
  const selectedDate = Number(time) ? new Date(parseInt(time, 10)) : new Date();
  const { weekInfo, changeWeek } = useRangeState(selectedDate);
  const navigate = useNavigate();
  const categories = diseaseType?.[user.disease_type].categories;

  useEffect(() => {
    if (!categories) navigate('/notfound');
    if (!userType || category === '') navigate('/notfound');
  }, [userType, navigate, category, categories]);

  useEffect(() => {
    document.getElementsByTagName('main')[0]?.scrollTo(0, 0);
  }, []);

  if (!userType || category === '') return null;

  return (
    <>
      <CategoryList
        category={category}
        categoryArray={categories}
        setCategory={setCategory}
      />
      <Calendar
        selectedDate={selectedDate}
        category={category}
        week={weekInfo}
        dayClickHandler={changeWeek}
      />
      <Statistics category={category} week={weekInfo} />
    </>
  );
}

export default Report;
