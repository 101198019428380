import styled from '@emotion/styled';
import { colors } from '../../../components/Styles/theme';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100vh - 5.6rem);
  justify-content: space-between;
`;
export const VesselContainer = styled.div`
  margin: 0 2rem;
  flex: 1;
`;

export const DateWrapper = styled.div`
  height: 6rem;
  p:nth-of-type(1) {
    font-size: 1.2rem;
    color: ${colors.grey_05_subtext};
  }
  p:nth-of-type(2) {
    font-size: 1.6rem;
    font-weight: bold;
    color: ${colors.black_01_basic};
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`;

export const ShowGuideButton = styled.button`
  color: ${colors.black_01_basic};
  text-decoration: underline;
  font-size: 1.2rem;
  font-weight: bold;
  margin: 2rem 0;
`;

export const Guidance = styled.div`
  color: ${colors.grey_05_subtext};
  text-align: center;
  font-size: 1rem;
  margin: 1.5rem 0;
`;
