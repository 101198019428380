import { Dispatch, SetStateAction } from 'react';
import { Button } from 'src/components/Button';
import { BottomSheet } from 'src/components/Modal';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import DatePicker from './DatePicker';
import { IDate } from './lib/types';

interface IDatePickerModal {
  visible: boolean;
  bottomSheetClose: () => void;
  date: IDate;
  setDate: Dispatch<SetStateAction<IDate>>;
  startDate: Date;
  endDate: Date;
}

export default function DatePickerModal({
  visible,
  bottomSheetClose,
  date,
  setDate,
  startDate,
  endDate,
}: IDatePickerModal) {
  return (
    <BottomSheet visible={visible} close={bottomSheetClose}>
      <DatePicker
        setDate={setDate}
        date={date}
        startDate={startDate}
        endDate={endDate}
      />
      <Button
        type="rectangle"
        onClick={bottomSheetClose}
        style={{ marginBottom: 0 }}
      >
        확인
      </Button>
    </BottomSheet>
  );
}
