import { useState } from 'react';
import Month from './Month';
import Legend from './Legend';
import { ICalendar } from './lib/types';
import { CalendarContainer } from './index.styles';
import WeekLabel from './WeekLabel';
import DayList from './DayList';

function Calendar({
  selectedDate,
  category,
  week,
  dayClickHandler,
}: ICalendar) {
  const [date, setDate] = useState<Date>(selectedDate);

  return (
    <CalendarContainer>
      <Month date={date} setDate={setDate} />
      <Legend category={category} />
      <WeekLabel />
      <DayList
        date={date}
        week={week}
        dayClickHandler={dayClickHandler}
        category={category}
      />
    </CalendarContainer>
  );
}

export default Calendar;
