import { useMutation, useQuery, useQueryClient } from 'react-query';
import { get, post } from '../lib/request';
import { ITargetData } from '../features/etc/target/lib/types';

export const useGetGoals = () => {
  return useQuery(['goal'], () => get(`/user/goal/`), {
    staleTime: 1000 * 60 * 5,
  });
};

export const usePostGoals = (mutateSuccessHandler: () => void) => {
  const queryClient = useQueryClient();
  return useMutation((body: ITargetData) => post(`/user/update_goal`, body), {
    onSuccess: () => {
      queryClient.invalidateQueries(['goal']);
      queryClient.invalidateQueries(['home']);
      queryClient.invalidateQueries(['statistics']);
      mutateSuccessHandler();
    },
  });
};
