import styled from '@emotion/styled';
import { colors } from '../../../components/Styles/theme';

interface IWeekText {
  isWeek: boolean;
  isCenter: boolean;
}

export const WeekText = styled.span<IWeekText>`
  text-align: center;
  font-weight: bold;
  font-size: 2.8rem;
  margin-top: 1rem;
  color: ${(props) =>
    props.isWeek ? colors.grey_03_icon : colors.primary_02_light};
  margin-bottom: 0.3rem;
  color: ${(props) =>
    props.isCenter && props.isWeek ? colors.grey_04_inactive : null};
  color: ${(props) =>
    props.isCenter && !props.isWeek ? colors.primary_03_basic : null};
`;
