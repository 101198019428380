import { Dispatch, MutableRefObject, SetStateAction } from 'react';
import { ISectionContent, ITargetData } from './lib/types';
import TargetInput from './TargetInput';
import { ItemTitle, ItemWrapper, Section } from './TargetSection.styles';

interface ITargetSection {
  sectionTitle: string;
  sectionInfos: ISectionContent[];
  goals: ITargetData;
  setGoals: Dispatch<SetStateAction<ITargetData>>;
  editMode: boolean;
  inputRefArray: MutableRefObject<HTMLInputElement[]>;
  enrollRef: Function;
  setDisabled: Dispatch<SetStateAction<boolean>>;
}

function TargetSection({
  sectionTitle,
  sectionInfos,
  goals,
  setGoals,
  editMode,
  inputRefArray,
  enrollRef,
  setDisabled,
}: ITargetSection) {
  return (
    <div>
      <Section>{sectionTitle}</Section>
      {sectionInfos.map((item) => {
        return (
          <ItemWrapper key={item.id}>
            <ItemTitle>{item.title}</ItemTitle>
            <TargetInput
              goals={goals}
              editMode={editMode}
              sectionInfo={item}
              inputRefArray={inputRefArray}
              enrollRef={enrollRef}
              setGoals={setGoals}
              setDisabled={setDisabled}
            />
          </ItemWrapper>
        );
      })}
    </div>
  );
}

export default TargetSection;
