import styled from '@emotion/styled';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100vh - env(safe-area-inset-bottom));
  height: calc(100vh - constant(safe-area-inset-bottom));
  position: relative;
  main {
    flex: 1;
    overflow: auto;
  }
`;
