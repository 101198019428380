import { Dispatch, SetStateAction } from 'react';
import { Pagination } from '../../../components/Contents';
import { DateInfo, DateWrapper } from './Month.styles';

interface IMonth {
  date: Date;
  setDate: Dispatch<SetStateAction<Date>>;
}

function Month({ date, setDate }: IMonth) {
  const clickNext = () => {
    setDate((prev) => {
      return new Date(prev.getFullYear(), prev.getMonth() + 1);
    });
  };
  const clickBack = () => {
    setDate((prev) => {
      return new Date(prev.getFullYear(), prev.getMonth() - 1);
    });
  };

  return (
    <DateWrapper>
      <Pagination
        prevHandler={clickBack}
        nextHandler={clickNext}
        prevDisabled={false}
        nextDisabled={false}
      >
        <DateInfo>
          {date.getFullYear()}년 {date.getMonth() + 1}월
        </DateInfo>
      </Pagination>
    </DateWrapper>
  );
}

export default Month;
