import styled from '@emotion/styled';

export const HeaderStyle = styled.header`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 5.6rem;

  .back {
    position: absolute;
    left: 0;
    top: 0;
    padding: 1.6rem;
  }

  h2 {
    font-size: 1.6rem;
    font-weight: bold;
  }

  .side {
    position: absolute;
    right: 0;
    top: 0;
    padding: 0.4rem;
  }
`;
