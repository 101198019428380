import styled from '@emotion/styled';

export const PrevWeekWrapper = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  & span {
    padding: 0 0.2rem;
  }
`;
