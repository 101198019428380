import styled from '@emotion/styled';
import Drawer from 'antd/lib/drawer';
import 'antd/lib/drawer/style/index.css';
import { colors } from '../Styles/theme';

export const ModalContainer = styled.div``;
export const Modal = styled(Drawer)`
  .ant-drawer-content-wrapper {
    border-radius: 12px 12px 0 0;
  }
  .ant-drawer-content {
    border-radius: 12px 12px 0 0;
  }
  .ant-drawer-body {
    padding: 0;
  }
  .css-ov1ktg {
    display: flex;
    justify-content: center;
  }
`;

export const ModalBackground = styled.div`
  height: 100vh;
  background-color: ${colors.black_03_floating};
`;
