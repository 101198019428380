import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface IHealthInfo {
  swipeWeek: number;
  tab: string;
}

// RTK에서 API 통신을 통해 기본값을 지정하는 방법이 있나?
const initialState: IHealthInfo = {
  swipeWeek: 0,
  tab: 'week',
};

export const healthInfoSlice = createSlice({
  name: 'healthInfo',
  initialState,
  reducers: {
    changeSwipeWeek: (state, action: PayloadAction<number>) => {
      // eslint-disable-next-line no-param-reassign
      state.swipeWeek = action.payload;
    },
    changeTab: (state, action: PayloadAction<string>) => {
      // eslint-disable-next-line no-param-reassign
      state.tab = action.payload;
    },
  },
});

export const { changeSwipeWeek, changeTab } = healthInfoSlice.actions;

export default healthInfoSlice.reducer;
