import styled from '@emotion/styled';
import { colors } from '../../components/Styles/theme';

export const UserName = styled.div`
  padding: 1.8rem 2rem;
  font-size: 1.8rem;
  font-weight: bold;
  line-height: 1.33;
  color: ${colors.black_01_basic};
`;
