import styled from '@emotion/styled';
import { colors } from '../../../components/Styles/theme';
import { ILegendItem } from './lib/types';

export const DayContainer = styled.li<{ active: boolean }>`
  border-bottom: 0.1rem solid ${colors.grey_02_line};
  width: calc(100% / 7);
  padding-bottom: 1rem;
  padding-top: 0.3rem;
  padding-left: 0.3rem;
  min-height: 7rem;
  box-sizing: border-box;
  color: ${(props) =>
    props.active ? colors.grey_05_subtext : colors.grey_03_icon};
`;

export const DayInfo = styled.span<{ isToday: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) =>
    props.isToday ? colors.primary_03_basic : 'transparent'};
  width: 1.8rem;
  height: 1.8rem;
  border-radius: 50%;
  margin-bottom: 0.2rem;
`;

export const DayItem = styled.li<ILegendItem>`
  font-size: 0.9rem;
  line-height: 1.3;
  height: 1.3rem;
  ${(props) =>
    props.marker
      ? `list-style:url(${props.marker}) inside;`
      : 'visibility: hidden;'}
`;
