import React, { Dispatch, SetStateAction } from 'react';
import { Button } from '../../components/Button';
import surveyIcon from '../../assets/img/image-survey.svg';
import {
  IntroContainer,
  Title,
  Description,
  StartButtonContainer,
} from './Questionnaires.styles';

interface IIntro {
  name: string;
  questionnaireCnt: number;
  questionCnt: number;
  setButtonStatus: Dispatch<SetStateAction<boolean>>;
}
export default function Intro({
  name,
  questionnaireCnt,
  questionCnt,
  setButtonStatus,
}: IIntro) {
  const handleClick = () => {
    setButtonStatus(true);
  };
  return (
    <IntroContainer>
      <div>
        <Title>나쁜 생활습관을 바로 잡으면 건강이 개선됩니다. </Title>
        <Description>
          설문을 통해 나의 건강 상태를 확인하고, 개선할 습관이 무엇인지
          알아보아요! 결과에 따라 나만의 관리 목표를 설정하면 헬스매니저가
          &nbsp;{name}님께 맞는 건강관리 서비스를 제공합니다.
        </Description>
      </div>
      <StartButtonContainer>
        <div className="icon-container">
          <img src={surveyIcon} alt="설문 아이콘" />
        </div>
        <div className="cnt">
          설문은 {questionnaireCnt}분류, 총{' '}
          <span className="questionCnt">{`${questionCnt}문항`}</span>
          입니다.
        </div>
        <Button type="round" onClick={handleClick}>
          설문시작
        </Button>
      </StartButtonContainer>
    </IntroContainer>
  );
}
