import { UNSAFE_NavigationContext as NavigationContext } from 'react-router';
import { Dispatch, SetStateAction, useContext } from 'react';
import { callFinish } from 'src/lib/utils';
import Modal from './Modal';

interface IExitModal {
  setIsExit: Dispatch<SetStateAction<boolean>>;
}

function ExitModal({ setIsExit }: IExitModal) {
  const { navigator } = useContext(NavigationContext);
  const leftButtonHandler = () => {
    navigator.push('/');
    setIsExit(false);
  };

  const rightButtonHandler = () => {
    callFinish();
  };

  return (
    <Modal
      isOpen
      leftButtonTitle="아니오"
      leftButtonHandler={leftButtonHandler}
      rightButtonTitle="예"
      rightButtonHandler={rightButtonHandler}
    >
      <div>오케어 앱으로 이동하시겠습니까?</div>
    </Modal>
  );
}

export default ExitModal;
