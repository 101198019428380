import { useLocation } from 'react-router-dom';
import qs from 'qs';

function useQueryString() {
  const { search } = useLocation();

  const queryString = qs.parse(search, {
    ignoreQueryPrefix: true,
  });

  return queryString;
}

export default useQueryString;
