import {
  ChangeEvent,
  Dispatch,
  KeyboardEvent,
  MutableRefObject,
  SetStateAction,
} from 'react';
import { ISectionContent, ITargetData } from './lib/types';
import { Dash, Input, Unit } from './TargetInput.styles';

interface ITargetInput {
  goals: ITargetData;
  editMode: boolean;
  sectionInfo: ISectionContent;
  inputRefArray: MutableRefObject<HTMLInputElement[]>;
  enrollRef: Function;
  setGoals: Dispatch<SetStateAction<ITargetData>>;
  setDisabled: Dispatch<SetStateAction<boolean>>;
}

function TargetInput({
  goals,
  editMode,
  sectionInfo,
  inputRefArray,
  enrollRef,
  setGoals,
  setDisabled,
}: ITargetInput) {
  const keyUpHandler = (e: KeyboardEvent) => {
    if (e.key === 'Enter') {
      const nextInputIdx =
        Array.prototype.indexOf.call(inputRefArray.current, e.target) + 1;
      if (nextInputIdx !== inputRefArray.current.length) {
        inputRefArray.current[nextInputIdx].focus();
      } else {
        inputRefArray.current[nextInputIdx - 1].blur();
      }
      e.preventDefault();
    }
  };

  const keyDownHandler = (e: KeyboardEvent) => {
    if (
      (e.key >= '0' && e.key <= '9') ||
      e.key === 'Enter' ||
      e.key === 'Backspace' ||
      e.key === '.' ||
      e.key === 'ArrowRight' ||
      e.key === 'ArrowLeft'
    ) {
      return;
    }

    e.preventDefault();
  };

  const goalChangeHandler = (
    event: ChangeEvent<HTMLInputElement>,
    key: keyof ITargetData,
    min: number,
    max: number,
  ) => {
    const newData: ITargetData = { ...goals };
    if (Number(event.target.value) > max) return;

    if (Number(event.target.value) < min) {
      newData[key] = '';
    } else {
      const num = Number(event.target.value);
      newData[key] = Number.isInteger(num)
        ? num.toString()
        : (Math.floor(num * 10) / 10).toString();
    }
    setGoals(newData);
    setDisabled(false);
  };

  return (
    <p>
      <Input
        type="number"
        value={goals[sectionInfo.keys[0]] ?? undefined}
        align="right"
        maxLen={sectionInfo.maxLen}
        onChange={(e) => {
          goalChangeHandler(
            e,
            sectionInfo.keys[0],
            sectionInfo.minValue,
            sectionInfo.maxValue,
          );
        }}
        disabled={!editMode}
        onKeyUp={keyUpHandler}
        onKeyDown={keyDownHandler}
        ref={(el) => {
          enrollRef(el);
        }}
        min="0"
        inputMode="numeric"
        pattern="[0-9]*"
      />
      {sectionInfo.type === 'range' && (
        <>
          <Dash>-</Dash>
          <Input
            type="number"
            onKeyUp={keyUpHandler}
            onKeyDown={keyDownHandler}
            value={goals?.[sectionInfo.keys[1]] ?? undefined}
            maxLen={sectionInfo.maxLen}
            onChange={(e) => {
              goalChangeHandler(
                e,
                sectionInfo.keys[1],
                sectionInfo.minValue,
                sectionInfo.maxValue,
              );
            }}
            disabled={!editMode}
            ref={(el) => {
              enrollRef(el);
            }}
            min="0"
            inputMode="numeric"
            pattern="[0-9]*"
          />
        </>
      )}
      <Unit>{sectionInfo.unit}</Unit>
    </p>
  );
}

export default TargetInput;
