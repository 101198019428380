import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../app/store';
import { Tag } from '../../components/Button';
import Chart from '../lifeLog/Chart';
import ChartDetail from './WeekChartDetail';
import { useWeekList, useUser } from '../../hooks';
import { IWeekInfo } from '../../hooks/useWeekList';
import { getFormattedDate } from '../../lib/utils';
import useGetCategory from '../lifeLog/lib/useGetCategory';
import {
  setDisease,
  setCategoryNumber,
  setHomeLogs,
} from '../lifeLog/lifeLogSlice';
import { Container } from './WeekChart.styles';

function WeekChart() {
  const dispatch = useDispatch();
  const categories = useGetCategory();
  const { weeks } = useWeekList();
  const [currentWeek, setCurrentWeek] = useState<IWeekInfo>({
    label: 0,
    startDate: weeks?.[0]?.startDate || new Date(),
    endDate: weeks?.[0]?.endDate || new Date(),
  });

  const { diseaseName, subDiseaseName } = useSelector(
    (state: RootState) => state.home,
  );
  const { userDisease } = useUser();
  const { categoryNumber } = userDisease;
  const { isPreviousData, data } = categories[categoryNumber].useGet(
    getFormattedDate(currentWeek.endDate, 'yyyyMMdd'),
    getFormattedDate(currentWeek.startDate, 'yyyyMMdd'),
  );

  useEffect(() => {
    if (weeks.length > 0) {
      setCurrentWeek(weeks[0]);
    }
  }, [weeks]);

  useEffect(() => {
    if (!categoryNumber) return;
    dispatch(
      setDisease({
        diseaseNumber: categoryNumber,
      }),
    );
    dispatch(
      setCategoryNumber({
        categoryNumber,
      }),
    );
  }, [dispatch, categoryNumber]);

  useEffect(() => {
    if (!data) return;
    dispatch(
      setHomeLogs({
        isPreviousData,
        data,
        currentWeek,
      }),
    );
  }, [dispatch, currentWeek, data, isPreviousData]);

  return (
    <Container>
      <div className="heading">
        <Tag type="basic1">{`${weeks[0]?.label}주차`}</Tag>
        <h3>
          {diseaseName}
          <span>{subDiseaseName}</span>
        </h3>
      </div>
      <Chart isHome />
      <ChartDetail label={categories[categoryNumber].label} />
    </Container>
  );
}

export default WeekChart;
