import { useEffect, useRef, useState } from 'react';
import { getFormattedDate } from 'src/lib/utils';
import { Button } from '../../../components/Button';
import { showToastBottom, showToastTop } from '../../../lib/toast';
import { TargetContainer, UpdatedTime } from './index.styles';
import { ITargetData } from './lib/types';
import { initGoalState, needCheckRangeObj, sections } from './lib/utils';
import { AlertModal } from '../../../components/Modal';
import { modalName } from '../../../lib/modal';
import TargetSection from './TargetSection';
import { useGetGoals, usePostGoals } from '../../../services/useGoal';

function Target() {
  const query = useGetGoals();
  const [goals, setGoals] = useState<ITargetData>(initGoalState);
  const [disabled, setDisabled] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const order = ['혈압', '혈당', '식사', '걸음', '운동', '체중'];
  const inputRefArray = useRef<HTMLInputElement[]>([]);

  useEffect(() => {
    if (query.data) setGoals(query.data);
  }, [query.data]);

  useEffect(() => {
    if (!editMode) return;
    const keys = Object.keys(goals);
    const idx = keys.findIndex((key) => {
      if (goals[key as keyof ITargetData] === '') return true;
      if (!needCheckRangeObj?.[key]) return false;
      const shouldMinVal = goals[key as keyof ITargetData];
      const shouldMaxVal = goals[needCheckRangeObj?.[key] as keyof ITargetData];
      if (Number(shouldMinVal) > Number(shouldMaxVal)) return true;
      return false;
    });

    if (keys[idx] !== undefined) {
      showToastTop('입력한 범위를 확인해주세요.');
      setDisabled(true);
    }
  }, [goals, editMode]);

  const mutateSuccessHandler = () => {
    showToastBottom('저장되었습니다.');
    setEditMode(false);
  };

  const { mutate: targetMutate } = usePostGoals(mutateSuccessHandler);

  const clickSaveBtn = () => {
    targetMutate(goals);
  };

  const clickEditBtn = () => {
    setEditMode(true);
  };

  useEffect(() => {
    if (editMode) inputRefArray?.current[0].focus();
  }, [editMode]);

  let refIdx = 0;
  const enrollRef = (ref: any) => {
    if (ref === null) return;
    inputRefArray.current[refIdx] = ref;
    refIdx += 1;
  };

  if (query.isLoading) return <div>Loading...</div>;
  return (
    <>
      <AlertModal modalName={modalName.AlertModalEtc}>
        <>
          <p>
            자가기록 관리목표는 입력 정보 및 학회 등 가이드라인을 참고하여
            <br />
            자동으로 초기 설정되나,
            <br /> 본인이 목표를 설정하여 사용하실 수 있습니다.
          </p>
          <p>
            개인별 목표와는 상이할 수 있으니,
            <br /> 주치의와 상담 후 개인 목표를 <br />
            개별적으로 설정해 주시기를 <br /> 권고드립니다.
          </p>
          <p>
            자가기록 목표는 <br /> 휴레이 만성질환관리서비스의 모든 서비스에
            반영됩니다.
          </p>
        </>
      </AlertModal>
      <TargetContainer>
        <div>
          {order.map((item) => (
            <TargetSection
              key={item}
              sectionTitle={item}
              sectionInfos={sections[item]}
              goals={goals}
              setGoals={setGoals}
              editMode={editMode}
              inputRefArray={inputRefArray}
              enrollRef={enrollRef}
              setDisabled={setDisabled}
            />
          ))}
        </div>
        <div>
          <UpdatedTime>
            {goals.update_date && (
              <>
                최종 업데이트 시간{' '}
                {getFormattedDate(
                  new Date(goals.update_date),
                  'yyyy년 MM월 dd일 a hh:mm',
                )}
              </>
            )}
          </UpdatedTime>
          <Button
            type="rectangle"
            disabled={disabled}
            onClick={editMode ? clickSaveBtn : clickEditBtn}
          >
            {editMode ? '저장' : '수정'}
          </Button>
        </div>
      </TargetContainer>
    </>
  );
}

export default Target;
