import { Link } from 'react-router-dom';
import { TabWrapper } from './EtcTab.styles';
import { ReactComponent as Next } from '../../assets/img/icon-next-icon.svg';

interface IEtcTab {
  link: string;
  title: string;
}
function EtcTab({ link, title }: IEtcTab) {
  return (
    <Link to={link}>
      <TabWrapper>
        <span>{title}</span>
        <Next />
      </TabWrapper>
    </Link>
  );
}

export default EtcTab;
