import { ReactChild, useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import {
  ModalBackGround,
  ModalButtonContainer,
  ModalButtonLeft,
  ModalButtonRight,
  ModalButtonSingle,
  ModalContainer,
  ModalContent,
} from './Modal.styles';

interface IModal {
  children: ReactChild;
  leftButtonTitle?: string;
  rightButtonTitle?: string;
  singleButtonTitle?: string;
  leftButtonHandler?: () => void;
  rightButtonHandler?: () => void;
  singleButtonHandler?: () => void;
  isOpen: boolean;
  isSingle?: boolean;
}

function Modal({
  children,
  leftButtonTitle,
  rightButtonTitle,
  singleButtonTitle,
  leftButtonHandler,
  rightButtonHandler,
  singleButtonHandler,
  isOpen,
  isSingle,
}: IModal) {
  const ref = useRef<Element | null>();
  const [mounted, setMounted] = useState(false);
  useEffect(() => {
    setMounted(true);
    if (document) {
      const dom = document.getElementById('modal-root');
      ref.current = dom;
    }
  }, []);
  if (ref.current && mounted) {
    return createPortal(
      <ModalBackGround isOpen={isOpen}>
        <ModalContainer>
          <ModalContent>{children}</ModalContent>
          <ModalButtonContainer>
            {isSingle ? (
              <ModalButtonSingle onClick={singleButtonHandler}>
                {singleButtonTitle}
              </ModalButtonSingle>
            ) : (
              <>
                <ModalButtonLeft onClick={leftButtonHandler}>
                  {leftButtonTitle}
                </ModalButtonLeft>
                <ModalButtonRight onClick={rightButtonHandler}>
                  {rightButtonTitle}
                </ModalButtonRight>
              </>
            )}
          </ModalButtonContainer>
        </ModalContainer>
      </ModalBackGround>,
      ref.current,
    );
  }
  return null;
}
export default Modal;
