import { ReactNode } from 'react';
import { Container } from './Pagination.styles';
import { Button } from '../Button';
import { ReactComponent as CircleBack } from '../../assets/img/icon-circle-back.svg';

interface IPagination {
  children: ReactNode;
  prevHandler: Function;
  nextHandler: Function;
  prevDisabled?: boolean;
  nextDisabled?: boolean;
}

function Pagination({
  children,
  prevHandler,
  nextHandler,
  prevDisabled,
  nextDisabled,
}: IPagination) {
  return (
    <Container>
      <Button
        type="icon"
        icon={<CircleBack />}
        onClick={() => {
          prevHandler();
        }}
        disabled={prevDisabled}
      >
        <span className="a11y">Back</span>
      </Button>
      {children}
      <Button
        type="icon"
        icon={<CircleBack />}
        onClick={() => {
          nextHandler();
        }}
        disabled={nextDisabled}
      >
        <span className="a11y">Back</span>
      </Button>
    </Container>
  );
}

export default Pagination;
