import styled from '@emotion/styled';
import { colors } from '../Styles/theme';

interface IContainer {
  margin: string;
}
export const Container = styled.div<IContainer>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: ${({ margin }) => (margin === 'top' ? '1rem 0 0' : '0 0 1rem')};
`;

export const FilterButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 8rem;
  padding: 0.2rem 1.2rem;
  font-size: 1.2rem;
  color: ${colors.white_01_basic};
  border-radius: 1.2rem;
  background-color: ${colors.black_01_basic};
`;

export const List = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 5.6rem;
  text-align: center;
  &:hover {
    background-color: ${colors.grey_01_bg};
  }
`;
