import styled from '@emotion/styled';
import { ILegendItem } from './lib/types';

export const LegendWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

export const LegnedList = styled.ul`
  display: flex;
`;

export const LegendItem = styled.li<ILegendItem>`
  align-items: center;
  font-size: 1rem;
  line-height: 1.3;
  margin-right: 1rem;
  ${(props) => {
    return `list-style:url(${props.marker}) inside`;
  }}
`;
