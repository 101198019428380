import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { isBackBtnOff } from 'src/features/questionnaires/checkBackButtonSlice';
import { useModalState } from '../hooks';
import { routeList } from '../routes';
import { RootState } from '../app/store';
import { setType } from '../features/lifeLog/lifeLogSlice';
import Back from '../assets/img/icon-back-black.svg';
import { modalName } from '../lib/modal';
import useGetCategory from '../features/lifeLog/lib/useGetCategory';
import { Button } from '../components/Button';
import { HeaderStyle } from './Header.styles';
import QuestionnairesModal from '../features/questionnaires/QuestionnairesCloseModal';
import { callFinish } from '../lib/utils';

export interface Iside {
  [key: string]: {
    label: string;
  };
}
export interface IHeader {
  path: string;
}

function Header({ path }: IHeader) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { categoryNumber } = useParams<{ categoryNumber: string }>();
  const { type } = useSelector((state: RootState) => state.lifeLogs);
  const { isBackBtn } = useSelector(
    (state: RootState) => state.checkBackButton,
  );

  const isLifeLogs = path.includes('/main/logs');
  const categories = useGetCategory();
  const currentRoute = routeList.find((item) => item.path.includes(path));
  const side: Iside = {
    graph: {
      label: '전체보기',
    },
    list: {
      label: '그래프',
    },
  };

  const [backStatus, setStatus] = useState(false);
  const goBack = () => {
    if (path.includes('questionnaires')) {
      setStatus(true);
    } else {
      navigate(-1);
    }
  };

  const typeHandler = () => {
    dispatch(
      setType({
        type: type === 'graph' ? 'list' : 'graph',
      }),
    );
  };
  const { closeModal } = useModalState(modalName.QuestionnairesModal);
  const leftButtonHandler = () => {
    setStatus(false);
    closeModal({ permanent: false });
    dispatch(isBackBtnOff());
  };
  // # 앱 종료
  const rightButtonHandler = () => {
    callFinish();
    localStorage.setItem('app', `close`);
    closeModal({ permanent: false });
  };

  useEffect(() => {
    if (isBackBtn) setStatus(true);
  }, [isBackBtn]);

  return (
    <>
      <QuestionnairesModal
        isOpen={backStatus}
        leftButtonHandler={leftButtonHandler}
        rightButtonHandler={rightButtonHandler}
      />
      <HeaderStyle>
        <h1 className="a11y">휴레이 만성질환 서비스</h1>
        <h2>
          {isLifeLogs && categoryNumber
            ? categories[categoryNumber].label
            : currentRoute?.label}
        </h2>

        <button type="button" className="back" onClick={goBack}>
          <img src={Back} alt="back" />
          <span className="a11y">back</span>
        </button>

        {/* 라이프로그 */}
        {isLifeLogs && type && (
          <div className="side">
            <Button type="text" onClick={typeHandler}>
              {side[type].label}
            </Button>
          </div>
        )}
      </HeaderStyle>
    </>
  );
}

export default Header;
