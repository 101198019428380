import { Container, Img, TextContainer } from './PopularContentHeader.styles';
import headerImg from '../../../assets/img/image-contents-ranking.svg';

function PopularContentHeader() {
  return (
    <Container>
      <Img>
        <img src={headerImg} alt="이미지" />
      </Img>
      <TextContainer>
        <span>휴레이 만성질환관리서비스</span>
        <span>사용자들이 가장 많이 읽은</span>
        <span>인기 콘텐츠 TOP 10</span>
        <span>바쁠 땐 인기 콘텐츠를 열람하세요!</span>
      </TextContainer>
    </Container>
  );
}

export default PopularContentHeader;
