import { ReactNode } from 'react';
import { Button } from '../../components/Button';
import { Container } from './Header.styles';
import { ReactComponent as Detail } from '../../assets/img/icon-detail.svg';

interface IHeader {
  children: ReactNode;
  to?: string;
}

function Header({ children, to }: IHeader) {
  return (
    <Container>
      <h3>{children}</h3>
      {to && (
        <Button type="text2" tag="a" to={to} icon={<Detail />}>
          자세히보기
        </Button>
      )}
    </Container>
  );
}

export default Header;
