import { Link } from 'react-router-dom';
import styled from '@emotion/styled';

export const CustomLink = styled(Link)`
  display: inline-block;
  width: auto;
  height: auto;
  margin: 0;

  &:last-child {
    margin-bottom: 2.9rem;
  }
`;
