import { useEffect, useState } from 'react';
import * as _ from 'lodash';
import { Line, Bar } from 'react-chartjs-2';
import {
  useGetBloodPressure,
  useGetSugar,
  useGetMeal,
  useGetWeight,
  useGetStep,
  useGetExercise,
} from '../../../services/useLifeLog';
import { useGetGoals } from '../../../services/useGoal';
import { categoryType } from '../../../lib/utils';

interface ICategory {
  [key: string]: {
    label: string;
    useGet: Function;
    chart: {
      element: any; // TODO:
      max: number;
      min: number;
    };
    goalMax: number;
    goalMin: number;
    unit: string[];
    isRiskGuide: boolean;
  };
}

function useGetCategory(): ICategory {
  const logs = {
    [categoryType.blood]: {
      label: '혈압',
      useGet: useGetBloodPressure,
      chart: {
        element: Line,
        max: 180,
        min: 0,
      },
      goalMax: 120,
      goalMin: 80,
      unit: ['mmHg', 'bpm', '회'],
      isRiskGuide: true,
    },
    [categoryType.sugar]: {
      label: '혈당',
      useGet: useGetSugar,
      chart: {
        element: Line,
        max: 400,
        min: 0,
      },
      goalMax: 179,
      goalMin: 71,
      unit: ['mg/dL', '회'],
      isRiskGuide: true,
    },
    [categoryType.meal]: {
      label: '식사',
      useGet: useGetMeal,
      chart: {
        element: Bar,
        max: 4160,
        min: 0,
      },
      goalMax: 0,
      goalMin: 0,
      unit: ['kcal', '%'],
      isRiskGuide: false,
    },
    [categoryType.weight]: {
      label: '체중',
      useGet: useGetWeight,
      chart: {
        element: Line,
        max: 0, // chart에서 처리 - 평균체중 + 10
        min: 0, // chart에서 처리 - 평균체중 - 10
      },
      goalMax: 0,
      goalMin: 0,
      unit: ['kg'],
      isRiskGuide: true,
    },
    [categoryType.step]: {
      label: '걸음',
      useGet: useGetStep,
      chart: {
        element: Bar,
        max: 15000,
        min: 0,
      },
      goalMax: 0,
      goalMin: 0,
      unit: ['걸음'],
      isRiskGuide: false,
    },
    [categoryType.exercise]: {
      label: '운동',
      useGet: useGetExercise,
      chart: {
        element: Bar,
        max: 750,
        min: 0,
      },
      goalMax: 0,
      goalMin: 0,
      unit: ['kcal'],
      isRiskGuide: false,
    },
  };

  const [categories, setCategories] = useState<any>(logs);
  const { data } = useGetGoals();

  useEffect(() => {
    if (data) {
      const goals = {
        [categoryType.meal]: {
          goalMax: data.cal_in,
        },
        [categoryType.weight]: {
          goalMax: data.weight,
        },
        [categoryType.step]: {
          goalMax: data.step_count,
        },
        [categoryType.exercise]: {
          goalMax: data.cal_out,
        },
      };
      setCategories({ ...categories, ..._.merge(categories, goals) });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return categories;
}

export default useGetCategory;
