import { WeekContainer } from './WeekLabel.styles';

function WeekLabel() {
  return (
    <WeekContainer>
      <span>일</span>
      <span>월</span>
      <span>화</span>
      <span>수</span>
      <span>목</span>
      <span>금</span>
      <span>토</span>
    </WeekContainer>
  );
}

export default WeekLabel;
