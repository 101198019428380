import styled from '@emotion/styled';
import { Button } from '../../components/Button';
import { colors } from '../../components/Styles/theme';

interface IButtonContainer {
  display: boolean | number;
}
interface IActive {
  active: boolean;
}

// # INTRO
export const IntroContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100vh - 5.6rem);
  justify-content: space-between;
  align-items: center;
  padding: 3rem;
`;
export const Title = styled.h1`
  font-size: 2rem;
  margin-top: 2rem;
  font-weight: bold;
  color: ${colors.black_01_basic};
  word-break: keep-all;
`;

export const Description = styled.div`
  margin-top: 1rem;
  word-break: keep-all;
  color: ${colors.grey_05_subtext};
  font-size: 1.4rem;
`;

export const StartButtonContainer = styled.div`
  width: 100%;
  font-size: 1.4rem;
  font-weight: bold;
  margin-bottom: 2rem;
  div.icon-container {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 4rem;
  }
  div.cnt {
    margin-bottom: 2rem;
    text-align: center;
  }
  span.questionCnt {
    color: ${colors.primary_03_basic};
    font-weight: bold;
  }
`;

// # STEP_설문영역
export const StepContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100vh - 5.6rem);

  div.step {
    flex: 1;
    overflow: auto;
    padding: 3rem;
  }
`;
export const SectionStep = styled.div`
  margin-top: 6.3rem;
`;
export const TotalStep = styled.span`
  font-size: 1.4rem;
  color: ${colors.grey_03_icon};
`;
export const CurrStep = styled(TotalStep)`
  font-size: 2rem;
  color: ${colors.primary_03_basic};
`;

export const Question = styled.div`
  margin-top: 1rem;
  word-break: keep-all;
  font-size: 1.6rem;
  color: ${colors.grey_05_subtext};
`;

export const AnswerContainer = styled.div`
  margin-top: 3rem;
`;
export const Answer = styled.div`
  margin-bottom: 0.8rem;
`;
export const InputContainer = styled.div`
  font-size: 1.6rem;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;
export const Input = styled.input`
  border: none;
  text-align: center;
  width: 50%;
  font-size: 2rem;
  caret-color: ${colors.primary_03_basic};
  border-bottom: 1px solid ${colors.grey_02_line};
  line-height: 2.6rem;
  &::placeholder {
    font-size: 2rem;
    color: ${colors.grey_03_icon};
  }
  &:focus {
    border-bottom: 1px solid ${colors.primary_03_basic};
  }
`;
export const SelectButton = styled(Button)<IActive>`
  display: flex;
  justify-content: space-between;
  background-color: ${(props) =>
    props.active ? colors.primary_03_basic : colors.grey_01_bg};
  color: ${colors.grey_05_subtext};
  text-align: start;
  margin-bottom: 0.8rem;

  &:hover,
  &:focus {
    color: ${colors.grey_05_subtext};
    background-color: ${(props) =>
      props.active ? colors.primary_03_basic : colors.grey_01_bg};
  }
  &:active {
    color: ${colors.grey_05_subtext};
    background-color: ${colors.primary_03_basic};
  }
`;
interface IEnterDate {
  active: string | boolean;
}
export const EnterDate = styled.div<IEnterDate>`
  display: flex;
  align-items: center;
  font-size: 1.6rem;
  color: ${(props) =>
    props.active ? colors.black_02_pressed : colors.grey_04_inactive};
  div.dateFormat {
    display: flex;
  }
`;
export const DeleteButton = styled.div`
  color: ${colors.black_01_basic};
  width: 1.6rem;
  text-align: center;
  margin-left: 1.4rem;
`;
export const SvgContainer = styled.div`
  margin-left: 1.4rem;
`;
// # 이전/다음 버튼영역
export const ButtonContainer = styled.div<IButtonContainer>`
  display: flex;
  margin-bottom: 2rem;
  justify-content: space-between;
  flex-direction: ${(props) => (props.display ? 'row' : 'row-reverse')};
`;

// # 기초설문 완료 안내
export const EndContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100vh - 5.6rem);
  text-align: center;

  div.description {
    flex: 1;
    overflow: auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  div.complete {
    font-size: 1.6rem;
    margin-top: 2rem;
    font-weight: normal;
  }
`;
export const CompleteSection = styled.div`
  font-size: 1.6rem;
  font-weight: bold;
`;
export const NextSection = styled.div`
  margin-top: 2rem;
  color: ${colors.grey_04_inactive};
  font-size: 1.4rem;
`;
export const EndButtonContainer = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 2rem;
  justify-content: flex-end;
  align-items: center;
`;
