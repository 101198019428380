import styled from '@emotion/styled';

export const Container = styled.div`
  .slick-list {
    padding-left: 0.5rem !important;

    .slick-track {
      margin-left: inherit;

      .slick-slide {
        padding: 0 0.5rem;
      }
    }
  }
`;
