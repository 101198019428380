import { format, parse } from 'date-fns';
import { ko } from 'date-fns/locale';

export const FORMAT = "yyyy-MM-dd'T'HH:mm:ss";

export function convertToDate(date: string) {
  // 시간 제외
  return new Date(date.slice(0, -9));
  // return new Date(date);
}

export function getFormattedDate(baseDate: Date, givenFormat: string = FORMAT) {
  return format(baseDate, givenFormat, { locale: ko });
}

export function parseToDate(date: string) {
  return parse(date, FORMAT, new Date());
}

export function getWeekInfo(startDate: Date, fromDate: Date) {
  const day = 1000 * 60 * 60 * 24;
  const gapDay = (fromDate.getTime() - startDate.getTime()) / day;
  const label = Math.floor(gapDay / 7) + 1;
  const weekInfo = {
    label: label < 0 ? 0 : label,
    startDate: new Date(startDate.getTime() + day * (label - 1) * 7),
    endDate: new Date(startDate.getTime() + day * 6 + day * (label - 1) * 7),
  };
  return weekInfo;
}

export function numberWithCommas(
  value: string | number | undefined,
  useDecimalPoint: boolean = true,
) {
  if (value !== '-') {
    let convertedValue = useDecimalPoint
      ? Number(Number(value).toFixed(1))
      : Number(Number(value).toFixed(0));
    convertedValue = Number.isInteger(convertedValue)
      ? Math.floor(convertedValue)
      : convertedValue;
    return convertedValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }
  return value;
}

export const categoryType: {
  [key: string]: string;
} = {
  meal: '01',
  weight: '02',
  step: '03',
  exercise: '04',
  blood: '05',
  sugar: '06',
};

export const diseaseType: {
  [key: string]: {
    label: string;
    categoryNumber: string;
    categories: string[];
  };
} = {
  '2': {
    label: '당뇨',
    categoryNumber: categoryType.sugar,
    categories: ['혈당', '체중', '식사', '걸음', '운동'],
  },
  '3': {
    label: '고혈압',
    categoryNumber: categoryType.blood,
    categories: ['혈압', '체중', '식사', '걸음', '운동'],
  },
  '4': {
    label: '이상지지혈증',
    categoryNumber: categoryType.weight,
    categories: ['혈당', '혈압', '체중', '식사', '걸음', '운동'],
  },
};

export const categoryToNumber: { [key: string]: string } = {
  식사: categoryType.meal,
  체중: categoryType.weight,
  걸음: categoryType.step,
  운동: categoryType.exercise,
  혈압: categoryType.blood,
  혈당: categoryType.sugar,
};

export const numberToCategory: { [key: string]: string } = {
  [categoryType.meal]: '식사',
  [categoryType.weight]: '체중',
  [categoryType.step]: '걸음',
  [categoryType.exercise]: '운동',
  [categoryType.blood]: '혈압',
  [categoryType.sugar]: '혈당',
};

// 현재 호출한 디바이스가 어떤 것인지 체크합니다.
const isMobile = {
  Android() {
    return navigator.userAgent.match(/Android/i) != null;
  },
  iOS() {
    return navigator.userAgent.match(/iPhone|iPad|iPod/i) != null;
  },
  any() {
    return isMobile.Android() || isMobile.iOS();
  },
};

export const ocareCat: { [key: string]: string } = {
  식사: 'ML',
  체중: 'WT',
  걸음: 'WK',
  운동: 'EX',
  혈압: 'BP',
  혈당: 'BS',
};

export function ocareJson(obj = {}) {
  const defaultJson = {
    cat_code: '',
    cat_code_detail: '',
    providerId: '1000000040',
    typeKey: 'carenmate',
    params: '',
  };

  return { ...defaultJson, ...obj };
}

// 입력 된 링크를 전달하는 Bridge 함수
export function callFinish(json = {}) {
  console.log('callFinish :: ', json);
  if (isMobile.any()) {
    if (isMobile.Android()) {
      /* global Android */
      /* eslint no-undef: "error" */
      // @ts-ignore
      Android.callFinish(JSON.stringify(json));
      console.log('Android callFinish');
    } else if (isMobile.iOS()) {
      /* global webkit */
      /* eslint no-undef: "error" */
      // @ts-ignore
      webkit.messageHandlers.callFinish.postMessage(json);
      console.log('IOS callFinish');
    }
  }
}
