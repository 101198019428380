import React, {
  Dispatch,
  MouseEvent,
  SetStateAction,
  useEffect,
  useState,
} from 'react';
import {
  Answer,
  DeleteButton,
  EnterDate,
  Input,
  InputContainer,
  SelectButton,
  SvgContainer,
} from './Questionnaires.styles';
import { IChoice, IDatePicker, IDefaultValue, IResponse } from './lib/types';
import DatePickerModal from '../../components/DatePicker/DatePickerModal';
import DownBlank from '../../assets/svg/DownBlank';
import useBottomSheet from '../../hooks/useBottomSheet';

interface IQuestion {
  questionId: number | undefined;
  responseType: number | undefined;
  multiResponse: number | undefined;
  unit: string | null | undefined;
  limitSize: number;
  choices: IChoice[] | undefined;
  responses: IResponse[];
  selectQuestion: IDatePicker[];
  setSelectQuestion: Dispatch<SetStateAction<IDatePicker[]>>;
  setIsSubQstn: Dispatch<SetStateAction<boolean>>;
}

export default function Question(props: IQuestion) {
  const {
    questionId,
    responseType,
    multiResponse,
    unit,
    limitSize,
    choices,
    responses,
    selectQuestion,
    setSelectQuestion,
    setIsSubQstn,
  } = props;
  const { visible, open, close } = useBottomSheet();
  const subjective = responseType === 2;
  // # 없음 버튼 상태
  const [clickNone, setClickNone] = useState<boolean>(false);
  const [clickChoiceId, setClickChoiceId] = useState<number>(0);

  interface IDate {
    year: number;
    month: number;
    day: number;
  }

  const presentDate = new Date();
  const presentYear = presentDate.getFullYear();
  const presentMonth = presentDate.getMonth() + 1;
  const presentDay = presentDate.getDate();

  const [date, setDate] = useState<IDate>({
    year: presentYear,
    month: presentMonth,
    day: presentDay,
  });

  // # 선택 삭제 핸들러
  const handleRemove = (choiceId: number) => {
    const remove = selectQuestion.filter((item) => item.choiceId !== choiceId);
    setSelectQuestion(remove);
  };
  // # 객관식 핸들러
  const handleQuestionClick = (
    e: MouseEvent<HTMLElement>,
    index: number,
    choiceId: number,
    subjectYn: string,
    isSubQstn: boolean,
  ) => {
    const isExist = selectQuestion.filter((item) => item.choiceId === choiceId);
    const noneId = choices?.find((item) => item.text === '없음');
    setIsSubQstn(isSubQstn);
    // 1. 복수선택일때
    if (multiResponse) {
      if (choiceId === noneId?.id) {
        setSelectQuestion([{ choiceId, index }]);
        setClickNone(true);
      } else {
        if (subjectYn === 'Y') {
          // 1-1. 진단일자 입력 버튼이 있을 때
          if (clickNone) {
            setSelectQuestion(
              selectQuestion.filter((item) => item.choiceId !== noneId?.id),
            );
            setClickNone(false);
          }
          open();
          return;
        }

        if (clickNone) {
          setSelectQuestion([{ choiceId, index }]);
          setClickNone(false);
          return;
        }
        // 1-2. 진단일자 입력 버튼이 없을 때
        console.log('기본 복수선택');
        const question = selectQuestion.concat([
          {
            choiceId,
            index,
          },
        ]);
        if (subjectYn === 'N' && isExist.length > 0) {
          handleRemove(choiceId);
          return;
        }
        setSelectQuestion(question);
      }
    } else {
      // 2. 단일선택일때
      console.log('단일선택');
      setSelectQuestion([
        {
          choiceId,
          index,
        },
      ]);
    }
  };

  const keyDownCheckForInputNumber = (
    e: React.KeyboardEvent<HTMLInputElement>,
  ) => {
    // 윈도우 전용
    const { key } = e;
    if (key !== 'Backspace') {
      if (key === 'Unidentified' || /[a-zA-Z||+-.,]|\./.test(key)) {
        // IOS, 안드로이드에서는 해당 호출로 인한 중단이 안됨.
        e.preventDefault();
      }
    }
  };

  const checkForInputNumber = (e: InputEvent): boolean => {
    const { data, target } = e;
    const pattern = /[^0-9]/g;
    // 안드로이드에서는 input number일 경우 '.' 입력시 isComposing이 true로 들어옴. 실수 입력때문
    if (data && pattern.test(data)) {
      const inputElm = target as HTMLInputElement;
      inputElm.value = ''; // 안드로이드 때문 값이 같을 경우 렌더링을 하지 않음.
      inputElm.value = selectQuestion[0]?.text; // IOS input number일 경우 숫자 이외의 값을 입력하면 value를 지워버림.
      e.preventDefault();
      return true;
    }
    return false;
  };
  // # 3. 주관식 핸들러
  const handleSubjective = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { target } = e;
    const { value, maxLength, size } = target;
    if (Number(value) > size) {
      // TODO: 가능한 ${size}를 UX를 위해 어떤 형태(모달이든)로 보여줘야 하지 않을까요
      console.log(`${size}이하의 숫자만 입력 가능합니다.`);
      // setSelectQuestion([
      //   {
      //     choiceId: 0,
      //     text: selectQuestion[0].text,
      //   },
      // ]);
      return;
    }
    if (!checkForInputNumber(e.nativeEvent as InputEvent)) {
      setSelectQuestion([
        {
          choiceId: 0,
          text: value.slice(0, maxLength),
        },
      ]);
    }
    if (choices !== undefined && choices[0]?.subquestions) {
      const isSubQstn = choices[0].subquestions.length > 0;
      setIsSubQstn(isSubQstn && Number(value.slice(0, maxLength)) > 0);
    }
  };
  // # 바텀시트 핸들러
  const handleSelectDate = () => {
    const dateFormat = `${date.year}.${date.month}.${date.day}`;
    const question = selectQuestion.concat([
      {
        choiceId: clickChoiceId,
        date: dateFormat,
      },
    ]);
    const arrUnique = question.reverse().filter((character, idx, arr) => {
      return (
        arr.findIndex((item) => item.choiceId === character.choiceId) === idx
      );
    });
    setClickNone(false);
    setSelectQuestion(arrUnique);
    close();
  };

  useEffect(() => {
    if (choices === undefined) return;
    setSelectQuestion([]);
    const saved = responses.filter((item) => {
      return item.question_id === questionId;
    });

    if (saved.length) {
      const choiceIdx = choices.findIndex(
        (item) => item.id === saved[0].choice_ids[0],
      );
      const resObject: IDefaultValue[] = [];
      Object.keys(saved).forEach((key: any) => {
        Object.keys(saved[key].choice_ids).forEach((item) => {
          if (!saved[0].choice_ids[item]) {
            resObject.push({
              choiceId: saved[0].choice_ids[item],
              text: saved[0].response_value[item],
            });
          } else {
            resObject.push({
              choiceId: saved[0].choice_ids[item],
              date: saved[0].response_value[item],
              index: choiceIdx,
            });
          }
        });
      });

      if (choices[choiceIdx < 0 ? 0 : choiceIdx]?.subquestions.length) {
        if (subjective) setIsSubQstn(Number(resObject[0].text) > 0);
        else setIsSubQstn(true);
      } else if (!subjective) setIsSubQstn(false);

      setSelectQuestion(resObject);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questionId]);

  const handlePrefixText = (id: number | undefined) => {
    switch (id) {
      case 4:
        return '총';
      case 6:
        return '총';
      case 15:
        return '하루';
      default:
        return null;
    }
  };
  return (
    <>
      <DatePickerModal
        visible={visible}
        bottomSheetClose={handleSelectDate}
        date={date}
        setDate={setDate}
        startDate={
          new Date(new Date().setFullYear(new Date().getFullYear() - 10))
        }
        endDate={new Date()}
      />
      <Answer>
        {subjective ? (
          <InputContainer>
            <span>{handlePrefixText(questionId)}&nbsp;</span>
            <Input
              type="number"
              name="subject"
              size={limitSize}
              maxLength={String(limitSize).length}
              placeholder="숫자입력"
              value={selectQuestion[0]?.text ?? ''}
              onKeyDown={keyDownCheckForInputNumber}
              onInput={handleSubjective}
              min="0"
              inputMode="numeric"
              pattern="[0-9]*"
            />
            {unit}
          </InputContainer>
        ) : (
          choices?.map((choice, index) => {
            // eslint-disable-next-line camelcase
            const { id, subject_yn: subjectYn, subquestions } = choice;
            const isState = selectQuestion.some((item) => {
              return item.choiceId === id;
            });
            const stateDate = selectQuestion.filter((item) => {
              return item.choiceId === id;
            });
            const active = selectQuestion.some((item) => item.choiceId === id);
            const isSubQstn =
              subquestions !== undefined && subquestions.length > 0;
            return (
              <SelectButton
                key={id}
                onClick={(e) => {
                  setClickChoiceId(id);
                  handleQuestionClick(e, index, id, subjectYn, isSubQstn);
                }}
                active={(clickNone && active) || active}
              >
                {choice.text}
                {subjectYn === 'Y' && (
                  <EnterDate active={active}>
                    {isState ? (
                      <div className="dateFormat">
                        <span>{stateDate[0]?.date}</span>
                        <DeleteButton
                          className="delete"
                          onClick={(event) => {
                            event.stopPropagation();
                            handleRemove(id);
                          }}
                        >
                          &times;
                        </DeleteButton>
                      </div>
                    ) : (
                      <>
                        진단일자입력
                        <SvgContainer>
                          <DownBlank />
                        </SvgContainer>
                      </>
                    )}
                  </EnterDate>
                )}
              </SelectButton>
            );
          })
        )}
      </Answer>
    </>
  );
}
