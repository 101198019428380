import Lottie from 'lottie-web';
import { useEffect, useRef } from 'react';
import { LoadingBackground, OcareAnimationDiv } from './Loading.styles';
import animation from '../../assets/img/ocare-animation.json';

function Loading() {
  const animationRef = useRef<any>();

  useEffect(() => {
    const instance = Lottie.loadAnimation({
      container: animationRef.current,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData: animation,
    });

    return () => instance.destroy();
  });

  return (
    <>
      <LoadingBackground />
      <OcareAnimationDiv ref={animationRef} />
    </>
  );
}

export default Loading;
