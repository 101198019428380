import React from 'react';
import { convertSection } from '../../features/questionnaires/lib/constants';
import {
  ProgressContainer,
  DotContainer,
  Dot,
  Line,
} from './DotsProgress.styles';

// FIXME: 현재 currStep은 questionnaires/lib/constants.ts를 통해 어떤 설문인지 값을 가져옴. 추후 API 개발 요쳥필요
interface IProgress {
  progress: number[];
  currStep: number;
}
const makeKey = (idx: number) => {
  return `set-${idx}`;
};
export default function DotsProgress({ progress, currStep }: IProgress) {
  return (
    <ProgressContainer active={currStep === progress.length}>
      {progress.map((set) => {
        return (
          <DotContainer
            key={makeKey(set)}
            active={set < convertSection(currStep)}
          >
            <div className="dot-container">
              <Dot active={set <= convertSection(currStep)} />
              <Line active={set < convertSection(currStep)} />
            </div>
          </DotContainer>
        );
      })}
    </ProgressContainer>
  );
}
