import styled from '@emotion/styled';
import { colors } from '../../../components/Styles/theme';
import { IContentContainer } from '../lib/types';

export const ContentContainer = styled.div`
  width: calc(100% - 20px);
  height: 7.4rem;
  margin: 0 1rem 1rem 1rem;
  border-radius: 1.6rem;
  background-color: ${colors.white_01_basic};
  box-shadow: 0 0.2rem 1.2rem 0 rgba(0, 0, 0, 0.15);
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
`;

export const ContentInfo = styled.div<IContentContainer>`
  width: 100%;
  padding: 1.7rem 2rem 1.7rem 1.3rem;
  display: flex;
  align-items: center;
  font-size: 1.4rem;

  div: nth-of-type(1) {
    width: 1.7rem;
    white-space: nowrap;
    font-weight: bold;
    color: ${colors.primary_03_basic};
    text-align: center;
  }

  div: nth-of-type(2) {
    width: calc(100% - 3.7rem);
    margin-left: 0.6rem;
    color: ${({ isRead }) =>
      isRead ? colors.grey_05_subtext : colors.black_01_basic};
    font-weight: ${({ isRead }) => (isRead ? 100 : 900)};
  }
`;

export const ReadCircle = styled.div`
  width: 0.6rem;
  height: 0.6rem;
  background-color: ${colors.primary_03_basic};
  border-radius: 50%;
  position: absolute;
  right: 1rem;
  top: 1rem;
`;

export const Highlight = styled.img`
  width: 1rem;
  height: 1.4rem;
  object-fit: contain;
  position: absolute;
  left: 1.6rem;
  top: 0rem;
`;
