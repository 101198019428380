import { Modal } from 'src/components/Modal';
import { callFinish, ocareJson } from 'src/lib/utils';

function CheckOcareWeightModal() {
  const onOk = () => {
    const callFinishJSON = ocareJson({
      cat_code: 'WT',
      params: {
        page: 'step',
      },
    });
    callFinish(callFinishJSON);
  };

  return (
    <Modal isOpen isSingle singleButtonTitle="확인" singleButtonHandler={onOk}>
      <p>
        현재 체중이 없습니다. <br />
        오케어앱에서 체중을 입력해 주세요.
      </p>
    </Modal>
  );
}

export default CheckOcareWeightModal;
