import styled from '@emotion/styled';
import { colors } from 'src/components/Styles/theme';

export const QnAItemContainer = styled.div`
  padding: 2rem;
  background-color: ${colors.grey_01_bg};
`;

export const QnADate = styled.span`
  font-size: 1rem;
  font-weight: normal;
  margin-left: 1rem;
`;

export const QnAAnswer = styled.div`
  padding: 0.6rem 0;
  font-size: 1.4rem;
  img {
    max-width: 100% !important;
    height: auto !important;
  }
`;

export const ImageContainer = styled.div`
  width: 100%;
  img {
    max-width: 100% !important;
    height: auto !important;
  }
`;
