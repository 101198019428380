import styled from '@emotion/styled';
import { colors } from '../../../components/Styles/theme';

export const Section = styled.p`
  padding: 0.9rem 3.14rem 0.9rem 2rem;
  background-color: ${colors.grey_01_bg};
  font-size: 1.4rem;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: ${colors.black_01_basic};
`;

export const ItemWrapper = styled.div`
  display: flex;
  padding: 0 2rem;
  height: 4rem;
  justify-content: space-between;
  align-items: center;
`;

export const ItemTitle = styled.label`
  font-size: 1.6rem;
  line-height: 1.38;
  letter-spacing: normal;
  color: ${colors.grey_05_subtext};
  font-weight: normal;
`;
