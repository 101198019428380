import styled from '@emotion/styled';

export const Container = styled.article`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  button {
    &:first-of-type {
    }

    &:last-of-type {
      transform: rotate(0.5turn);
    }
  }
`;
