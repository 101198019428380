import { categoryType } from '../../../lib/utils';

interface Ilegend {
  [key: string]: string[];
}

export const legend: Ilegend = {
  [categoryType.blood]: [
    '저혈압',
    '정상',
    '고혈압 전단계',
    '고혈압 1단계',
    '고혈압 2단계',
  ],
  [categoryType.sugar]: ['저혈당', '정상', '고혈당 위험', '고혈당'],
  [categoryType.weight]: [
    '저체중',
    '정상',
    '비만 전단계',
    '1단계 비만',
    '2단계 비만',
    '3단계 비만',
  ],
};

export const mealType = [
  {
    name: '간식',
    detailCode: 'PARS',
  },
  {
    name: '저녁',
    detailCode: 'CV',
  },
  {
    name: '점심',
    detailCode: 'CD',
  },
  {
    name: '아침',
    detailCode: 'CM',
  },
];
