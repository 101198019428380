import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import Collapse from 'antd/lib/collapse';
import { Button } from 'src/components/Button';
import 'antd/lib/collapse/style/index.css';
import { colors } from '../../components/Styles/theme';

const { Panel } = Collapse;
interface IPanel {
  active: boolean;
}
interface IImage {
  detail?: boolean;
}

export const Wrapper = styled.div`
  height: 100%;
`;
// # 헤더영역
export const HeaderStikyContainer = styled.div`
  position: sticky;
  top: 0;
  z-index: 100;
`;

// # 휴먼코칭 소개
export const CoachIntroContainer = styled.div`
  height: 10rem;
  display: flex;
  align-items: center;
  padding: 2rem;
`;
export const CoachImageContainer = styled.div<IImage>`
  max-width: ${(props) => (props.detail ? '4rem' : '7rem')};
  border-radius: 50%;
  border: 1px solid grey;
  overflow: hidden;
  margin-right: 2rem;
  text-align: center;
  img {
    width: 100%;
  }
`;

export const ServiceNameContainer = styled.div`
  font-size: 1.6rem;
  font-weight: bold;
`;

export const CoachMessageContainer = styled.div`
  div.service-name {
    font-size: 1.6rem;
    font-weight: bold;
    color: ${colors.black_01_basic};
    @media (max-width: 340px) {
      font-size: 1.4rem;
    }
    @media (max-width: 320px) {
      font-size: 1.2rem;
    }
    @media (max-width: 290px) {
      font-size: 1.1rem;
    }
  }
  div.message {
    font-size: 1.2rem;
    color: ${colors.grey_04_inactive};
    word-break: keep-all;
    @media (max-width: 340px) {
      font-size: 1.1rem;
    }
    @media (max-width: 325px) {
      font-size: 0.5rem;
    }
    @media (max-width: 280px) {
      font-size: xx-small;
    }
  }
`;

// # (공통) 메시지, 질문답변 리스트 container
export const ListContainer = styled.div<{ isAdviceMessage: boolean }>`
  width: 100%;
  height: ${(props) =>
    props.isAdviceMessage ? 'calc(100% - 18.2rem)' : 'calc(100% - 15rem)'};

  div.no-list {
    height: 100%;
    font-size: 1.4rem;
    color: ${colors.grey_05_subtext};
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

// # 상담메시지_리스트
export const ListItem = styled.div`
  position: relative;
  padding: 1rem 1rem 2rem 2rem;
  div {
    display: flex;
    .msg-check-dttm {
      position: absolute;
      top: 0;
      left: 1rem;
      font-size: 1.2rem;
      color: ${colors.grey_05_subtext};
      span {
        display: inline-block;
        width: 0.6rem;
        height: 0.6rem;
        border-radius: 50%;
        background-color: ${colors.primary_03_basic};
      }
    }
    .created-at {
      font-size: 1.2rem;
      color: ${colors.grey_05_subtext};
    }
  }
`;
export const SideDiv = styled.div`
  button {
    margin-left: 0.8rem;
  }
`;
export const MessageInfoDiv = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin: 0.4rem 0;
`;
interface ITitle {
  textColor: boolean;
}
export const Title = styled(Link)<ITitle>`
  color: ${(props) =>
    props.textColor ? colors.black_01_basic : colors.grey_04_inactive};
  font-size: 1.4rem;
  word-break: keep-all;
`;

// # 상담메시지_상세
export const MessageDetailContainer = styled.div`
  padding-bottom: 9.5rem;
`;
export const MessageDetailHeader = styled.div`
  height: 8rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.6rem;
`;

export const MessageDetailTitle = styled.div`
  padding: 2rem 2rem 0 2rem;
  border-top: 1px solid ${colors.grey_02_line};
  .flex-row {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
  }
  .create-at {
    font-size: 1.2rem;
    color: ${colors.grey_05_subtext};
  }
  .title {
    word-break: keep-all;
    margin: 1rem 0;
    color: ${colors.black_01_basic};
    font-size: 2rem;
    font-weight: bold;
  }
`;

export const MessageDetailContent = styled.div`
  padding: 0 2rem;
  margin-top: 4rem;
  color: ${colors.grey_05_subtext};
  font-size: 1.8rem;
  width: 100%;
  img {
    max-width: 100% !important;
    height: auto !important;
  }
`;

export const AttachedFileContainer = styled.div`
  padding: 0 2rem;
`;

export const AttachedFile = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  img {
    max-width: 100% !important;
    height: auto !important;
  }
`;

// # 질문답변_리스트
export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  div.list-container {
    padding-bottom: 6.6rem;
  }
`;
export const QnaListContainer = styled(Collapse)`
  .ant-collapse-item > .ant-collapse-header {
    padding: 0;
    color: ${colors.black_01_basic};
  }
  .ant-collapse-item.ant-collapse-no-arrow > .ant-collapse-header {
    padding: 0;
  }
`;
export const QnaPanel = styled(Panel)`
  .ant-collapse-content > .ant-collapse-content-box {
    background-color: ${colors.grey_01_bg};
  }
`;
export const QnaList = styled.div<IPanel>`
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 2rem;
  background-color: ${(props) =>
    props.active ? colors.grey_01_bg : colors.white_01_basic};
`;
export const QuestionDttm = styled.div`
  width: 100%;
`;
export const Date = styled.span`
  font-size: 1rem;
  color: ${colors.grey_05_subtext};
  margin-left: 1rem;
`;
export const Question = styled.div`
  width: 100%;
  font-size: 1.4rem;
  font-weight: bold;
  margin-top: 0.4rem;
`;
export const AdminInfo = styled.div`
  font-size: 1.2rem;
  font-weight: bold;
  color: ${colors.grey_05_subtext};
`;
export const FixedFuncButton = styled(Button)`
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 0;
  font-size: 1.4rem;
  width: 100%;
  height: 5rem;
`;

// # 질문하기
export const TextArea = styled.textarea`
  resize: none;
  border: none;
  outline: none;
  border-style: none;
  border-color: Transparent;
  overflow: auto;
  padding: 2rem;
  width: 100%;
  height: calc(100% - 5rem);
  font-size: 1.6rem;
  color: ${colors.grey_05_subtext};
`;
