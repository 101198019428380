import { useState } from 'react';

export default function useBottomSheet() {
  const [visible, setVisible] = useState<boolean>(false);
  function close() {
    setVisible(false);
  }
  function open() {
    setVisible(true);
  }
  return { visible, open, close };
}
