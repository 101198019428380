import { ReactNode } from 'react';
import { Modal } from '.';
import { useModalState } from '../../hooks';
import { Paragraph } from './AlertModal.styles';

interface IAlertModal {
  children: ReactNode;
  modalName: string;
}

function AlertModal({ children, modalName }: IAlertModal) {
  const { modalOpen, closeModal } = useModalState(modalName);
  const leftButtonHandler = () => {
    closeModal({ permanent: true });
  };

  const rightButtonHandler = () => {
    closeModal({ permanent: false });
  };

  return (
    <Modal
      isOpen={modalOpen}
      leftButtonTitle="다시 보지 않기"
      rightButtonTitle="확인"
      leftButtonHandler={leftButtonHandler}
      rightButtonHandler={rightButtonHandler}
    >
      <Paragraph>{children}</Paragraph>
    </Modal>
  );
}

export default AlertModal;
