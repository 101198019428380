import styled from '@emotion/styled';
import { colors } from '../../components/Styles/theme';

export interface IItem {
  isEmpty?: boolean;
}
export const Container = styled.article``;

export const Item = styled.div<IItem>`
  box-shadow: ${({ isEmpty }) =>
    isEmpty ? 'none' : '0 0.2rem 1.2rem 0 rgba(0, 0, 0, 0.15)'};
  background-color: ${({ isEmpty }) =>
    isEmpty ? colors.grey_01_bg : colors.white_01_basic};
  border-radius: 1.6rem;
  padding: 2.2rem;
  min-height: ${({ isEmpty }) => (isEmpty ? 'inherit' : '11.5rem')};
  margin: ${({ isEmpty }) => (isEmpty ? '1rem 1rem 2rem' : '1rem 0')};
  position: relative;

  .tags {
    position: absolute;
    right: 1rem;
    top: 1rem;

    button {
      margin-left: 0.8rem;
    }
  }
`;

export const Message = styled.dl`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  dt {
    width: 6rem;
    margin-right: 1.5rem;
    img {
      width: 6rem;
      height: 6rem;
      border-radius: 100%;
    }
  }
  dd {
    padding-top: 1rem;
    color: ${colors.grey_05_subtext};
    font-size: 1.2rem;

    .date {
      font-size: 1rem;
      color: ${colors.grey_05_subtext};
      margin-bottom: 0.4rem;
    }

    .message {
      font-size: 1.4rem;
      color: ${colors.black_01_basic};
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }

    .empty {
      font-size: 1.4rem;
      font-weight: bold;
      color: ${colors.grey_04_inactive};
      padding-top: 1rem;
    }
  }
`;
