import { useMutation, useQuery, useQueryClient } from 'react-query';
import { get, post } from '../lib/request';

interface IMeta {
  max_sync_seq: number;
  size: number;
}

interface IContentPreview {
  id: number;
  mission_name: string;
  description: string | null;
  level: number;
  action_notification: null;
  is_read: boolean;
}

interface IContent {
  id: number;
  week: number;
  week_name: string;
  mission_version: number;
  mission_previews: IContentPreview[];
}

export interface IContentList {
  mission_weeks: IContent[];
  meta: IMeta;
}

const SYNC_SEQ = '1';

export const useGetHealthInfoContents = () => {
  return useQuery<IContentList, Error>(
    ['healthinfo'],
    () => get(`/mission_weeks?sync_seq=${SYNC_SEQ}`),
    {
      staleTime: Infinity,
      onError: () => {
        console.log('미션 목록 조회(GET) API 에러');
      },
    },
  );
};

interface IMissionContents {
  id: number;
  description: string;
  question: number;
  choices: string;
  display_order: number;
  image: {
    url: string;
  };
}

interface IMissions {
  id: number;
  level: number;
  mission_name: string;
  description: string;
  mission_category: number;
  mission_category_nm: string;
  completion_comment: string | null;
  menu_id: number | null;
  action_notification: string | null;
  week_name: string;
  mission_contents: IMissionContents[];
}

export interface IContentsDetail {
  missions: IMissions[];
  meta: IMeta;
}

export const useGetHealthInfoContentsDetail = (id: number) => {
  return useQuery<IContentsDetail, Error>(
    ['healthinfodetail', id],
    () => get(`mission_weeks/${id}?sync_seq=${SYNC_SEQ}`),
    {
      staleTime: Infinity,
      onError: () => {
        console.log('미션 상세 조회(GET) API 에러');
      },
    },
  );
};

export interface IPopularMission {
  no: number;
  id: number;
  mission_name: string;
  mission_week_id: number;
  mission_content_id: number;
  is_read: boolean;
}

export interface IPopularContents {
  missions: IPopularMission[];
}

export const useGetHealthInfoPopular = () => {
  return useQuery<IPopularContents, Error>(
    ['healthinfopopular'],
    () => get(`mission_weeks/popular`),
    {
      staleTime: 1000 * 60 * 10,
      onError: () => {
        console.log('미션 인기 목록 조회(GET) API 에러');
      },
    },
  );
};

export interface IRead {
  mission_id: number;
  completion_type: number;
}

export const usePostRead = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (content: IRead) => post(`/mission_completions`, content),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['healthinfopopular']);
        queryClient.invalidateQueries(['healthinfo']);
        queryClient.invalidateQueries(['home']);
      },
      onError: () => {
        console.log('미션 완료 등록(POST) API 에러');
      },
    },
  );
};
