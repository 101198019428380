import Home from '../assets/svg/Home';
import Menu from './Menu';
import Message from '../assets/svg/Message';
import Contents from '../assets/svg/Contents';
import Etc from '../assets/svg/Etc';
import { ReactComponent as Ocare } from '../assets/img/icon-tab-ocare-active.svg';
import { FooterStyle } from './Footer.styles';

function Footer() {
  const navs = [
    {
      id: 'home',
      link: '/',
      label: '홈',
      icon: <Home />,
    },
    {
      id: 'message',
      link: '/msgs',
      label: '메시지',
      icon: <Message />,
    },
    {
      id: 'ocare',
      link: null,
      label: '오케어',
      icon: <Ocare />,
    },
    {
      id: 'contents',
      link: '/contents',
      label: '건강정보',
      icon: <Contents />,
    },
    {
      id: 'etc',
      link: '/etc',
      label: '더보기',
      icon: <Etc />,
    },
  ];
  return (
    <FooterStyle>
      <nav>
        {navs.map((item) => (
          <Menu
            key={item.id}
            link={item.link}
            label={item.label}
            icon={item.icon}
          />
        ))}
      </nav>
    </FooterStyle>
  );
}

export default Footer;
