import { createSlice } from '@reduxjs/toolkit';
import { IMessages, IMissions, IReports, ITodayLogs } from './lib/types';

interface IHome {
  currentWeekLabel: number;
  diseaseName: string;
  subDiseaseName: string;
  coach: string;
  messages: IMessages[];
  missions: IMissions[];
  reports: IReports[];
  todayLogs: ITodayLogs[];
}

const initialState: IHome = {
  currentWeekLabel: 0,
  diseaseName: '',
  subDiseaseName: '',
  coach: '',
  messages: [],
  missions: [],
  reports: [],
  todayLogs: [],
};

export const homeSlice = createSlice({
  name: 'home',
  initialState,
  reducers: {
    setData: (state, action) => {
      const { data } = action.payload;
      state.diseaseName = data.disease_nm;
      state.subDiseaseName = data.sub_disease_nm;
      state.coach = data.coach_img_url;

      const filteredMessage = data.coach_msg_list.messages.filter(
        (message: any) => message.is_read === false,
      );
      state.messages = filteredMessage;
      state.missions = data.mission_list;
      state.reports = data.report_list;
      state.todayLogs = data.health_log_list;
    },
  },
});

export const { setData } = homeSlice.actions;

export default homeSlice.reducer;
