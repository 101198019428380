import { numberWithCommas } from 'src/lib/utils';
import { DayContainer, DayInfo, DayItem } from './Day.styles';
import { ICalendarDates, ILegendItem, IWeekInfo } from './lib/types';
import { calcRange, legendMarker } from './lib/utils';

interface IDay {
  item: ICalendarDates;
  week: IWeekInfo;
  // eslint-disable-next-line no-unused-vars
  dayClickHandler: (selectedDate: Date, prevWeek: IWeekInfo) => void;
}

function Day({ item, week, dayClickHandler }: IDay) {
  return (
    <DayContainer
      className={calcRange(item.date, week)}
      active={item.selectedMonth}
      onClick={() => {
        dayClickHandler(item.date, week);
      }}
    >
      <DayInfo isToday={item.isToday}>{item.date.getDate()}</DayInfo>
      <ul>
        {item.contents &&
          item.contents.map((data: ILegendItem) => (
            <DayItem
              key={data.id}
              marker={data.content ? legendMarker[data.marker] : ''}
            >
              {Number.isNaN(Number(data.content))
                ? data.content
                : numberWithCommas(data.content)}
              {data.unit}
            </DayItem>
          ))}
      </ul>
    </DayContainer>
  );
}

export default Day;
