import { useGetCoachIntro } from '../../services/useMsgs';
import {
  CoachIntroContainer,
  CoachImageContainer,
  CoachMessageContainer,
  ServiceNameContainer,
} from './HealthManager.styles';

interface IProps {
  detail?: boolean;
}

export default function HealthManagerHeader({ detail }: IProps) {
  const { data: coachData } = useGetCoachIntro(detail);

  const imageUrl = coachData?.image_url;
  const message = coachData?.intro;
  return detail ? (
    <CoachIntroContainer>
      <CoachImageContainer detail>
        <img src={imageUrl} alt="휴먼 코치 프로필 이미지" />
      </CoachImageContainer>
      <ServiceNameContainer>만성질환관리 Health Manager</ServiceNameContainer>
    </CoachIntroContainer>
  ) : (
    <CoachIntroContainer>
      <CoachImageContainer>
        <img src={imageUrl} alt="휴먼 코치 프로필 이미지" />
      </CoachImageContainer>
      <CoachMessageContainer>
        <div className="service-name">만성질환관리 Health Manager</div>
        <div className="message">{message}</div>
      </CoachMessageContainer>
    </CoachIntroContainer>
  );
}
