import styled from '@emotion/styled';
import { colors } from '../Styles/theme';
import { IGuideColor } from './lib/types';
import { FactorColors } from './lib/utils';

export const GuideContainer = styled.div`
  height: 25rem;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const GuideTitle = styled.div`
  margin-top: 3rem;
  font-size: 2rem;
  color: ${colors.black_01_basic};
  font-weight: bold;
`;

export const GuideInfo = styled.div`
  margin-top: 1rem;
  font-size: 1rem;
  width: 54%;
  color: ${colors.grey_05_subtext};
  text-align: center;
`;

export const GuideItemWrapper = styled.div`
  display: flex;
  width: 60%;
  margin-top: 3rem;
  justify-content: space-between;
`;

export const GuideItem = styled.div<IGuideColor>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  div:nth-of-type(1) {
    background-color: ${(props) => {
      return FactorColors?.[props.type];
    }};
    width: 4rem;
    height: 4rem;
    border-radius: 50%;
  }

  div:nth-of-type(2) {
    font-size: 1.4rem;
    font-weight: bold;
    color: ${(props) => {
      return FactorColors?.[props.type];
    }};
    margin-top: 1rem;
  }
`;
