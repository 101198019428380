import { Doughnut } from 'react-chartjs-2';

import {
  Chart as ChartJS,
  CategoryScale,
  ArcElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { ContainerDoughnut } from './Chart.styles';
import { colors } from '../../../components/Styles/theme';

ChartJS.register(CategoryScale, ArcElement, Title, Tooltip, Legend);

ChartJS.defaults.font.family = 'Noto Sans KR';
ChartJS.defaults.font.size = 10;

interface InutrientsLog {
  label: string;
  value: number | string | undefined;
  note: string;
}
interface IDoughnutChart {
  nutrients: InutrientsLog[];
}
function DoughnutChart({ nutrients }: IDoughnutChart) {
  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
        position: 'top' as const,
        align: 'end' as const,
        labels: {
          color: `${colors.grey_05_subtext}`,
          usePointStyle: true,
        },
      },
    },
  };

  const mealColorSet = [
    colors.category_1,
    colors.primary_03_basic,
    colors.category_2,
  ];

  const data = {
    labels: nutrients.map((nutrient) => nutrient.label),
    datasets: [
      {
        label: 'nutrient',
        data:
          nutrients[0].value !== '-'
            ? nutrients.map((nutrient) => nutrient.value)
            : [100],
        backgroundColor: (): any => {
          return mealColorSet;
        },
        borderWidth: 0,
      },
    ],
  };

  return (
    <ContainerDoughnut>
      <Doughnut options={options} data={data} />
    </ContainerDoughnut>
  );
}

export default DoughnutChart;
