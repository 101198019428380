import styled from '@emotion/styled';
import { colors } from '../../../components/Styles/theme';

export const DayWrapper = styled.ul`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  bottom: 0.1rem;
  list-style: none;
  box-sizing: border-box;
  & .range {
    border-bottom: 0.1rem solid ${colors.primary_03_basic};
    border-top: 0.1rem solid ${colors.primary_03_basic};
    padding-top: 0.2rem;
    background-color: ${colors.primary_01_highlight};
  }
  & .rangeStart {
    border-left: 0.1rem solid ${colors.primary_03_basic};
    padding-left: 0.2rem;
  }
  & .rangeEnd {
    border-right: 0.1rem solid ${colors.primary_03_basic};
  }
  & .removeBorder {
    border-bottom: 0.1rem solid transparent;
  }
`;
