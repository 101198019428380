import { createSlice } from '@reduxjs/toolkit';
import { setRange, setRangeGraph, getLastIndex } from './lib/utils';
import { ILog, IStat } from './lib/types';

interface ILogs {
  selectedIndex: number | null;
  categoryNumber: string;
  diseaseNumber: string;
  type: string;
  weekLabel: number;
  logs: ILog[];
  logsGraph: ILog[];
  homeLogs: ILog[];
  homeLogsGraph: ILog[];
  stat: IStat;
  avgWeight?: number;
}

const initialState: ILogs = {
  selectedIndex: null,
  categoryNumber: '',
  diseaseNumber: '',
  type: 'graph',
  weekLabel: 0,
  logs: [],
  logsGraph: [],
  homeLogs: [],
  homeLogsGraph: [],
  stat: {},
  avgWeight: 0,
};

export const lifeLogsSlice = createSlice({
  name: 'lifeLogs',
  initialState,
  reducers: {
    setCategoryNumber: (state, action) => {
      const { categoryNumber } = action.payload;
      state.categoryNumber = categoryNumber;
    },
    setDisease: (state, action) => {
      const { diseaseNumber } = action.payload;
      state.diseaseNumber = diseaseNumber;
    },
    setType: (state, action) => {
      const { type } = action.payload;
      state.type = type;
    },
    setWeekLabel: (state, action) => {
      const { weekLabel } = action.payload;
      state.weekLabel = weekLabel;
    },
    setLogs: (state, action) => {
      const { isPreviousData, data, currentWeek } = action.payload;
      if (!isPreviousData) {
        const range = setRange(currentWeek, data.logs);
        const rangeGraph = setRangeGraph(state.categoryNumber, range); // 그래프를 위한 logs

        state.logs = range;
        state.logsGraph = rangeGraph;

        state.stat = data.stat;
        state.avgWeight = data.avg_weight;

        const lastIndex = getLastIndex(rangeGraph);
        state.selectedIndex = lastIndex;
      }
    },
    setHomeLogs: (state, action) => {
      const { isPreviousData, data, currentWeek } = action.payload;
      if (!isPreviousData) {
        const range = setRange(currentWeek, data.logs);
        const rangeGraph = setRangeGraph(state.diseaseNumber, range);

        state.homeLogs = range;
        state.homeLogsGraph = rangeGraph;

        state.stat = data.stat;
        state.avgWeight = data.avg_weight;

        const lastIndex = getLastIndex(rangeGraph);
        state.selectedIndex = lastIndex;
      }
    },
    setIndex: (state, action) => {
      state.selectedIndex = action.payload;
    },
  },
});

export const {
  setCategoryNumber,
  setDisease,
  setType,
  setWeekLabel,
  setLogs,
  setHomeLogs,
  setIndex,
} = lifeLogsSlice.actions;

export default lifeLogsSlice.reducer;
