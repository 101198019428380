export function toStringByFormatting(source: Date, delimter = '') {
  function leftPad(value: number) {
    if (value >= 10) {
      return value;
    }
    return `0${value}`;
  }

  const year = source.getFullYear();
  const month = leftPad(source.getMonth() + 1);
  const day = leftPad(source.getDate());
  return `${year}${delimter}${month}${delimter}${day}`;
}
