import { useState, useEffect, useMemo } from 'react';
import { useGetReports } from '../../../../services/useReports';
import { ICalendarDates, IDates, ILegendItem, IUseGetReports } from './types';
import { getCalendar, categoryObj } from './utils';

function makeDayData(
  reportData: IUseGetReports,
  category: string,
  date: IDates,
) {
  const contents: ILegendItem[] = categoryObj[category].legend.map((key) => {
    return {
      id: category + reportData.log_day + key.objKey,
      marker: key.marker,
      content: reportData[key.objKey as string],
      unit: key.unit,
    };
  });
  return { ...date, contents };
}

export function useCalendarDates(selectedDate: Date, category: string) {
  const [calendarDates, setcalendarDates] = useState<ICalendarDates[]>(
    getCalendar(selectedDate, category),
  );
  useEffect(() => {
    setcalendarDates(getCalendar(selectedDate, category));
  }, [selectedDate, category]);

  const days = useMemo(
    () => getCalendar(selectedDate, category),
    [selectedDate, category],
  );
  const reports = useGetReports(category, days);
  useEffect(() => {
    if (reports.data) {
      const datas: Array<ICalendarDates> = reports.data.map((item, idx) =>
        makeDayData(item, category, days[idx]),
      );
      setcalendarDates(datas);
    }
  }, [reports.data, days, category]);

  return { calendarDates };
}
