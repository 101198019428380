import styled from '@emotion/styled';
import { colors } from '../../../components/Styles/theme';

export const Container = styled.article`
  position: relative;
  margin: 0 2rem 0 0;
`;

export const ContainerDoughnut = styled.article`
  width: 35%;
  height: 35%;
`;

export const Empty = styled.article`
  position: absolute;
  left: 50%;
  bottom: 50%;
  margin-left: -50%;
  width: 100%;
  padding-left: 2rem;
  text-align: center;
  font-size: 1.2rem;
  font-weight: bold;
`;

export const Unit = styled.div<{ isLegend: boolean; isEmpty: boolean }>`
  position: absolute;
  left: 1rem;
  top: ${({ isLegend, isEmpty }) =>
    isLegend && !isEmpty ? '0.5rem' : '-0.7rem'};
  color: ${colors.grey_04_inactive};
`;
