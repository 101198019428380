import { useWeekList } from '../../../hooks';
import ReportItem from './reportItem';
import ReportListEmpty from './reportListEmpty';

function ReportList() {
  const { weeks } = useWeekList();

  const filteredWeeks = weeks.slice(1, weeks.length - 1); // 현재 진행중인 주차 & 0주차 제외 로직

  return (
    <>
      {filteredWeeks.length === 0 && <ReportListEmpty />}
      {filteredWeeks.map((item) => (
        <ReportItem info={item} key={item.label} />
      ))}
    </>
  );
}

export default ReportList;
