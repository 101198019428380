import styled from '@emotion/styled';
import { colors } from 'src/components/Styles/theme';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;

  & p {
    color: ${colors.grey_05_subtext};
    text-align: center;
    font-size: 1.4rem;
    margin-top: 1rem;
  }
`;
