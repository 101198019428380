import { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router';
import { useGetLogin } from 'src/services/useLogin';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/app/store';
import {
  StepContainer,
  Title,
  SectionStep,
  TotalStep,
  CurrStep,
  Question,
  ButtonContainer,
  AnswerContainer,
} from './Questionnaires.styles';
import {
  useGetQuestionnaires,
  usePostQuestionnaires,
} from '../../services/useQuestionnaires';
import { DotsProgress } from '../../components/Progress';
import { Button } from '../../components/Button';
import { IResponse, IDatePicker, IQuestions, IRes } from './lib/types';
import Choices from './Question';
import { subjectInputLimit } from './lib/constants';
import {
  initSurveyInfo,
  setQuestionnairesId,
  setSurveyInfo,
} from './surveyInfoSlice';
import { reconnectionModalOn } from './checkBackButtonSlice';

interface IStep {
  questionnairesId: number;
  birthday: string;
}

export default function Step({ questionnairesId, birthday }: IStep) {
  const dispatch = useDispatch();
  const {
    backResponses,
    backCurrIndex,
    backIsSubQstn,
    backChoiceNum,
    backSubQstnIndex,
  } = useSelector((state: RootState) => state.surveyInfo);

  const { data: questionnairesData } = useGetQuestionnaires(questionnairesId);
  const { isFetching } = useGetLogin();
  const [currIndex, setCurrIndex] = useState<number>(backCurrIndex || 0);

  // # 최종_body payload
  const [responses, setResponses] = useState<IResponse[]>(backResponses || []);
  const [currQuestion, setCurrQuestion] = useState<IQuestions | undefined>(); // 선택된 설문
  // # 진단일자포함된 선택지 형태 상태 관리 : [{choiceId: 1, date: '20220406'}, { ... }]
  const [selectQuestion, setSelectQuestion] = useState<IDatePicker[]>([]);
  const [last, setLast] = useState(false);
  const [isSubQstn, setIsSubQstn] = useState(backIsSubQstn || false); // 현재 선택한 선택지가 subQuestion 을 가지고 있는지
  const [choiceNumber, setChoiceNumber] = useState(
    backChoiceNum >= 0 ? backChoiceNum : -1,
  ); // 선택된 선택지 번호
  const [subQstnIndex, setSubQstnIndex] = useState(
    backSubQstnIndex >= 0 ? backSubQstnIndex : -1,
  ); // subQuestion 의 설문 번호

  const onGoing = currIndex + 1 > 1;
  const sectionId = questionnairesData?.questionnaire.id;
  const sections = questionnairesData?.questionnaire.sections[0];
  const questions = sections?.questions;
  const lastQuestion =
    questions !== undefined && questions.length === currIndex + 1;

  const setCurQstnByIndex = useCallback(() => {
    if (choiceNumber >= 0) {
      const subQstn =
        sections?.questions[currIndex].choices[choiceNumber].subquestions;
      if (subQstn !== undefined) setCurrQuestion(subQstn[subQstnIndex]);
    } else setCurrQuestion(sections?.questions[currIndex]);
  }, [subQstnIndex, choiceNumber, currIndex, sections, setCurrQuestion]);

  useEffect(() => {
    setCurQstnByIndex();
  }, [setCurQstnByIndex]);

  useEffect(() => {
    dispatch(
      setSurveyInfo({
        backResponses: responses,
        backCurrIndex: currIndex,
        backIsSubQstn: isSubQstn,
        backChoiceNum: choiceNumber,
        backSubQstnIndex: subQstnIndex,
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currQuestion]);

  useEffect(() => {
    dispatch(setQuestionnairesId(questionnairesId));
  }, [dispatch, questionnairesId]);

  const { mutate: questionnairesMutate, isSuccess: questionnairesIsSuccess } =
    usePostQuestionnaires(questionnairesId);

  // # <- 이전
  const handlePrev = () => {
    if (!currIndex) return;
    if (isSubQstn && subQstnIndex > 0) setSubQstnIndex(subQstnIndex - 1);
    else if (!subQstnIndex) {
      setIsSubQstn(false);
      setSubQstnIndex(-1);
      setChoiceNumber(-1);
    } else setCurrIndex(currIndex - 1);
  };

  // # 다음 -> / 완료
  const handleNext = () => {
    const resObject: IRes = {
      choice_ids: [],
      response_value: [],
      question_id: currQuestion?.id,
      related_id: -1,
    };
    const dateArr: string[] = [];

    // 진단일자 없는 항목
    const isSubject = selectQuestion
      .map((item) => {
        return item.date;
      })
      .some((item) => item === undefined);

    // 주관식
    const isSelect = selectQuestion
      .map((item) => {
        return item.text;
      })
      .some((item) => item);

    const subjectIdx = selectQuestion.map((item) => {
      return item.index;
    });

    if (selectQuestion.length === 0) {
      console.log('선택된 답변이 없습니다.');
      return;
    }

    if (!isSubject) {
      // 다중선택 & 진단일자
      console.log('다중선택 & 진단일자');
      Object.keys(selectQuestion).forEach((key: any) => {
        resObject.choice_ids.push(selectQuestion[key].choiceId);
        resObject.response_value.push(selectQuestion[key].date);
      });
      // 주관식
    } else if (isSubject && isSelect) {
      console.log('주관식');
      Object.keys(selectQuestion).forEach((key: any) => {
        resObject.choice_ids = [0];
        resObject.response_value.push(selectQuestion[key].text);
        if (subQstnIndex >= 0) resObject.related_id = questions![currIndex].id;
      });
      // 기본 다중선택
    } else if (isSubject && !isSelect && currQuestion?.multi_response) {
      console.log('기본 다중선택');
      Object.keys(selectQuestion).forEach((key: any) => {
        resObject.choice_ids.push(selectQuestion[key].choiceId);
        dateArr.push(selectQuestion[key].date);
      });
      const result = dateArr.filter((el) => el);
      resObject.response_value.push(...result);
      // 단일선택
    } else if (isSubject && selectQuestion.length === 1) {
      console.log('단일선택');
      Object.keys(selectQuestion).forEach((key: any) => {
        resObject.choice_ids.push(selectQuestion[key].choiceId);
        resObject.response_value = [''];
        if (subQstnIndex >= 0) resObject.related_id = questions![currIndex].id;
      });
    }

    const res = responses.slice().filter((item) => {
      if (isSubQstn) return true;
      return resObject.question_id !== item.related_id;
    });

    const duplicateIndex = responses.findIndex(
      (item) => resObject.question_id === item.question_id,
    );
    if (duplicateIndex >= 0) res[duplicateIndex] = resObject;
    else res.push(resObject);

    if (isSubQstn && choiceNumber === -1)
      setChoiceNumber(subjectIdx[0] !== undefined ? subjectIdx[0] : 0);

    setResponses(res);

    if (
      choiceNumber !== -1 &&
      subQstnIndex + 1 ===
        sections?.questions[currIndex].choices[choiceNumber].subquestions.length
    ) {
      setIsSubQstn(false);
      setChoiceNumber(-1);
      setSubQstnIndex(-1);
      setCurrIndex(currIndex + 1);
      return;
    }

    if (lastQuestion) {
      setLast(true);
      return;
    }
    if (isSubQstn) setSubQstnIndex(subQstnIndex + 1);
    else setCurrIndex(currIndex + 1);
  };

  // # 마지막 문항에서 [완료] 버튼 누르면, setState 후 mutate가 순차적으로 실행되도록 함
  useEffect(() => {
    if (last) {
      const res = responses
        .sort((a, b) => {
          if (a.question_id === undefined || b.question_id === undefined)
            return 0;
          return a.question_id - b.question_id;
        })
        .map((item) => {
          /* eslint-disable camelcase */
          const { choice_ids, response_value, question_id } = item;
          return {
            choice_ids,
            response_value,
            question_id,
          };
        });
      /* eslint-enable camelcase */
      questionnairesMutate(res);
      dispatch(initSurveyInfo());
      dispatch(reconnectionModalOn());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [last]);

  const navigate = useNavigate();
  useEffect(() => {
    if (isFetching) return;
    if (questionnairesIsSuccess) {
      if (questionnairesId === 4) {
        navigate(`/etc/cvd/?prev=questionnaires`);
        return;
      }
      if (questionnairesId === 0) {
        navigate('/');
        return;
      }
      navigate(`/questionnaires/end/?step=${questionnairesId - 1}`);
    }
  }, [questionnairesIsSuccess, questionnairesId, isFetching, navigate]);

  if (sectionId === undefined) {
    return null;
  }

  return (
    <StepContainer>
      <div className="step">
        <DotsProgress progress={[1, 2, 3, 4]} currStep={sectionId} />
        <Title>{sections?.title}</Title>
        <SectionStep>
          <CurrStep>
            {subQstnIndex < 0
              ? `${currIndex + 1}`
              : `${currIndex + 1} - ${subQstnIndex + 1}`}
          </CurrStep>
          <TotalStep>&nbsp;/&nbsp;{sections?.questions.length}</TotalStep>
        </SectionStep>
        <Question>{currQuestion?.text}</Question>
        <AnswerContainer>
          <Choices
            questionId={currQuestion?.id}
            responseType={currQuestion?.response_type_id}
            multiResponse={currQuestion?.multi_response}
            unit={currQuestion?.response_value_unit}
            limitSize={subjectInputLimit(
              currQuestion?.response_value_unit,
              birthday,
            )}
            choices={currQuestion?.choices}
            responses={responses}
            selectQuestion={selectQuestion}
            setSelectQuestion={setSelectQuestion}
            setIsSubQstn={setIsSubQstn}
          />
        </AnswerContainer>
      </div>
      {/* Warning: Received `false` for a non-boolean attribute `display`.  */}
      <ButtonContainer display={onGoing ? 1 : 0}>
        {onGoing && (
          <Button type="arrowLeft" onClick={handlePrev}>
            이전
          </Button>
        )}
        <Button
          disabled={
            selectQuestion.length === 0 || selectQuestion[0].text?.length === 0
          }
          onClick={handleNext}
          type={lastQuestion ? 'arrowComplete' : 'arrowRight'}
        >
          {lastQuestion ? '완료' : '다음'}
        </Button>
      </ButtonContainer>
    </StepContainer>
  );
}
