import {
  useLocation,
  useNavigationType,
  UNSAFE_NavigationContext as NavigationContext,
} from 'react-router';
import { useSearchParams } from 'react-router-dom';
import {
  SetStateAction,
  Dispatch,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { on } from './features/questionnaires/checkBackButtonSlice';
import { RootState } from './app/store';

function usePrevious(path: string) {
  const ref = useRef<string>();
  useEffect(() => {
    ref.current = path;
  }, [path]);
  return ref.current;
}

const goToMainPath = ['/msgs', '/qna', '/contents', '/etc'];
const isSurveyPage = [
  '/questionnaires/step',
  '/questionnaires/end/',
  '/etc/cvd/',
];

function useHistoryRoutes(setIsExit: Dispatch<SetStateAction<boolean>>) {
  const dispatch = useDispatch();
  const { questionnairesId } = useSelector(
    (state: RootState) => state.surveyInfo,
  );
  const { navigator } = useContext(NavigationContext);
  const { pathname: url } = useLocation();
  const navType = useNavigationType();
  const [params] = useSearchParams();
  const pageKey = params.get('pagekey');
  const [curPath, setCurPath] = useState<string>('/');
  const prevPath = usePrevious(curPath);

  useEffect(() => {
    setCurPath(url);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url]);

  const isFinish = prevPath === '/' && navType === 'POP';
  const isGoMain = goToMainPath.includes(prevPath!) && navType === 'POP';
  const isSurveyBackButton =
    isSurveyPage.includes(curPath) && curPath === prevPath && navType === 'POP';

  useEffect(() => {
    if (url === '/' && navType === 'POP') {
      if (pageKey === 'msgs') {
        const msgID = params.get('id');
        navigator.push('/');
        navigator.push('/msgs');
        navigator.push(`/msgs/advice/detail/${msgID}`);
      }
      if (pageKey === 'qna') {
        // const qnaID = params.get('id');
        navigator.push('/');
        navigator.push('/qna');
      }
      if (pageKey === 'logs') {
        const categoryNumber = params.get('category');
        navigator.push('/');
        navigator.push(`/main/logs/${categoryNumber}`);
      }
    }

    if (isFinish) {
      navigator.push('/');
      setIsExit(true);
    } else if (isGoMain) {
      navigator.push('/');
    } else if (isSurveyBackButton) {
      if (curPath === '/etc/cvd/')
        navigator.push('/etc/cvd/?prev=questionnaires');
      else if (curPath === '/questionnaires/end/')
        navigator.push(`/questionnaires/end/?step=${questionnairesId - 1}`);
      else navigator.push('/');
      dispatch(on());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    navigator,
    params,
    url,
    navType,
    pageKey,
    setIsExit,
    isFinish,
    isGoMain,
    isSurveyBackButton,
    dispatch,
  ]);
}

export default useHistoryRoutes;
