import { IMessages } from '../../../services/useMsgs';
import {
  ListItem,
  MessageInfoDiv,
  SideDiv,
  Title,
} from '../HealthManager.styles';
import { Tag } from '../../../components/Button';
import Like from '../Like';
import { getFormattedDate, parseToDate } from '../../../lib/utils';

export default function AdviceMsgs({
  id,
  created_at: createdAt,
  category,
  is_read: isRead,
  liked,
  title,
}: IMessages) {
  return (
    <ListItem>
      <div className="container">
        <span className="msg-check-dttm">{!isRead && <span />}</span>
        <MessageInfoDiv>
          <span className="created-at">
            {createdAt &&
              getFormattedDate(parseToDate(createdAt), 'MM월 dd일, a hh:mm')}
          </span>
          <SideDiv>
            {category && <Tag type="basic5">{category}</Tag>}
            <Like id={id} liked={liked} />
          </SideDiv>
        </MessageInfoDiv>
      </div>
      <Title to={`/msgs/advice/detail/${id}`} textColor={!isRead}>
        {title}
      </Title>
    </ListItem>
  );
}
