import { useEffect, useState, useMemo } from 'react';
import { getWeekInfo } from '../lib/utils';
import { useUser } from '.';

export interface IWeekInfo {
  label: number;
  startDate: Date;
  endDate: Date;
}

export default function useWeekList() {
  const {
    user: {
      service_start_dt: serviceStartDate,
      registration_dttm: registeredDate,
    },
  } = useUser();

  const parsedServiceStartDate = useMemo(
    () => new Date(serviceStartDate),
    [serviceStartDate],
  );

  const parsedRegisteredDate = useMemo(
    () => new Date(registeredDate),
    [registeredDate],
  );

  const nowWeek = getWeekInfo(parsedServiceStartDate, new Date());
  const [weeks, setWeeks] = useState<IWeekInfo[]>([]);

  useEffect(() => {
    const startYear = parsedServiceStartDate.getFullYear();
    const startMonth = parsedServiceStartDate.getMonth();
    const startDate = parsedServiceStartDate.getDate();
    const arr: IWeekInfo[] = [];
    if (parsedRegisteredDate.getTime() !== parsedServiceStartDate.getTime()) {
      arr.push({
        label: 0,
        startDate: parsedRegisteredDate,
        endDate: new Date(startYear, startMonth, startDate - 1),
      });
    }
    for (let i = 0; i < nowWeek.label; i += 1) {
      arr.push({
        label: i + 1,
        startDate: new Date(startYear, startMonth, startDate + 7 * i),
        endDate: new Date(startYear, startMonth, startDate + 6 + 7 * i),
      });
    }
    setWeeks(arr.reverse());
  }, [parsedServiceStartDate, nowWeek.label, parsedRegisteredDate]);

  return { weeks };
}
