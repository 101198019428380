import { Modal } from '../../components/Modal';

interface IQuestionnairesModal {
  leftButtonHandler: () => void;
  rightButtonHandler: () => void;
  isOpen: boolean;
}

export default function QuestionnairesModal({
  isOpen,
  leftButtonHandler,
  rightButtonHandler,
}: IQuestionnairesModal) {
  return (
    <Modal
      isOpen={isOpen}
      leftButtonTitle="아니오"
      rightButtonTitle="예"
      leftButtonHandler={leftButtonHandler}
      rightButtonHandler={rightButtonHandler}
    >
      <div>
        설문을 완료해야 <br /> 앱을 사용할 수 있습니다. <br /> 종료하시겠습니까?
      </div>
    </Modal>
  );
}
