import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import WeekSwiper from './WeekSwiper';
import WeekContentList from './WeekContentList';
import { useGetHealthInfoContents } from '../../../services/useHealthInfo';
import { useWeekList } from '../../../hooks';
import { changeSwipeWeek } from '../healthInfoSlice';

function WeekContentPage() {
  const dispatch = useDispatch();
  const { weeks: userWeek } = useWeekList();
  let week = userWeek.length > 25 ? 25 : userWeek.length;
  week = week > 0 ? week - 1 : 0;
  useEffect(() => {
    if (week && week !== null) {
      dispatch(changeSwipeWeek(week));
    }
  }, [dispatch, week]);
  const { data, isLoading, isError, isIdle } = useGetHealthInfoContents();

  if (isLoading || isError || isIdle) return null;

  return (
    <>
      <WeekSwiper week={week} />
      <WeekContentList week={week} data={data} />
    </>
  );
}

export default WeekContentPage;
