import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import axios from 'axios';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { store } from './app/store';

const loading = document.getElementById('loading');
const url = new URL(window.location.href);
const urlToken = url.searchParams.get('token');
const token = decodeURIComponent(urlToken!);
console.log(window.history);

axios
  .post(
    `${process.env.REACT_APP_BASE_URL}loginHome`,
    {},
    {
      headers: {
        Authorization: token!,
      },
    },
  )
  .then((data) => {
    if (data.data === 'SUCCESS') {
      window.history.pushState(null, '', window.location.href);
    } else {
      window.history.pushState(null, '', window.location.origin);
      window.sessionStorage.clear();
    }
  })
  .catch(() => {
    window.history.pushState(null, '', window.location.origin);
    window.sessionStorage.clear();
  })
  .finally(() => {
    loading?.remove();
    ReactDOM.render(
      <React.StrictMode>
        <Provider store={store}>
          <App />
        </Provider>
      </React.StrictMode>,
      document.getElementById('root'),
    );
  });

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
