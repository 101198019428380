import styled from '@emotion/styled';
import { colors } from '../Styles/theme';

interface IModalBackGround {
  isOpen: boolean;
}

export const ModalBackGround = styled.div<IModalBackGround>`
  position: fixed;
  display: ${(props) => (props.isOpen ? 'flex' : 'none')};
  justify-content: center;
  align-items: center;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: ${colors.black_03_floating};
`;

export const ModalContainer = styled.div`
  display: flex;
  width: 28rem;
  border-radius: 1.6rem;
  flex-direction: column;
  justify-content: space-between;
  background-color: ${colors.white_01_basic};
  font-size: 1.6rem;
  text-align: center;
`;

export const ModalButtonContainer = styled.div`
  display: flex;
  height: 5rem;
  color: ${colors.black_01_basic};
  font-size: 1.4rem;
`;

export const ModalButtonLeft = styled.button`
  background-color: ${colors.primary_01_highlight};
  color: ${colors.black_01_basic};
  width: 50%;
  border-radius: 0 0 0 1.6rem;
`;

export const ModalButtonRight = styled.button`
  background-color: ${colors.primary_03_basic};
  color: ${colors.black_01_basic};
  width: 50%;
  border-radius: 0 0 1.6rem 0;
`;

export const ModalButtonSingle = styled.button`
  background-color: ${colors.primary_03_basic};
  color: ${colors.black_01_basic};
  width: 100%;
  border-radius: 0 0 1.6rem 1.6rem;
`;

export const ModalContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  padding: 5rem 0;
  word-break: keep-all;
  & div {
    padding: 0 2rem;
  }
`;
