import EtcTab from './EtcTab';
import { useUser } from '../../hooks';
import { UserName } from './index.styles';

function Etc() {
  const {
    user: { name: userName },
  } = useUser();

  return (
    <>
      <UserName>{userName} 고객님</UserName>
      <EtcTab link="/etc/target" title="자가기록 관리목표" />
      <EtcTab link="/etc/cvd" title="심뇌혈관 위험도 평가 결과" />
      <EtcTab link="/etc/report" title="리포트" />
    </>
  );
}

export default Etc;
