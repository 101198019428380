import { configureStore } from '@reduxjs/toolkit';
import checkBackButtonSlice from 'src/features/questionnaires/checkBackButtonSlice';
import surveyInfoSlice from 'src/features/questionnaires/surveyInfoSlice';
import homeReducer from '../features/home/homeSlice';
import lifeLogsReducer from '../features/lifeLog/lifeLogSlice';
import healthInfoSlice from '../features/healthInfo/healthInfoSlice';

export const store = configureStore({
  reducer: {
    home: homeReducer,
    lifeLogs: lifeLogsReducer,
    healthInfo: healthInfoSlice,
    checkBackButton: checkBackButtonSlice,
    surveyInfo: surveyInfoSlice,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
