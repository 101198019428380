import { useQuery } from 'react-query';
import { toStringByFormatting } from '../features/etc/reportList/lib/utils';
import { IDates, IUseGetReports } from '../features/report/calendar/lib/types';
import { categoryToNumber } from '../lib/utils';
import { get } from '../lib/request';

export const useGetReports = (category: string, dates: IDates[]) => {
  return useQuery<IUseGetReports[]>(
    ['reports', category, dates[0].date.toDateString()],
    () =>
      get(`/weekly_report/calendar`, {
        category: categoryToNumber?.[category],
        end_dt: toStringByFormatting(dates[dates.length - 1].date),
        start_dt: toStringByFormatting(dates[0].date),
      }),
  );
};
