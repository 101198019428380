import styled from '@emotion/styled';
import { colors } from '../../components/Styles/theme';

export const Container = styled.article``;

export const Item = styled.div`
  border-radius: 1.6rem;
  box-shadow: 0 0.2rem 1.2rem 0 rgba(0, 0, 0, 0.15);
  background-color: ${colors.white_01_basic};
  padding: 2.2rem;
  margin: 1rem 0;

  .tags {
    .date {
      color: ${colors.grey_05_subtext};
      margin-left: 0.8rem;
    }
  }

  dl {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 1rem;

    dt {
      font-size: 1.8rem;
      font-weight: bold;
    }
    dd {
      color: ${colors.grey_05_subtext};
      font-size: 1.2rem;

      .val {
        min-width: 15rem;
        margin-left: 0.6rem;
        text-align: right;

        strong {
          font-size: 1.8rem;
          font-weight: bold;
          color: ${colors.black_01_basic};
        }
      }
    }
  }
`;

export const Empty = styled.p`
  margin: 1rem 1rem 2rem;
  font-size: 1.4rem;
  font-weight: bold;
  color: ${colors.grey_04_inactive};
  border-radius: 1.6rem;
  background-color: ${colors.grey_01_bg};
  text-align: center;
  padding: 4rem 0;
`;
