import { useQueryClient } from 'react-query';
import { IUser } from '../services/useLogin';
import { diseaseType } from '../lib/utils';

export default function useUser() {
  const queryClient = useQueryClient();
  const { user } = queryClient.getQueryData('user') as IUser;
  const userDisease = diseaseType?.[user.disease_type];
  return { user, userDisease };
}
