import { useQuery } from 'react-query';
import { toStringByFormatting } from '../features/etc/reportList/lib/utils';
import { IStatistics } from '../features/report/lib/types';
import { categoryToNumber } from '../lib/utils';
import { get } from '../lib/request';

export const useGetStatistics = ({ category, week }: IStatistics) => {
  return useQuery(
    ['statistics', category, week.label],
    () =>
      get(`/weekly_report/calendar/stats`, {
        category: categoryToNumber?.[category],
        end_dt: toStringByFormatting(week.endDate),
        start_dt: toStringByFormatting(week.startDate),
      }),
    {
      staleTime: 1000 * 60 * 5,
    },
  );
};
