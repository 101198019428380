import { useMutation, useQuery, useQueryClient } from 'react-query';
import { get, post } from '../lib/request';

// # 메시지 목록 _ 상담메시지
const SYNC_SEQ = '1';
interface IUrl {
  url: string;
}
export interface IMessages {
  update_ts: number;
  admin_name: string;
  admin_profile_image: string;
  id: number;
  title: string;
  message: string;
  message_check_dttm: string;
  is_important: boolean;
  message_type: string;
  is_read: boolean;
  liked: boolean;
  category: string;
  created_at: string;
  images: IUrl[];
}
export interface IHealthManager {
  messages: IMessages[];
}

export interface IDetail {
  message: IMessages;
}
export interface IQna {
  update_ts: number;
  admin_name: string;
  admin_profile_image: string;
  id: number;
  question: string;
  question_dttm: string;
  answer: string;
  answer_dttm: string;
  answer_check_dttm: string;
  created_at: string;
  images: IUrl[];
}
export interface IQnas {
  qnas: IQna[];
}

interface IAdviceMsgsData {
  check_dttm: boolean;
  liked: boolean;
}

export interface ICoachIntro {
  intro: string;
  image_url: string;
}

interface IUsePostQnasRead {
  id: number;
  dttm: string;
}

// # 휴먼 코치 소개 문구
export const useGetCoachIntro = (detail: boolean | undefined) => {
  return useQuery<ICoachIntro, Error>(['coach'], () => get(`/coach_intro`), {
    enabled: Boolean(!detail),
    onError: () => {
      console.log('local error');
    },
  });
};

// # 메시지 목록 _ 상담메시지 목록
export const useGetAdviceMsgs = (category: string) => {
  return useQuery<IHealthManager, Error>(
    ['healthmanager', category],
    () => get(`/advice_msgs?sync_seq=${SYNC_SEQ}`),
    {
      cacheTime: 0,
      onError: () => {
        console.log('local error');
      },
    },
  );
};

// # 메시지 목록 _ 상담메시지 (상세)
export const useGetAdviceMsgsDetail = (id: number) => {
  return useQuery<IDetail, Error>(
    ['advicemsgdetail', id],
    () => get(`/advice_msgs/${id}`),
    {
      enabled: Boolean(id),
      onError: () => {
        console.log('local error');
      },
    },
  );
};

// # 질문답변 목록
export const useQnas = (enabled: boolean) => {
  return useQuery<IQnas, Error>(
    ['qna'],
    () => get(`/qnas?sync_seq=${SYNC_SEQ}`),
    {
      enabled,
      cacheTime: 0,
      onError: () => {
        console.log('local error');
      },
    },
  );
};

// # 질문하기
export const usePostQnas = (successHandler: () => void, question: string) => {
  const queryClient = useQueryClient();
  return useMutation(() => post(`/qnas?question=${question}`), {
    onSuccess: () => {
      queryClient.invalidateQueries(['qna']);
      successHandler();
    },
  });
};

export const usePostQnasRead = () => {
  return useMutation(
    ({ id, dttm }: IUsePostQnasRead) =>
      post(`/qnas/${id}`, {
        check_dttm: dttm,
      }),
    {
      onSuccess: (res) => {
        console.log('success :: ', res);
      },
    },
  );
};

// # 좋아요, 읽음
export const usePostAdviceMsgs = (id: number) => {
  const queryClient = useQueryClient();
  return useMutation(
    (body: IAdviceMsgsData) =>
      post(
        `/advice_msgs/${id}
  `,
        body,
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['healthmanager']);
        queryClient.invalidateQueries(['advicemsgdetail']);
        queryClient.invalidateQueries(['home']);
      },
    },
  );
};
