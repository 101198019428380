interface INext {
  color: string;
}

function Icon({ color }: INext) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <path
        fill="none"
        stroke={color}
        strokeLinejoin="round"
        d="M8 5l7 7-7 7"
      />
    </svg>
  );
}

export default Icon;
