import { Tag } from '../../../components/Button';
import { IWeekInfo } from '../calendar/lib/types';
import { getWeekInfo } from '../../../lib/utils';
import { FlexContainer, Span } from './index.styles';
import { useUser } from '../../../hooks';

interface IRangeWeekInfo {
  week: IWeekInfo;
}

function RangeWeekInfo({ week }: IRangeWeekInfo) {
  const {
    user: { service_start_dt: serviceStartDate },
  } = useUser();
  const displayWeek: IWeekInfo = {
    label: week.label,
    startDate: new Date(week.startDate.getTime()),
    endDate: new Date(week.endDate.getTime()),
  };
  if (displayWeek.label <= 0) {
    const firstWeek = getWeekInfo(
      new Date(serviceStartDate),
      new Date(serviceStartDate),
    );
    displayWeek.label = firstWeek.label;
    displayWeek.startDate = firstWeek.startDate;
    displayWeek.endDate = firstWeek.endDate;
  }
  return (
    <FlexContainer>
      <Tag type="basic1">{displayWeek.label}주차</Tag>
      <Span>
        {displayWeek.startDate.getMonth() + 1}월{' '}
        {displayWeek.startDate.getDate()}일
      </Span>
      <Span>-</Span>
      <Span>
        {displayWeek.endDate.getMonth() + 1}월 {displayWeek.endDate.getDate()}일
      </Span>
    </FlexContainer>
  );
}

export default RangeWeekInfo;
