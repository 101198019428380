import styled from '@emotion/styled';
import { colors } from '../../../components/Styles/theme';

export const Input = styled.input<{ align?: string; maxLen: number }>`
  border: none;
  text-align: ${(props) => props.align};
  margin: 0 0.6rem;
  color: ${colors.black_01_basic};
  font-size: 1.8rem;
  font-weight: bold;
  width: ${(props) => props.maxLen + 0.5}ch;

  &:disabled {
    background-color: transparent;
  }
`;

export const Unit = styled.span`
  font-size: 1.2rem;
  color: ${colors.grey_05_subtext};
`;

export const Dash = styled.span`
  font-size: 1.2rem;
  font-weight: bold;
`;
