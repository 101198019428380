import { Outlet, useParams } from 'react-router-dom';
import { usePathParams } from '../hooks';
import Header from './Header';
import Footer from './Footer';
import { Container } from './index.styles';

function Layout() {
  const params = useParams();
  const pathParam = usePathParams();
  const isParams = Object.values(params).length > 0;
  const convertedParams = Object.values(params).join('/');
  const path = pathParam.slice(0, pathParam.lastIndexOf(`/${convertedParams}`));

  // 메인과 서브메인은 푸터만, 그외는 헤더만 노출되어 path의 depth로 처리함
  const isMain = !(pathParam.split('/').slice(1).length - 1);

  return (
    <Container>
      {!isMain && <Header path={isParams ? path : pathParam} />}
      <main>
        <Outlet />
      </main>
      {isMain && <Footer />}
    </Container>
  );
}

export default Layout;
