import { useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../app/store';
import {
  Container,
  Record,
  ByDate,
  RecordItem,
  FoodName,
  Empty,
} from './Records.styles';
import { Tag } from '../../components/Button';
import {
  getFormattedDate,
  parseToDate,
  categoryType,
  numberWithCommas,
  callFinish,
  ocareCat,
  ocareJson,
} from '../../lib/utils';
import useGetCategory from './lib/useGetCategory';
import { alert } from './lib/alert';
import { listTarget } from './lib/list';
import { ReactComponent as Down } from '../../assets/img/icon-down-black.svg';
import Filter from '../../components/Filter';
import { AlertModal } from '../../components/Modal';
import { modalName } from '../../lib/modal';

function List() {
  const [filter, setFilter] = useState<string>('전체보기');
  const sortList = ['전체보기', '미입력'];
  const categories = useGetCategory();
  const { categoryNumber, logs } = useSelector(
    (state: RootState) => state.lifeLogs,
  );
  const allData = logs.filter((log) => Array.isArray(log));
  const noEnteredData = allData
    .map((arr) => arr.filter((log: any) => log.period === 0)) // 측정시기 미입력 데이터 필터링
    .filter((log) => log.length !== 0);
  const filteredData = filter === '미입력' ? noEnteredData : allData;
  const orderedData = filteredData.reverse();
  const isEmpty = !filteredData.length;
  const category = listTarget[categoryNumber];
  return (
    <Container type="list">
      {filter === '미입력' && (
        <AlertModal modalName={modalName.AlertModalSugar}>
          <>
            <p>
              혈당기록의 평가를 위해서는 측정시기가 매우 중요합니다. <br />
              아래 목록에서 혈당 수치를 선택한 후 저장해주세요.
            </p>
            <p>
              모든 기록을 설정하기 어렵다면, 이전 화면으로 돌아가도 다음에
              설정할 수 있습니다.
            </p>
          </>
        </AlertModal>
      )}
      {categoryNumber === categoryType.sugar && (
        <Filter
          filter={filter}
          filterList={sortList}
          setFilter={setFilter}
          margin="bottom"
        />
      )}
      {!isEmpty ? (
        <>
          {orderedData.map((el) => {
            const total = el.reduce((acc: number, curr: any) => {
              return acc + curr[category.value[0]];
            }, 0);
            const totalRatio =
              (total / categories[categoryNumber].goalMax) * 100;

            return (
              <Record key={el.id}>
                <ByDate
                  type={category.byDateType}
                  totalCal={category.byDateType === 'status' ? totalRatio : 0}
                  categoryNumber={categoryNumber}
                >
                  <div className="wrapper">
                    <h4>
                      {getFormattedDate(
                        parseToDate(el[0].log_dttm),
                        'yyyy년 MM월 dd일 E요일',
                      )}
                    </h4>
                    {category.byDateType === 'status' && (
                      <div className="value">
                        <strong>총 {numberWithCommas(total)}</strong> /{' '}
                        {numberWithCommas(categories[categoryNumber].goalMax)}
                        <span className="unit">kal</span>
                      </div>
                    )}
                  </div>
                </ByDate>
                {el.map((log: any) => {
                  // 혈당, 식사
                  const typeValue = category.type && log[category.type];

                  return (
                    <>
                      <RecordItem
                        key={log.id}
                        type="list"
                        classType="interval"
                        categoryNumber={categoryNumber}
                        alertIndex={
                          alert[categoryNumber](
                            log,
                            categories[categoryNumber].goalMax,
                          ).index
                        }
                      >
                        <dt>
                          <span className="time">
                            {getFormattedDate(
                              parseToDate(log.log_dttm),
                              'a hh:mm',
                            )}
                          </span>
                          {categoryNumber === categoryType.meal ? (
                            <img
                              src={log.image_info[0]?.filename}
                              alt="식사이미지"
                              className="mealImg"
                            />
                          ) : (
                            <span className="status">
                              {
                                alert[categoryNumber](
                                  log,
                                  categories[categoryNumber].goalMax,
                                ).label
                              }
                            </span>
                          )}
                        </dt>
                        <dd>
                          {/* device BLE */}
                          {category.device && log[category.device] && (
                            <Tag type="basic2">BLE</Tag>
                          )}

                          {/* type */}
                          {typeValue && <Tag type="basic5">{typeValue}</Tag>}
                          {categoryNumber === categoryType.sugar && !typeValue && (
                            <button
                              type="button"
                              onClick={() =>
                                callFinish(
                                  ocareJson({
                                    cat_code: ocareCat['혈당'],
                                    params: { page: 'logs' },
                                  }),
                                )
                              }
                            >
                              <Tag type="basic3">
                                측정시기 입력
                                <Down />
                              </Tag>
                            </button>
                          )}

                          {/* tags */}
                          {category.tags?.map((tag) => {
                            return (
                              <Tag key={tag.key} type="basic4">
                                {log[tag.key] === 0 ? '-' : log[tag.key]}{' '}
                                {tag.unit}
                              </Tag>
                            );
                          })}

                          {/* value */}
                          <div className="wrapper">
                            <span className="value">
                              {categoryNumber === categoryType.blood
                                ? `${numberWithCommas(log[category.value[0]])} /
                      ${numberWithCommas(log[category.value[1]])}`
                                : numberWithCommas(log[category.value[0]])}
                            </span>
                            <span className="unit">
                              {categories[categoryNumber].unit[0]}
                            </span>
                          </div>
                        </dd>
                      </RecordItem>
                      {categoryNumber === categoryType.meal && (
                        <FoodName>
                          {log.meal_foods.map((food: any, index: number) => {
                            return index === 0
                              ? food.food_name
                              : `, ${food.food_name}`;
                          })}
                        </FoodName>
                      )}
                    </>
                  );
                })}
              </Record>
            );
          })}
        </>
      ) : (
        <Empty>
          이번 주 기록이 없습니다. <br />
          기록만 해도 수치 관리에 도움이 됩니다.
        </Empty>
      )}
    </Container>
  );
}

export default List;
