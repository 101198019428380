import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import EmptyImage from 'src/assets/img/image-dashboard-contents.svg';
import { RootState } from '../../app/store';
import Header from './Header';
import SlickSlider from './SlickSlider';
import { Container, Item, Empty } from './Missions.styles';
import { IMissions } from './lib/types';

function Missions() {
  const { missions } = useSelector((state: RootState) => state.home);

  return (
    <Container>
      <Header to="/contents">1일 건강정보</Header>

      {missions.length > 0 ? (
        <SlickSlider opts={{ centerPadding: '85' }}>
          {missions.map((mission: IMissions) => {
            const {
              id,
              mission_id: missionId,
              mission_name: missionName,
              level,
            } = mission;
            return (
              <Link key={missionId} to={`/contents/detail/${id}/${missionId}`}>
                <Item level={level}>{missionName}</Item>
              </Link>
            );
          })}
        </SlickSlider>
      ) : (
        <Empty src={EmptyImage}>오늘의 건강정보를 모두 읽었어요!</Empty>
      )}
    </Container>
  );
}

export default Missions;
