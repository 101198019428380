import { useGetHealthInfoPopular } from '../../../services/useHealthInfo';
import PopularContent from './PopularContent';
import PopularContentEmpty from './PopularContentEmpty';
import { CustomLink } from './PopularContentList.styles';

function PopularContentList() {
  const { data, isError, isIdle, isLoading } = useGetHealthInfoPopular();
  if (isError || isIdle || isLoading) return null;

  const { missions: popularContents } = data;

  if (popularContents.length === 0) return <PopularContentEmpty />;

  return (
    <>
      {popularContents.map((item) => (
        <CustomLink
          key={item.mission_content_id}
          to={`/contents/detail/${item.mission_week_id}/${item.id}`}
        >
          <PopularContent {...item} />
        </CustomLink>
      ))}
    </>
  );
}

export default PopularContentList;
