import ResultCard from './ResultCard';
import FactorsTable from './FactorsTable';
import useBottomSheet from '../../../hooks/useBottomSheet';
import { BottomSheet } from '../../../components/Modal';
import {
  Container,
  ButtonWrapper,
  DateWrapper,
  Guidance,
  ShowGuideButton,
  VesselContainer,
} from './index.styles';
import RiskColorGuide from '../../../components/RiskColorGuide';
import { useGetFactors } from '../../../services/useFactors';
import { useQueryString, useUser } from '../../../hooks';
import Summary from './Summary';
import { Button } from '../../../components/Button';

function makeDateStirng(date: Date) {
  return `${date.getFullYear()}년 ${date.getMonth() + 1}월 ${date.getDate()}일`;
}

export default function Vessel() {
  const {
    user: { name: userName },
  } = useUser();

  const { visible, open, close } = useBottomSheet();
  const { data: fetchedData } = useGetFactors();
  const query = useQueryString();
  const { prev } = query;

  if (!fetchedData) return null;

  const {
    assessment_dttm: assessmentDateString,
    current_age: currentAge,
    current_incidence_rate: currentRate,
    following_age: followingAge,
    following_incidence_rate: followingRate,
    factors,
  } = fetchedData.assessments[0];

  const assessmentDate = new Date(assessmentDateString);

  return (
    <Container>
      <VesselContainer>
        <BottomSheet visible={visible} close={close}>
          <RiskColorGuide factor="심뇌혈관" />
        </BottomSheet>
        <DateWrapper>
          <p>평가일</p>
          <p>{makeDateStirng(assessmentDate)}</p>
        </DateWrapper>
        <ResultCard
          title={`${userName}님의 심뇌혈관 질환 위험 평가`}
          age={currentAge}
          percent={currentRate}
        />
        <Summary factors={factors} userName={userName} />
        <FactorsTable factors={factors} />
        <ButtonWrapper>
          <ShowGuideButton type="button" onClick={() => open()}>
            건강 위험도 기준 안내
          </ShowGuideButton>
        </ButtonWrapper>
        <ResultCard
          title={`${userName}님의 건강위험요인을 개선할 경우`}
          age={followingAge}
          percent={followingRate}
        />
        <Guidance>
          심뇌혈관질환 평가에 대한 결과는 고객님께서 입력하신 정보를 기반으로
          <br />
          도출한 것으로 참고용일뿐, 진료의 목적을 가지고 있지 않습니다.
        </Guidance>
      </VesselContainer>
      {prev && (
        <Button tag="a" type="rectangle" to="/questionnaires/step">
          다음
        </Button>
      )}
    </Container>
  );
}
