import styled from '@emotion/styled';
import { colors } from '../../components/Styles/theme';

interface ITab {
  isTab: boolean;
}

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const Tabs = styled.nav`
  width: 100%;
  display: flex;
`;

export const Tab = styled.button<ITab>`
  width: 50%;
  height: 5rem;
  font-size: 1.4rem;
  font-weight: bold;
  background-color: white;
  color: ${(props) =>
    props.isTab ? colors.black_01_basic : colors.grey_04_inactive};
  border-bottom: ${(props) =>
    props.isTab
      ? `0.2rem solid ${colors.primary_03_basic}`
      : `0.1rem solid ${colors.grey_02_line}`};
  text-align: center;
  line-height: 5rem;
  a {
    color: inherit;
  }
`;
