import { useState } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { QueryClientProvider } from 'react-query';
import { ToastContainer, Slide } from 'react-toastify';
import { ReactQueryDevtools } from 'react-query/devtools';
import { Global } from '@emotion/react';
import { getQueryClient } from './lib/request';
import Router from './routes';
import 'react-toastify/dist/ReactToastify.css';
import { GlobalStyles } from './components/Styles/GlobalStyles';

function App() {
  const [isError, setIsError] = useState(false);
  const [isExit, setIsExit] = useState(false);
  return (
    <BrowserRouter>
      <QueryClientProvider client={getQueryClient(setIsError)}>
        <Global styles={GlobalStyles} />
        <ReactQueryDevtools initialIsOpen={false} />
        <Router isError={isError} isExit={isExit} setIsExit={setIsExit} />
      </QueryClientProvider>
      <ToastContainer transition={Slide} closeButton={false} limit={1} />
    </BrowserRouter>
  );
}

export default App;
