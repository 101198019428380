import { ReactNode } from 'react';
import { ModalContainer, Modal } from './BottomSheet.styles';

export interface IBottomSheet {
  visible: boolean;
  close: () => void;
  children: ReactNode;
}

function BottomSheet({ visible, close, children }: IBottomSheet) {
  return (
    <ModalContainer>
      <Modal
        height="auto"
        placement="bottom"
        closable={false}
        onClose={() => close()}
        visible={visible}
      >
        {children}
      </Modal>
    </ModalContainer>
  );
}

export default BottomSheet;
