import styled from '@emotion/styled';
import { colors } from '../Styles/theme';

interface IDot {
  active: boolean;
}
export const ProgressContainer = styled.div<IDot>`
  display: flex;
  width: 100%;
  &::after {
    content: '';
    width: 0.8rem;
    height: 0.8rem;
    border-radius: 25px;
    background-color: ${(props) =>
      props.active ? colors.primary_03_basic : colors.white_01_basic};
    border: ${(props) =>
      props.active
        ? `2px solid ${colors.primary_03_basic}`
        : `2px solid ${colors.grey_02_line}`};
  }
`;
export const DotContainer = styled.div<IDot>`
  display: flex;
  flex: 1;
  div.dot-container {
    display: flex;
    align-items: center;
    width: 100%;
  }
  &:last-child {
    display: none;
    border: 1px solid black;
  }
`;
export const Line = styled.div<IDot>`
  width: 100%;
  height: 0.2rem;
  margin: 0 0.4rem;
  background-color: ${(props) =>
    props.active ? colors.primary_03_basic : colors.white_01_basic};
  border: ${(props) =>
    props.active
      ? `2px solid ${colors.primary_03_basic}`
      : `2px solid ${colors.grey_02_line}`};
`;
export const Dot = styled.div<IDot>`
  width: 0.8rem;
  height: 0.8rem;
  background-color: ${(props) =>
    props.active ? colors.primary_03_basic : colors.white_01_basic};
  border: ${(props) =>
    props.active
      ? `2px solid ${colors.primary_03_basic}`
      : `2px solid ${colors.grey_02_line}`};
  border-radius: 25px;
`;
