import {
  ContentContainer,
  ContentInfo,
  Highlight,
  ReadCircle,
} from './PopularContent.styles';
import highlightIcon from '../../../assets/img/icon-popular.svg';
import { IPopularMission } from '../../../services/useHealthInfo';

function PopularContent(props: IPopularMission) {
  const { no, mission_name: missionName, is_read: isRead } = props;
  return (
    <ContentContainer>
      <Highlight src={highlightIcon} alt="highlightIcon" />
      <ContentInfo isRead={isRead === true}>
        <div>{no}</div>
        <div>{missionName}</div>
      </ContentInfo>
      {isRead ? null : <ReadCircle />}
    </ContentContainer>
  );
}

export default PopularContent;
