import { useSelector } from 'react-redux';
import { RootState } from '../../../app/store';
import {
  ContentContainer,
  ContentInfo,
  ReadCircle,
} from './WeekContent.styles';

interface IWeekContent {
  id: number;
  week: number;
  missionName: string;
  isRead: boolean;
}

function WeekContent({ id, week, missionName, isRead }: IWeekContent) {
  const displayWeek = useSelector(
    (state: RootState) => state.healthInfo.swipeWeek,
  );
  const isLock = displayWeek > week;
  return (
    <ContentContainer isLock={isLock}>
      <ContentInfo isRead={isRead} isLock={isLock}>
        <h3>{id + 1}</h3>
        <span>{missionName}</span>
      </ContentInfo>
      {!isLock && !isRead && <ReadCircle />}
    </ContentContainer>
  );
}

export default WeekContent;
