import styled from '@emotion/styled';
import { colors } from '../../components/Styles/theme';

export const Container = styled.article`
  padding-bottom: 1rem;

  & > :nth-child(2) > div {
    margin-top: 1rem;
  }
`;

export const OcareButton = styled.button`
  width: 100%;
  text-align: left;
`;

export const ItemWrap = styled.div`
  border-radius: 1.6rem;
  box-shadow: 0 0.2rem 1.2rem 0 rgba(0, 0, 0, 0.15);
  background-color: ${colors.white_01_basic};
  padding: 2.2rem;
  margin: 0 1rem 0.8rem;
`;

export const Item = styled.dl`
  display: flex;
  justify-content: space-between;
  align-items: center;

  dt {
    font-size: 1.8rem;
    font-weight: bold;
  }
  dd {
    display: flex;
    align-items: center;
    color: ${colors.grey_05_subtext};
    font-size: 1.2rem;

    span {
      display: inline-block;
      vertical-align: text-bottom;
    }
    .time {
      font-size: 1rem;
      color: ${colors.grey_05_subtext};
    }
    .val {
      min-width: 15rem;
      margin-left: 0.6rem;
      text-align: right;

      strong {
        display: inline-block;
        font-size: 1.8rem;
        font-weight: bold;
        color: ${colors.black_01_basic};
      }
    }
  }
`;

export const Empty = styled.p`
  font-size: 1.8rem;
  font-weight: bold;
`;

export const BottomSheetItem = styled.div`
  width: 100%;
  height: 5.6rem;
  line-height: 5.6rem;
  text-align: center;
  color: ${colors.black_01_basic};
  font-size: 1.6rem;
  background-color: ${colors.white_01_basic};

  &:hover {
    background-color: ${colors.grey_01_bg};
  }
`;
