import { createSlice } from '@reduxjs/toolkit';

type isBackButton = {
  isBackBtn: boolean;
  reconnectionModal: boolean;
};

const initialState: isBackButton = {
  isBackBtn: false,
  reconnectionModal: false,
};

const checkBackButtonSlice = createSlice({
  name: 'checkBackButton',
  initialState,
  reducers: {
    set: (state, action) => {
      state.isBackBtn = action.payload;
    },
    on: (state) => {
      state.isBackBtn = true;
      state.reconnectionModal = true;
    },
    isBackBtnOff: (state) => {
      state.isBackBtn = false;
    },
    reconnectionModalOn: (state) => {
      state.reconnectionModal = true;
    },
  },
});

export const { set, on, isBackBtnOff, reconnectionModalOn } =
  checkBackButtonSlice.actions;
export default checkBackButtonSlice.reducer;
