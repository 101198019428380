import { useCallback, Dispatch, SetStateAction, useState } from 'react';
import './DatePicker.css';
import DatePickerColumn from './DatePickerColumn';
import { IDate } from './lib/types';

interface IDatePicker {
  date: IDate;
  setDate: Dispatch<SetStateAction<IDate>>;
  startDate: Date;
  endDate: Date;
}

function DatePicker({ date, setDate, startDate, endDate }: IDatePicker) {
  const [maxYear] = useState<number>(endDate.getFullYear());
  const [maxMonth, setMaxMonth] = useState<number>(
    date.year === endDate.getFullYear() ? endDate.getMonth() + 1 : 12,
  );
  const [maxDay, setMaxDay] = useState<number>(
    date.year === endDate.getFullYear() && date.month === endDate.getMonth() + 1
      ? endDate.getDate()
      : new Date(date.year, date.month, 0).getDate(),
  );

  const [minYear] = useState<number>(startDate.getFullYear());
  const [minMonth, setMinMonth] = useState<number>(
    date.year === startDate.getFullYear() ? startDate.getMonth() + 1 : 1,
  );
  const [minDay, setMinDay] = useState<number>(
    date.year === startDate.getFullYear() &&
      date.month === startDate.getMonth() + 1
      ? startDate.getDate()
      : 1,
  );

  const [dayReset, setDayReset] = useState<boolean>(false);
  const [dayResetNum, setDayResetNum] = useState<number>(0);

  const [monthReset, setMonthReset] = useState<boolean>(false);
  const [monthResetNum, setMonthResetNum] = useState<number>(0);

  const onChangeYear = useCallback(
    (year: number) => {
      if (date.year === year) return;
      setDate((prevDate) => ({
        ...prevDate,
        year,
      }));

      const newMaxDay =
        year === endDate.getFullYear() && date.month === endDate.getMonth() + 1
          ? endDate.getDate()
          : new Date(year, date.month, 0).getDate();

      if (date.day > newMaxDay) {
        setDayReset(true);
        setDayResetNum(newMaxDay - minDay + 1);
      } else {
        setDayReset(true);
        setDayResetNum(date.day);
      }
      setMaxDay(newMaxDay);

      if (
        year === startDate.getFullYear() &&
        date.month === startDate.getMonth() + 1
      ) {
        const newDay = startDate.getDate();
        setMinDay(newDay);
        setDayReset(true);
        setDayResetNum(date.day > newDay ? date.day : newDay);
      } else {
        setMinDay(1);
      }

      let isReset = false;

      if (year === startDate.getFullYear()) {
        setMinMonth(startDate.getMonth() + 1);
        if (date.month < startDate.getMonth() + 1) {
          setMonthResetNum(startDate.getMonth() + 1);
          setMonthReset(true);
        } else {
          setMonthResetNum(date.month);
          setMonthReset(true);
        }
        isReset = true;
      } else {
        setMinMonth(1);
        setMonthResetNum(date.month);
        setMonthReset(true);
      }

      if (year === endDate.getFullYear()) {
        setMaxMonth(endDate.getMonth() + 1);
        if (!isReset) {
          setMonthReset(true);
          const newMonth = endDate.getMonth() + 1;
          setMonthResetNum(date.month > newMonth ? newMonth : date.month);
        }
      } else {
        setMaxMonth(12);
        if (!isReset) {
          setMonthResetNum(date.month);
          setMonthReset(true);
        }
      }
    },
    [date.year, date.month, date.day, setDate, endDate, startDate, minDay],
  );
  const onChangeMonth = useCallback(
    (month: number) => {
      if (date.month === month) return;

      setDate((prevDate) => ({
        ...prevDate,
        month,
      }));

      const newMaxDay =
        date.year === endDate.getFullYear() && month === endDate.getMonth() + 1
          ? endDate.getDate()
          : new Date(date.year, month, 0).getDate();

      setMaxDay(newMaxDay);

      if (
        date.year === startDate.getFullYear() &&
        month === startDate.getMonth() + 1
      ) {
        setMinDay(startDate.getDate());
        setDayReset(true);
        setDayResetNum(
          date.day < startDate.getDate() ? startDate.getDate() : date.day,
        );
      } else {
        setMinDay(1);
        setDayReset(true);
        setDayResetNum(date.day);
      }

      if (date.day > newMaxDay) {
        // ex) 현재 31일 선택되었는데 바뀐 달이 최대 30일 까지 있을때
        setDayReset(true);
        setDayResetNum(newMaxDay - minDay + 1);
      }
    },
    [date, endDate, minDay, setDate, startDate],
  );
  const onChangeDay = useCallback(
    (day: number) => {
      if (date.day === day) return;
      setDate((prevDate) => ({
        ...prevDate,
        day,
      }));
    },
    [setDate, date],
  );

  return (
    <div className="rdp-picker">
      <DatePickerColumn
        onChange={onChangeYear}
        minValue={minYear}
        maxValue={maxYear}
        value={date.year}
      />
      <DatePickerColumn
        onChange={onChangeMonth}
        minValue={minMonth}
        maxValue={maxMonth}
        value={date.month}
        reset={monthReset}
        setReset={setMonthReset}
        resetNum={monthResetNum}
      />
      <DatePickerColumn
        onChange={onChangeDay}
        minValue={minDay}
        maxValue={maxDay}
        value={date.day}
        reset={dayReset}
        setReset={setDayReset}
        resetNum={dayResetNum}
      />
    </div>
  );
}

export default DatePicker;
