import { Dispatch, ReactNode, SetStateAction } from 'react';
import { Routes, Route } from 'react-router-dom';
import Layout from './layouts';
import {
  Home,
  HealthInfo,
  Etc,
  HealthManager,
  AdviceMsgsDetail,
  Target,
  ReportList,
  Report,
  QnaWrite,
  Vessel,
  Qeustionnaires,
  End,
} from './features';
import NotFound from './components/NotFound';
import Guide from './components/Styles/Guide';
import LifeLog from './features/lifeLog';
import ContentDetail from './features/healthInfo/ContentDetail';
import CheckQuestionnaires from './components/CheckQuestionnaires/CheckQuestionnaires';
import useHistoryRoutes from './historyRoutes';
import ErrorModal from './components/Modal/ErrorModal';
import ExitModal from './components/Modal/ExitModal';

export interface IrouteList {
  key: string;
  path: string;
  element: ReactNode;
  label: string;
}

export const routeList: IrouteList[] = [
  {
    key: 'step',
    path: '/questionnaires/step',
    element: <Qeustionnaires />,
    label: '',
  },
  {
    key: 'end',
    path: '/questionnaires/end',
    element: <End />,
    label: '',
  },
  {
    key: 'home',
    path: '/',
    element: <Home />,
    label: '홈',
  },
  {
    key: 'logs',
    path: '/main/logs/:categoryNumber',
    element: <LifeLog />,
    label: '라이프로그',
  },
  {
    key: 'advice',
    path: '/msgs',
    element: <HealthManager />,
    label: '상담메시지',
  },
  {
    key: 'adviceDetail',
    path: '/msgs/advice/detail/:id',
    element: <AdviceMsgsDetail />,
    label: '상담메시지',
  },
  {
    key: 'qna',
    path: '/qna',
    element: <HealthManager />,
    label: '질문&답변',
  },
  {
    key: 'qna',
    path: '/qna/write',
    element: <QnaWrite />,
    label: '질문하기',
  },
  {
    key: 'contents',
    path: '/contents',
    element: <HealthInfo />,
    label: '건강정보',
  },
  {
    key: 'contents',
    path: '/contents/detail/:weekid/:contentid',
    element: <ContentDetail />,
    label: '건강정보',
  },
  {
    key: 'etc',
    path: '/etc',
    element: <Etc />,
    label: '더보기',
  },
  {
    key: 'target',
    path: '/etc/target',
    element: <Target />,
    label: '자가기록 관리목표',
  },
  {
    key: 'cvd',
    path: '/etc/cvd',
    element: <Vessel />,
    label: '심뇌혈관 위험도 평가 결과',
  },
  {
    key: 'report',
    path: '/etc/report',
    element: <ReportList />,
    label: '리포트',
  },
  {
    key: 'report',
    path: '/etc/calendar/:category/:time',
    element: <Report />,
    label: '리포트',
  },
  {
    key: 'notFound',
    path: '/*',
    element: <NotFound />,
    label: 'NotFound',
  },
  {
    key: 'guide',
    path: '/style/guide',
    element: <Guide />,
    label: '스타일가이드',
  },
];

interface IRouter {
  isError: boolean;
  isExit: boolean;
  setIsExit: Dispatch<SetStateAction<boolean>>;
}

function Router({ isError, isExit, setIsExit }: IRouter) {
  useHistoryRoutes(setIsExit);

  if (isError) return <ErrorModal />;
  if (isExit) return <ExitModal setIsExit={setIsExit} />;

  return (
    <Routes>
      <Route element={<Layout />}>
        {routeList.map((route: IrouteList) => (
          <Route
            {...route}
            element={<CheckQuestionnaires>{route.element}</CheckQuestionnaires>}
          />
        ))}
      </Route>
    </Routes>
  );
}

export default Router;
