import { colors } from '../../components/Styles/theme';

function Icon({ color = colors.black_01_basic }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
    >
      <path fill="none" stroke={color} d="M12.667 6L8 10 3.333 6" />
    </svg>
  );
}

export default Icon;
