import styled from '@emotion/styled';
import { colors } from '../../../components/Styles/theme';

export const Container = styled.div`
  width: 100%;
  height: 12rem;
  padding: 2rem;
  display: flex;
  justify-content: space-around;
  align-items: center;
`;

export const Img = styled.div`
  & > img {
    width: 10rem;
    height: 10rem;
  }
`;

export const TextContainer = styled.div`
  font-size: 1.3rem;
  color: ${colors.black_01_basic};
  display: flex;
  flex-direction: column;

  span {
    display: inline-block;
  }

  span: nth-of-type(3) {
    color: ${colors.primary_03_basic};
  }

  @media (max-width: 330px) {
    span {
      font-size: 1.1rem;
    }
  }
`;
