import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import WeekChart from './WeekChart';
import Messages from './Messages';
import Missions from './Missions';
import Reports from './Reports';
import TodayLogs from './TodayLogs';
import { useGetHome } from '../../services/useHome';
import { setData } from './homeSlice';
import { Container } from './index.styles';

function Home() {
  const dispatch = useDispatch();
  const { data } = useGetHome();

  useEffect(() => {
    if (!data) return;
    dispatch(setData({ data }));
  }, [dispatch, data]);

  return (
    <Container>
      <WeekChart />
      <Messages />
      <Missions />
      <Reports />
      <TodayLogs />
    </Container>
  );
}

export default Home;
