import styled from '@emotion/styled';
import { colors } from '../../../components/Styles/theme';

export const Factors = styled.div`
  margin: 2rem 0;
  font-size: 1.6rem;
  color: ${colors.black_01_basic};
  text-align: center;
  span {
    font-weight: bold;
    color: ${colors.sub_red_03_basic};
  }
`;
