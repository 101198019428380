import { useQuery } from 'react-query';
import { get } from '../lib/request';

export const useGetHome = () => {
  return useQuery(['home'], () => get(`/home`), {
    staleTime: Infinity,
    cacheTime: Infinity,
    keepPreviousData: true,
  });
};
