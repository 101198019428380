import parse from 'html-react-parser';
import { getFormattedDate, parseToDate } from 'src/lib/utils';
import { IQna } from '../../../services/useMsgs';
import { AdminInfo } from '../HealthManager.styles';
import {
  ImageContainer,
  QnAAnswer,
  QnADate,
  QnAItemContainer,
} from './QnAItem.style';

export default function QnAItem({
  answer,
  answer_dttm: answerDttm,
  admin_name: adminName,
  images,
}: IQna) {
  return (
    <QnAItemContainer>
      <AdminInfo>
        {adminName !== null ? adminName : 'Default Name'}
        <QnADate>
          {getFormattedDate(parseToDate(answerDttm), 'MM월 dd일, a hh:mm')}
        </QnADate>
      </AdminInfo>
      <QnAAnswer>{parse(answer)}</QnAAnswer>
      {images && (
        <ImageContainer>
          {images.map((img) => {
            return <img key={img.url} src={img.url} alt="" />;
          })}
        </ImageContainer>
      )}
    </QnAItemContainer>
  );
}
