import { Container, Explain, Img } from './WeekContentEmpty.styles';
import errorImg from '../../../assets/img/image-contents-error.svg';

function WeekContentEmpty() {
  return (
    <Container>
      <Img>
        <img src={errorImg} alt="이미지" />
      </Img>
      <Explain>잠시 후에 다시 시도해주세요.</Explain>
    </Container>
  );
}

export default WeekContentEmpty;
