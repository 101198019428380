import styled from '@emotion/styled';
import { colors } from '../../../components/Styles/theme';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: calc(10vh);
`;

// 추후 이미지로 변경 필요
export const Img = styled.div`
  width: 10rem;
  height: 10rem;
  margin-bottom: 1.1rem;
`;

export const Explain = styled.span`
  font-size: 1.4rem;
  text-align: center;
  color: ${colors.grey_05_subtext};
`;
