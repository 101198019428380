import { Dispatch, SetStateAction } from 'react';
import CategoryItem from './CategoryItem';
import { ListWrapper } from './index.styles';

interface ICategoryList {
  category: string;
  categoryArray: string[];
  setCategory: Dispatch<SetStateAction<string>>;
}

function CategoryList({ category, categoryArray, setCategory }: ICategoryList) {
  return (
    <ListWrapper>
      {categoryArray.map((item) => (
        <CategoryItem
          key={item}
          name={item}
          active={category === item}
          setCategory={setCategory}
        />
      ))}
    </ListWrapper>
  );
}

export default CategoryList;
