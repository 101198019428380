import { ReactNode, CSSProperties } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { colors } from '../Styles/theme';

interface TagType {
  children: ReactNode;
  type?:
    | 'basic1'
    | 'basic2'
    | 'basic3'
    | 'basic4'
    | 'basic5'
    | 'basic6'
    | 'basic7'
    | 'basic8';
  icon?: ReactNode;
  style?: CSSProperties;
}

function Tag(props: TagType) {
  const { children, type = 'basic1', icon, style } = props;

  const types = {
    basic1: css`
      color: ${colors.white_01_basic};
      background-color: ${colors.black_01_basic};
      border-color: ${colors.black_01_basic};
    `,
    basic2: css`
      color: ${colors.black_01_basic};
      background-color: ${colors.primary_01_highlight};
      border-color: ${colors.primary_01_highlight};
    `,
    basic3: css`
      color: ${colors.black_01_basic};
      background-color: ${colors.primary_03_basic};
      border-color: ${colors.primary_03_basic};
    `,
    basic4: css`
      color: ${colors.black_01_basic};
      background-color: ${colors.grey_01_bg};
      border-color: ${colors.grey_01_bg};
    `,
    basic5: css`
      color: ${colors.black_01_basic};
      border-color: ${colors.black_01_basic};
    `,
    basic6: css`
      color: ${colors.grey_03_icon};
      background-color: ${colors.grey_05_subtext};
      border-color: ${colors.grey_02_line};
    `,
    basic7: css`
      color: ${colors.grey_05_subtext};
      background-color: ${colors.white_01_basic};
      border-color: ${colors.grey_04_inactive};
    `,
    basic8: css`
      color: ${colors.grey_03_icon};
      border-color: ${colors.grey_02_line};
    `,
    basic9: css`
      color: ${colors.grey_02_line};
      border-color: ${colors.grey_02_line};
    `,
  };

  const SpanStyle = styled.span`
    display: inline-block;
    border-radius: 1.2rem;
    border-style: solid;
    border-width: 0.1rem;
    font-size: 1rem;
    font-weight: bold;
    padding: 0.2rem 0.8rem;
    margin: 0 0.2rem;

    ${types[type]}
  `;

  return (
    <SpanStyle style={style}>
      {children}
      <span>{icon && icon}</span>
    </SpanStyle>
  );
}

export default Tag;
