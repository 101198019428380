import styled from '@emotion/styled';

export const DateInfo = styled.span`
  font-size: 1.6rem;
  margin: 0.1rem 2rem;
  width: 10.1rem;
  text-align: center;
  font-weight: bold;
  line-height: 1.38;
`;

export const DateWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 0.1rem 0 2rem;
`;
