import { useEffect, useState } from 'react';
import { numberWithCommas } from 'src/lib/utils';
import { useGetStatistics } from '../../../services/useStatistics';
import { categoryObj } from '../calendar/lib/utils';
import { IStatistics } from '../lib/types';
import {
  FlexContainer,
  InputCount,
  Span,
  StatisticsContainer,
  StatisticsData,
  StatisticsLabel,
} from './index.styles';
import PrevInput from './PrevInput';
import RangeWeekInfo from './RangeWeekInfo';

function Statistics({ category, week }: IStatistics) {
  const { data: statisticData } = useGetStatistics({ category, week });
  const [values, setValues] = useState(['', '']);
  useEffect(() => {
    if (categoryObj[category].inputType === 'gap') {
      setValues([
        statisticData?.[categoryObj[category].left as string],
        statisticData?.[categoryObj[category].right as string],
      ]);
    } else {
      setValues([
        statisticData?.[categoryObj[category].current as string],
        statisticData?.[categoryObj[category].goal as string],
      ]);
    }
  }, [statisticData, category]);
  return (
    <StatisticsContainer>
      <RangeWeekInfo week={week} />
      <StatisticsLabel>{categoryObj[category].statisticLabel}</StatisticsLabel>
      <FlexContainer>
        {categoryObj[category].inputType === 'gap' ? (
          <>
            <StatisticsData>
              {values[0] ? numberWithCommas(values[0]) : '-'}/
              {values[1] ? numberWithCommas(values[1]) : '-'}
            </StatisticsData>
            <Span>{categoryObj[category].unit}</Span>
          </>
        ) : (
          <>
            <StatisticsData>
              {values[0]
                ? numberWithCommas(values[0], category !== '걸음')
                : '-'}
            </StatisticsData>
            <Span>
              /
              {values[1]
                ? numberWithCommas(values[1], category !== '걸음')
                : '-'}
              {categoryObj[category].unit}
            </Span>
          </>
        )}
      </FlexContainer>
      {category !== '걸음' ? (
        <InputCount>
          <Span bold>{categoryObj[category].inputLabel}</Span>
          <Span>{statisticData?.cnt}</Span>
          <PrevInput cnt={statisticData?.cntr_cnt} empty={week.label <= 0} />
        </InputCount>
      ) : null}
    </StatisticsContainer>
  );
}

export default Statistics;
