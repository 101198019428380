import { useNavigate } from 'react-router';
import { useQueryString } from '../../hooks';
import { convertSectionText } from './lib/constants';
import { Button } from '../../components/Button';
import surveyDone from '../../assets/img/image-survey-done.svg';
import {
  EndContainer,
  CompleteSection,
  NextSection,
  EndButtonContainer,
} from './Questionnaires.styles';

export default function End() {
  const navigate = useNavigate();
  const query = useQueryString();
  const { step } = query;
  const handleClick = () => {
    navigate('/questionnaires/step');
  };
  return (
    <EndContainer>
      <div className="description">
        <div>
          <img src={surveyDone} alt="" />
          <div className="complete">
            <CompleteSection>
              {convertSectionText(Number(step))}
            </CompleteSection>
            평가설문이 끝났습니다.
          </div>
          <NextSection>
            {convertSectionText(Number(step) + 1)} 설문을 준비해주세요.
          </NextSection>
        </div>
      </div>
      <EndButtonContainer>
        <Button type="arrowRight" onClick={handleClick}>
          다음
        </Button>
      </EndButtonContainer>
    </EndContainer>
  );
}
