import { Dispatch, SetStateAction } from 'react';
import { BottomSheet } from '../Modal';
import useBottomSheet from '../../hooks/useBottomSheet';
import { ReactComponent as DownWhiteArror } from '../../assets/img/icon-down-white.svg';
import { Container, FilterButton, List } from './index.styles';

interface IFilter {
  filter: string;
  filterList: string[];
  setFilter: Dispatch<SetStateAction<string>>;
  margin: string;
}
export default function Filter({
  filter,
  filterList,
  setFilter,
  margin,
}: IFilter) {
  const { visible, open, close } = useBottomSheet();
  const handleClick = (item: string) => {
    setFilter(item);
    close();
  };

  return (
    <>
      <Container margin={margin}>
        <FilterButton type="button" onClick={() => open()}>
          {filter}
          <DownWhiteArror />
        </FilterButton>
      </Container>

      <BottomSheet visible={visible} close={close}>
        {filterList.map((item) => {
          return (
            <List key={item} onClick={() => handleClick(item)}>
              {item}
            </List>
          );
        })}
      </BottomSheet>
    </>
  );
}
