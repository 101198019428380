import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate } from 'react-router';
import { RootState } from 'src/app/store';
import { callFinish } from 'src/lib/utils';
import { useGetLogin } from '../../services/useLogin';
import CheckOcareWeightModal from './CheckOcareWeightModal';
import Intro from './Intro';
import ReconnectionModal from './ReconnectionModal';
import Step from './Step';

export default function Qeustionnaires() {
  const dispatch = useDispatch();
  const { isBackBtn, reconnectionModal } = useSelector(
    (state: RootState) => state.checkBackButton,
  );
  const { backCurrIndex } = useSelector((state: RootState) => state.surveyInfo);
  const { data: userData, isError } = useGetLogin();
  const [buttonStatus, setButtonStatus] = useState(!!backCurrIndex);
  const [reconnection, setReconnection] = useState(false);
  const isOcareWeightNull = userData?.user.weight === 0;

  useEffect(() => {
    if (
      !isBackBtn &&
      !reconnectionModal &&
      userData !== undefined &&
      userData?.next_questionnaire_id > 1
    ) {
      setReconnection(true);
    }
  }, [userData, isBackBtn, reconnectionModal, dispatch]);

  if (userData === undefined || isError) return <Navigate to="/error" />;

  const nextQuestionnaireId = userData.next_questionnaire_id;
  const questionnaireCnt = userData.questionnaire_cnt;
  const questionCnt = userData.question_cnt;
  const { name, birthday } = userData.user;
  const firstSetExist = nextQuestionnaireId === 1;

  const leftButtonHandler = () => {
    callFinish();
  };
  const rightButtonHandler = () => {
    setReconnection(false);
  };

  return (
    <div>
      {isOcareWeightNull && <CheckOcareWeightModal />}
      <ReconnectionModal
        isOpen={reconnection}
        leftButtonHandler={leftButtonHandler}
        rightButtonHandler={rightButtonHandler}
      />
      {firstSetExist && !buttonStatus ? (
        <Intro
          name={name}
          questionnaireCnt={questionnaireCnt}
          questionCnt={questionCnt}
          setButtonStatus={setButtonStatus}
        />
      ) : (
        <Step questionnairesId={nextQuestionnaireId} birthday={birthday} />
      )}
    </div>
  );
}
