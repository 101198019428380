import { addDays, isSameDay, isBefore } from 'date-fns';
import {
  getFormattedDate,
  convertToDate,
  categoryType,
} from '../../../lib/utils';

interface ICurrentWeek {
  label: number;
  startDate: Date;
  endDate: Date;
}
export function setRange(currentWeek: ICurrentWeek, logs: any): any {
  const { startDate, endDate } = currentWeek;
  let current = startDate;
  const range = [];

  while (isBefore(current, endDate) || isSameDay(current, endDate)) {
    const currentDate = current;

    const sameDays = logs.filter((log: any) => {
      return isSameDay(convertToDate(log.log_dttm), currentDate);
    });

    if (sameDays.length > 0) {
      range.push(sameDays);
    } else {
      range.push({
        log_dttm: getFormattedDate(current),
      });
    }

    current = addDays(current, 1);
  }

  return range;
}

// TODO: any
export function setRangeGraph(categoryNumber: string, logs: any) {
  return logs.map((log: any) => {
    if (Array.isArray(log)) {
      // blood - 최근값 (날짜시간순 정렬)
      if (categoryNumber === categoryType.blood) {
        return {
          ...log[0],
        };
      }

      // sugar - 평균값
      // 0알수없음, 1공복(공복), 2아침식사 전(식전), 3식후(식후)
      let emptyCount = 0;
      let beforeCount = 0;
      let afterCount = 0;
      if (categoryNumber === categoryType.sugar) {
        const emptyBs = log.reduce((acc, curr) => {
          if (curr.period === 1) emptyCount += 1;
          return curr.period === 1 ? acc + curr.bs : acc;
        }, 0);
        const beforeBs = log.reduce((acc, curr) => {
          if (curr.period === 2) beforeCount += 1;
          return curr.period === 2 ? acc + curr.bs : acc;
        }, 0);
        const afterBs = log.reduce((acc, curr) => {
          if (curr.period === 3) afterCount += 1;
          return curr.period === 3 ? acc + curr.bs : acc;
        }, 0);

        const beforeEmptyBs =
          emptyBs + beforeBs === 0
            ? '-'
            : Math.round((emptyBs + beforeBs) / (emptyCount + beforeCount));

        return {
          ...log[0],
          beforeEmptyBs,
          emptyBs: emptyCount === 0 ? '-' : Math.round(emptyBs / emptyCount),
          beforeBs:
            beforeCount === 0 ? '-' : Math.round(beforeBs / beforeCount),
          afterBs: afterCount === 0 ? '-' : Math.round(afterBs / afterCount),
        };
      }

      // meal - 합산값
      if (categoryNumber === categoryType.meal) {
        const totalCalories = log.reduce((acc, curr) => {
          return acc + curr.total_calories;
        }, 0);

        return {
          ...log[0],
          total_calories: totalCalories,
        };
      }

      // weight - 최근값 (날짜시간순 정렬)
      if (categoryNumber === categoryType.weight) {
        return {
          ...log[0],
        };
      }

      // step - 합산값
      if (categoryNumber === categoryType.step) {
        const stepCount = log.reduce((acc, curr) => {
          return acc + curr.step_count;
        }, 0);

        return {
          ...log[0],
          step_count: stepCount,
        };
      }

      // exercise - 합산값
      if (categoryNumber === categoryType.exercise) {
        const caloriesOut = log.reduce((acc, curr) => {
          return acc + curr.calories_out;
        }, 0);
        const duration = log.reduce((acc, curr) => {
          return acc + curr.duration;
        }, 0);

        return {
          ...log[0],
          calories_out: caloriesOut,
          duration,
        };
      }
    }
    return log;
  });
}

// TODO: type
export function getLastIndex(data: any) {
  const index = data
    .slice()
    .reverse()
    .findIndex((log: any) => Object.prototype.hasOwnProperty.call(log, 'id'));
  return index >= 0 ? data.length - 1 - index : index;
}
