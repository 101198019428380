import { ReactElement, cloneElement } from 'react';
import { Link } from 'react-router-dom';
import { usePathParams } from '../hooks';
import { iconColors } from '../components/Styles/theme';
import { callFinish } from '../lib/utils';

interface IMenu {
  link: string | null;
  label: string;
  icon: ReactElement;
}

function Menu({ link, label, icon }: IMenu) {
  const pathParam = usePathParams();
  let isActive = link === pathParam;
  if (link === '/msgs' && pathParam === '/qna') isActive = true;
  return link !== null ? (
    <Link to={link} className={isActive ? 'active' : ''}>
      {cloneElement(icon, {
        color: isActive
          ? `${iconColors.tab_active}`
          : `${iconColors.tab_inactive}`,
      })}
      <span>{label}</span>
    </Link>
  ) : (
    <button type="button" onClick={() => callFinish()}>
      {cloneElement(icon)}
      <span className="ocare">{label}</span>
    </button>
  );
}

export default Menu;
