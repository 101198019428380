interface IHeart {
  color: string;
  size?: string;
}

function Icon({ color, size = '16' }: IHeart) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={size}
      height={size}
      viewBox="0 0 16 17"
    >
      <defs>
        <path id="ak75glwhea" d="M0 0h14v13H0z" />
      </defs>
      <g fill="none" fillRule="evenodd">
        <mask id="df5j2p7p2b" fill="#fff">
          <use xlinkHref="#ak75glwhea" />
        </mask>
        <path
          fill={color}
          d="M14 3.912C14 1.752 12.335 0 10.28 0 8.86 0 7.627.837 7 2.068 6.374.838 5.14 0 3.72 0 1.664 0 0 1.751 0 3.912 0 8.524 6.97 12.98 7 13c.03-.02 7-4.476 7-9.088"
          mask="url(#df5j2p7p2b)"
        />
      </g>
    </svg>
  );
}

export default Icon;
