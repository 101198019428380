import { ReactNode, useState, useRef, useEffect } from 'react';
import { Container, Fixed } from './FixedBottom.styles';

interface IFixedBottom {
  children: ReactNode;
  fixedNode: ReactNode;
  isMain?: boolean;
}

function FixedBottom({ children, fixedNode, isMain = false }: IFixedBottom) {
  const [height, setHeight] = useState<number>();
  const observed = useRef<any>(null); // TODO: type

  useEffect(() => {
    const { current } = observed;
    setHeight(current.clientHeight / 10 + 2);
  }, [observed]);

  return (
    <Container height={height}>
      {children}
      <Fixed ref={observed} isMain={isMain}>
        {fixedNode}
      </Fixed>
    </Container>
  );
}

export default FixedBottom;
