import React, { Dispatch, SetStateAction } from 'react';
import { Link } from 'react-router-dom';
import { Container, Tab, Tabs } from '../healthInfo/index.styles';

interface ITabs {
  id: number;
  name: string;
  pathname: string;
}
interface ITabsContainer {
  tabList: ITabs[];
  tab: string;
  setTab: Dispatch<SetStateAction<string>>;
}
export default function TabsContainer({
  tabList,
  tab,
  setTab,
}: ITabsContainer) {
  const tabHandler = (e: React.MouseEvent<HTMLButtonElement>) => {
    const clickTabName: string = e.currentTarget.name;
    setTab(clickTabName);
  };
  return (
    <Container>
      <Tabs>
        {tabList.map((item) => {
          const { id, name, pathname } = item;
          return (
            <Tab
              key={id}
              onClick={tabHandler}
              name={pathname}
              isTab={tab === pathname}
            >
              <Link to={pathname}>{name}</Link>
            </Tab>
          );
        })}
      </Tabs>
    </Container>
  );
}
