import { SubjectUnit } from './types';

// # 기초설문 섹션 정보
const sectionsArr = [
  {
    name: '심뇌혈관 위험도',
    id: 1,
  },
  {
    name: '건강관리',
    id: 2,
  },
  {
    name: '식습관 ',
    id: 3,
  },
  {
    name: '개선의지 및 식습관 목표 설정',
    id: 4,
  },
];

export const convertSection = (step: number) => {
  const filter = sectionsArr.filter((item) => {
    return item.id === step;
  });
  return filter[0].id;
};

export const convertSectionText = (step: number) => {
  const filter = sectionsArr.filter((item) => {
    return item.id === step;
  });
  return filter[0].name;
};

export const subjectInputLimit = (
  unit: SubjectUnit | null | undefined,
  birth: string,
) => {
  const userAge = new Date().getFullYear() - new Date(birth).getFullYear() + 1;
  const unitLimit = {
    분: 60 * 24 * 7,
    시간: 24,
    일: 7,
    년: userAge,
    잔: 1000,
    회: 7,
    개비: 1000,
  };
  return unit ? unitLimit[unit] : 1000;
};
