interface IIcon {
  color?: string;
}

function Icon({ color }: IIcon) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <g fill="none" fillRule="evenodd">
        <path fill="#FFF" d="M0 0h24v24H0z" />
        <g transform="translate(4 3)">
          <path
            fill={color}
            d="M10.043 18H2a2 2 0 01-2-2V2a2 2 0 012-2h12a2 2 0 012 2v10.078L10.043 18z"
          />
          <rect width="10" height="1" x="3" y="3" fill="#FFF" rx="0.5" />
          <rect width="10" height="1" x="3" y="6" fill="#FFF" rx="0.5" />
          <rect width="10" height="1" x="3" y="9" fill="#FFF" rx="0.5" />
          <path
            fill={color}
            stroke="#FFF"
            strokeLinejoin="round"
            d="M10.5 19.207V12.5h6.707L10.5 19.207z"
          />
        </g>
      </g>
    </svg>
  );
}

export default Icon;
