import { categoryType } from '../../lib/utils';

export const colors: { [key: string]: string } = {
  primary_01_highlight: '#fffae5',
  primary_02_light: '#ffed99',
  primary_03_basic: '#ffd300',
  primary_04_dark: '#b29300',
  sub_blue_01_highlight: '#ecf4fe',
  sub_blue_02_light: '#b5d6fe',
  sub_blue_03_basic: '#4698fc',
  sub_blue_04_dark: '#306aaf',
  sub_green_01_highlight: '#e9f9f3',
  sub_green_02_light: '#a8e7d2',
  sub_green_03_basic: '#25c38f',
  sub_green_04_dark: '#198863',
  sub_orange_01_highlight: '#fcf3ef',
  sub_orange_02_light: '#f3cfc3',
  sub_orange_03_basic: '#e18768',
  sub_orange_04_dark: '#9d5e48',
  sub_red_01_highlight: '#ffeff0',
  sub_red_02_light: '#ffc2c5',
  sub_red_03_basic: '#e14868',
  sub_red_04_dark: '#9d3248',
  sub_red_05_dark_2: '#5a1c29',
  grey_01_bg: '#f7f7f7',
  grey_03_icon: '#ccc',
  grey_02_line: '#e5e5e5',
  grey_04_inactive: '#999',
  grey_05_subtext: '#444',
  white_01_basic: '#fff',
  white_02_floating: 'rgba(255, 255, 255, 0.95)',
  white_03_pressed: 'rgba(255, 255, 255, 0.5)',
  black_01_basic: '#000',
  black_03_floating: 'rgba(0, 0, 0, 0.95)',
  black_02_pressed: 'rgba(0, 0, 0, 0.5)',
  category_1: '#ff9501',
  category_2: '#bd245a',
  contents_1: '#cfbd97',
  contents_2: '#96d198',
  contents_3: '#9c88b2',
  contents_4: '#a8bde7',
  contents_5: '#d6acd4',
};

export const iconColors = {
  tab_active: '#444',
  tab_inactive: '#ccc',
};

// 디자인 급간구간 순서 https://cdn.zeplin.io/624289751f5f1911a68aee9c/screens/9ECA80FA-70D6-4CB4-973F-9E8C95401C0F.png
export const classInterval: { [key: string]: { [key: string]: string } } = {
  [categoryType.blood]: {
    '1': colors.sub_orange_03_basic,
    '2': colors.sub_green_03_basic,
    '3': colors.sub_orange_03_basic,
    '4': colors.sub_red_03_basic,
    '5': colors.sub_red_03_basic,
    '6': colors.sub_red_03_basic,
    '9': 'transparent',
  },
  [categoryType.sugar]: {
    '1': colors.sub_red_03_basic,
    '2': colors.sub_green_03_basic,
    '3': colors.sub_orange_03_basic,
    '4': colors.sub_red_03_basic,
    '5': colors.grey_04_inactive,
    '9': 'transparent',
  },
  [categoryType.meal]: {
    '1': 'transparent', // none
    '2': colors.sub_green_03_basic, // 권장
    '3': colors.sub_red_03_basic, // 초과
    '9': 'transparent',
  },
  [categoryType.weight]: {
    '1': colors.sub_orange_03_basic,
    '2': colors.sub_green_03_basic,
    '3': colors.sub_orange_03_basic,
    '4': colors.sub_red_03_basic,
    '9': 'transparent',
  },
  [categoryType.step]: {
    '1': 'transparent', // none
    '2': 'transparent', // 미만
    '3': colors.sub_green_03_basic, // 초과/달설
    '9': 'transparent',
  },
  [categoryType.exercise]: {
    '1': 'transparent', // none
    '2': 'transparent', // 미만
    '3': colors.sub_green_03_basic, // 초과/달설
    '9': 'transparent',
  },
};

export const classPassFail: { [key: string]: string } = {
  '0': colors.sub_green_03_basic,
  '1': colors.sub_red_03_basic,
};
