import styled from '@emotion/styled';
import { colors } from '../components/Styles/theme';

export const FooterStyle = styled.footer`
  height: 6.6rem;
  display: flex;
  align-items: center;

  nav {
    width: 100%;
    display: flex;

    a,
    button {
      width: 100%;
      text-align: center;
      color: ${colors.grey_04_inactive};

      &.active {
        color: ${colors.grey_05_subtext};
      }

      span {
        display: block;

        &.ocare {
          margin-top: 0.4rem;
          color: #fbaf17;
        }
      }
    }
  }
`;
