import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import WeekContentPage from './weekContent/WeekContentPage';
import PopularContentPage from './popularContent/PopularContentPage';
import { Container, Tab, Tabs } from './index.styles';
import { changeTab } from './healthInfoSlice';
import { RootState } from '../../app/store';

const TabNames = {
  WEEK: 'week',
  POPULAR: 'popular',
};

function HealthInfo() {
  const tab = useSelector((state: RootState) => state.healthInfo.tab);

  const dispatch = useDispatch();

  const tabHandler = (e: React.MouseEvent<HTMLButtonElement>) => {
    const clickTabName: string = e.currentTarget.name;
    dispatch(changeTab(clickTabName));
  };

  return (
    <Container>
      <Tabs>
        <Tab
          onClick={tabHandler}
          name={TabNames.WEEK}
          isTab={tab === TabNames.WEEK}
        >
          주차별 콘텐츠
        </Tab>
        <Tab
          onClick={tabHandler}
          name={TabNames.POPULAR}
          isTab={tab === TabNames.POPULAR}
        >
          인기 콘텐츠
        </Tab>
      </Tabs>
      {tab === TabNames.WEEK ? <WeekContentPage /> : <PopularContentPage />}
    </Container>
  );
}

export default HealthInfo;
