import PopularContentHeader from './PopularContentHeader';
import PopularContentList from './PopularContentList';

function PopularContentPage() {
  return (
    <>
      <PopularContentHeader />
      <PopularContentList />
    </>
  );
}

export default PopularContentPage;
