import React, { useCallback, useEffect, useRef } from 'react';
import Slider from 'react-slick';
import { useDispatch, useSelector } from 'react-redux';
import { changeSwipeWeek } from '../healthInfoSlice';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { WeekText } from './SlickSlider.styles';
import { RootState } from '../../../app/store';

interface ISlider {
  week: number;
}

function SlickSlider({ week }: ISlider) {
  const weekArr = new Array(29).fill(0).map((item, idx) => idx - 2);
  const swipeRef = useRef<Slider>(null);
  const displayWeek = useSelector(
    (state: RootState) => state.healthInfo.swipeWeek,
  );
  const dispatch = useDispatch();

  const once = useCallback(() => {
    const firstIdx = displayWeek;
    swipeRef.current?.slickGoTo(firstIdx);
  }, [displayWeek]);

  useEffect(() => {
    once();
  }, [once]);

  const slideClickHandler = (e: React.MouseEvent<HTMLSpanElement>) => {
    const clickSlide = Number(e.currentTarget.innerText);
    swipeRef.current?.slickGoTo(clickSlide);
  };

  const settings = {
    arrows: false,
    slidesToShow: 5,
    swipeToSlide: true,
    infinite: false,
    beforeChange: (current: number, next: number) => {
      const nextIdx = next % 25;
      setTimeout(() => {
        dispatch(changeSwipeWeek(nextIdx));
      });
    },
  };

  return (
    <Slider ref={swipeRef} {...settings}>
      {weekArr.map((item) => (
        <WeekText
          onClick={slideClickHandler}
          key={item}
          isWeek={item > week}
          isCenter={displayWeek === item}
        >
          {item > -1 && item < 25 && item}
        </WeekText>
      ))}
    </Slider>
  );
}

export default SlickSlider;
