import { createSlice } from '@reduxjs/toolkit';
import { IResponse } from './lib/types';

type surveyInfo = {
  backResponses: IResponse[];
  backCurrIndex: number;
  backIsSubQstn: boolean;
  backChoiceNum: number;
  backSubQstnIndex: number;
  questionnairesId: number;
};

const initialState: surveyInfo = {
  backResponses: [],
  backCurrIndex: 0,
  backIsSubQstn: false,
  backChoiceNum: -1,
  backSubQstnIndex: -1,
  questionnairesId: 0,
};

const surveyInfoSlice = createSlice({
  name: 'checkBackButton',
  initialState,
  reducers: {
    setSurveyInfo: (state, action) => {
      const {
        backResponses,
        backCurrIndex,
        backIsSubQstn,
        backChoiceNum,
        backSubQstnIndex,
      }: surveyInfo = action.payload;
      state.backResponses = backResponses;
      state.backCurrIndex = backCurrIndex;
      state.backIsSubQstn = backIsSubQstn;
      state.backChoiceNum = backChoiceNum;
      state.backSubQstnIndex = backSubQstnIndex;
    },
    initSurveyInfo: (state) => {
      state.backResponses = [];
      state.backCurrIndex = 0;
      state.backIsSubQstn = false;
      state.backChoiceNum = -1;
      state.backSubQstnIndex = -1;
    },
    setQuestionnairesId: (state, action) => {
      state.questionnairesId = action.payload;
    },
  },
});

export const { setSurveyInfo, initSurveyInfo, setQuestionnairesId } =
  surveyInfoSlice.actions;
export default surveyInfoSlice.reducer;
