import Day from './Day';
import { DayWrapper } from './DayList.styles';
import { useCalendarDates } from './lib/hooks';
import { IWeekInfo } from './lib/types';

interface IDayList {
  date: Date;
  week: IWeekInfo;
  category: string;
  // eslint-disable-next-line no-unused-vars
  dayClickHandler: (selectedDate: Date, prevWeek: IWeekInfo) => void;
}

function DayList({ date, week, category, dayClickHandler }: IDayList) {
  const { calendarDates } = useCalendarDates(date, category);
  return (
    <DayWrapper>
      {calendarDates.map((item) => (
        <Day
          key={item.date.getTime()}
          item={item}
          week={week}
          dayClickHandler={dayClickHandler}
        />
      ))}
    </DayWrapper>
  );
}

export default DayList;
