import { useParams } from 'react-router-dom';
import parse from 'html-react-parser';
import {
  Container,
  Title,
  CategoryStyle,
  Description,
} from './ContentDetail.styles';
import Tag from '../../components/Button/Tag';
import Button from '../../components/Button/Button';
import { useContentDetail, useContentRead } from './lib/utils';
import { IContentDetail } from './lib/types';

function ContentDetail() {
  const { weekid, contentid } = useParams<keyof IContentDetail>();
  useContentRead(Number(contentid));
  const { weekName, missionName, category, description } = useContentDetail(
    Number(weekid),
    Number(contentid),
  );

  return (
    <Container>
      <Tag type="basic1">{weekName}</Tag>
      <Title>{missionName}</Title>
      <Button type="text" style={CategoryStyle}>
        {category && category}
      </Button>
      <Description>
        {parse(description)}
        <br />
        <br />
      </Description>
    </Container>
  );
}

export default ContentDetail;
