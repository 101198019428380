import styled from '@emotion/styled';
import { colors } from '../../../components/Styles/theme';

export const StatisticsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem 0;
`;

export const FlexContainer = styled.div`
  align-items: center;
  display: flex;
`;

export const StatisticsLabel = styled.div`
  color: ${colors.grey_05_subtext};
  font-size: 1.6rem;
  margin-top: 2rem;
`;

export const StatisticsData = styled.div`
  display: flex;
  justify-content: center;
  color: ${colors.black_01_basic};
  font-size: 1.8rem;
  font-weight: bold;
`;

export const Span = styled.span<{ bold?: boolean }>`
  font-size: 1.2rem;
  color: ${colors.grey_05_subtext};
  margin: 0 0.4rem;
  font-weight: ${(props) => (props.bold ? 'bold' : 'normal')};
`;

export const InputCount = styled.div`
  display: flex;
  align-items: center;
  margin: 2rem 0 3rem 0;
`;
