import { callFinish } from 'src/lib/utils';
import Modal from './Modal';

function ErrorModal() {
  const singleButtonHandler = () => {
    callFinish();
  };

  return (
    <Modal
      isOpen
      isSingle
      singleButtonTitle="확인"
      singleButtonHandler={singleButtonHandler}
    >
      <>
        현재 접속이 원활하지 않습니다. <br /> 잠시 후 다시 이용해 주세요.
      </>
    </Modal>
  );
}

export default ErrorModal;
