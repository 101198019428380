import { ReactNode, MouseEvent, MouseEventHandler, CSSProperties } from 'react';
import { Link } from 'react-router-dom';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { colors } from '../Styles/theme';
import loadingSpinner from '../../assets/img/icon-loadingSpinner.svg';
import { ReactComponent as BackGray } from '../../assets/img/icon-back-grey.svg';
import Next from '../../assets/svg/Next';

interface ButtonType {
  children: ReactNode;
  onClick?: MouseEventHandler<HTMLElement>;
  tag?: 'a';
  type?:
    | 'round'
    | 'rectangle'
    | 'rectangle2'
    | 'category'
    | 'text'
    | 'text2'
    | 'arrowLeft'
    | 'arrowRight'
    | 'arrowComplete'
    | 'icon'
    | 'fixed';
  size?: 'small' | 'medium' | 'large';
  className?: string;
  htmlType?: 'button' | 'submit' | 'reset';
  disabled?: boolean;
  loading?: boolean;
  icon?: ReactNode;
  style?: CSSProperties;
  to?: string;
}

function Button(props: ButtonType) {
  const {
    children,
    tag,
    type = 'round',
    size = 'medium',
    className,
    htmlType = 'button',
    disabled: disabledProp = false,
    loading = false,
    icon,
    style,
    to,
  } = props;

  const basicSt = css`
    width: 100%;
    color: ${colors.black_01_basic};
    background-color: ${colors.primary_03_basic};
    font-weight: bold;

    &:active {
      color: ${colors.black_02_pressed};
      background-color: ${colors.primary_01_highlight};
    }
  `;
  const fixedSt = css`
    position: absolute;
    bottom: 6.6rem;
  `;

  const types = {
    round: css`
      ${basicSt}
      border-radius: 2.3rem;
    `,
    rectangle: css`
      ${basicSt}
    `,
    fixed: css`
      ${basicSt}
      ${fixedSt}
    `,
    rectangle2: css`
      width: 100%;
      color: ${colors.black_01_basic};
      background-color: ${colors.primary_01_highlight};
      font-weight: bold;

      &:active {
        color: ${colors.black_02_pressed};
      }
    `,
    category: css`
      border-radius: 1.6rem;
      color: ${colors.grey_04_inactive};
      background-color: ${colors.white_01_basic};
      font-weight: bold;

      &.active {
        color: ${colors.black_01_basic};
        background-color: ${colors.primary_03_basic};
      }
    `,
    text: css`
      font-size: 1.6rem;
      font-weight: bold;
      color: ${colors.primary_03_basic};
    `,
    text2: css`
      font-size: 1.2rem;
      font-weight: bold;
      color: ${colors.grey_04_inactive};
    `,
    arrowLeft: css`
      width: 50%;
      height: 6.6rem;
      font-size: 1.6rem;
      font-weight: bold;
      color: ${colors.grey_05_subtext};
      svg {
        margin-right: 1rem;
      }
    `,
    arrowRight: css`
      width: 50%;
      height: 6.6rem;
      font-size: 1.6rem;
      font-weight: bold;
      color: ${colors.primary_03_basic};
      svg {
        margin-left: 1rem;
      }
      &:disabled {
        color: ${colors.grey_03_icon};
      }
    `,
    arrowComplete: css`
      width: 50%;
      height: 6.6rem;
      font-size: 1.6rem;
      font-weight: bold;
      color: ${colors.primary_03_basic};
    `,
    icon: css`
      display: inline-block;
    `,
  };

  const sizes = {
    icon: css``,
    small: css`
      font-size: 1.4rem;
      padding: 0.6rem 1.2rem;
    `,
    medium: css`
      font-size: 1.4rem;
      padding: 1.4rem 2rem;
    `,
    large: css`
      font-size: 1.6rem;
      padding: 1.8rem;
    `,
  };

  interface IAnchorStyle {
    buttontype: string;
    to: string;
  }

  interface IButtonStyle {
    buttontype: string;
  }
  const ButtonStyle = styled.button<IButtonStyle>`
    ${types[type]}
    ${({ buttontype }) => buttontype !== 'icon' && sizes[size]}
    ${({ disabled }) =>
      disabled &&
      css`
        opacity: 0.5;
        cursor: not-allowed;
        pointer-events: none;
      `}

    .loading {
      width: 2rem;
      height: 2rem;
    }
  `;

  const AnchorStyle = styled(ButtonStyle.withComponent(Link))<IAnchorStyle>`
    display: inline-block;
    text-align: center;
  `;

  const handleClick = (
    e: MouseEvent<HTMLButtonElement | HTMLAnchorElement>,
  ) => {
    if (disabledProp || loading) {
      return;
    }

    const { onClick } = props;

    (onClick as MouseEventHandler<HTMLButtonElement | HTMLAnchorElement>)?.(e);
  };

  switch (tag) {
    case 'a':
      return (
        <AnchorStyle buttontype={type} to={to || ''} style={style}>
          {children}
          {icon && icon}
        </AnchorStyle>
      );
    default:
      return (
        <ButtonStyle
          buttontype={type}
          className={className}
          type={htmlType}
          onClick={handleClick}
          disabled={loading || disabledProp}
          style={style}
        >
          {loading && (
            <img src={loadingSpinner} alt="loading" className="loading" />
          )}
          {!loading && type === 'arrowLeft' && <BackGray />}

          {children}
          {icon && icon}

          {!loading && type === 'arrowRight' && (
            <Next
              color={
                disabledProp
                  ? `${colors.grey_03_icon}`
                  : `${colors.primary_03_basic}`
              }
            />
          )}
          {!loading && type === 'arrowComplete'}
        </ButtonStyle>
      );
  }
}

export default Button;
