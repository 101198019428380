import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useGetLogin } from 'src/services/useLogin';
import Loading from '../Loading/Loading';
import { Container } from './CheckQuestionnaires.styles';

interface ICheckQuestionnaires {
  children: React.ReactNode;
}

function CheckQuestionnaires({ children }: ICheckQuestionnaires) {
  const { pathname } = useLocation();
  const { isLoading, data } = useGetLogin();

  if (!isLoading && !data) return <div>token error</div>;

  if (
    data?.next_questionnaire_id &&
    pathname !== '/questionnaires/step' &&
    pathname !== '/questionnaires/end/' &&
    pathname !== '/etc/cvd/'
  )
    return <Navigate to="/questionnaires/step" />;
  if (!data?.next_questionnaire_id && pathname === '/questionnaires/step')
    return <Navigate to="/" />;

  return isLoading ? <Loading /> : <Container>{children}</Container>;
}

export default CheckQuestionnaires;
