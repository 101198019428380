import { categoryType } from '../../../lib/utils';

interface IlistTarget {
  [key: string]: {
    value: string[];
    tags?: { key: string; unit: string }[];
    device?: string;
    type?: string; // 아침점심저녁간식 meal_type, 식전식후공복 period
    byDateType: string;
  };
}

export const listTarget: IlistTarget = {
  [categoryType.blood]: {
    value: ['sbp', 'dbp'],
    tags: [{ key: 'heart_rate', unit: 'bpm' }],
    device: 'is_device_linked',
    byDateType: 'list',
  },
  [categoryType.sugar]: {
    value: ['bs'],
    type: 'period_nm', // 0알수없음, 1공복(공복), 2아침식사 전(식전), 3식후(식후)
    device: 'is_device_linked',
    byDateType: 'list',
  },
  [categoryType.meal]: {
    value: ['total_calories'],
    type: 'meal_type_nm', // 1아침 2점심 3저녁 4간식
    byDateType: 'status',
  },
  [categoryType.weight]: {
    value: ['weight'],
    tags: [
      { key: 'body_fat', unit: 'kg' },
      { key: 'sk_muscle_pct', unit: 'kg' },
      { key: 'waist_inch', unit: '인치' },
    ],
    device: 'is_device_linked',
    byDateType: 'list',
  },
  [categoryType.step]: {
    value: ['step_count'],
    tags: [
      { key: 'distance', unit: 'km' },
      { key: 'cal_out', unit: 'kcal' },
    ],
    byDateType: 'list',
  },
  [categoryType.exercise]: {
    value: ['calories_out'],
    tags: [
      { key: 'exercise_type_nm', unit: '' },
      { key: 'duration', unit: '분' },
    ],
    byDateType: 'status',
  },
};

export const mealType: any = {
  [categoryType.sugar]: {
    1: '공복',
    2: '식전',
    3: '식후',
  },
  [categoryType.meal]: {
    1: '아침',
    2: '점심',
    3: '저녁',
    4: '간식',
  },
};
