import { useQuery } from 'react-query';
import { get } from '../lib/request';
import { ILog } from '../features/lifeLog/lib/types';

export const useGetBloodPressure = (endDt: string, strDt: string) => {
  return useQuery<ILog>(
    ['blood', endDt, strDt],
    () => get(`/blood_pressure_logs?endDt=${endDt}&strDt=${strDt}`),
    {
      staleTime: Infinity,
      cacheTime: Infinity,
      keepPreviousData: true,
    },
  );
};

export const useGetSugar = (endDt: string, strDt: string) => {
  return useQuery<ILog>(
    ['sugar', endDt, strDt],
    () => get(`/blood_sugar_logs?endDt=${endDt}&strDt=${strDt}`),
    {
      staleTime: Infinity,
      cacheTime: Infinity,
      keepPreviousData: true,
    },
  );
};

export const useGetMeal = (endDt: string, strDt: string) => {
  return useQuery<ILog>(
    ['meal', endDt, strDt],
    () => get(`/meal_logs?endDt=${endDt}&strDt=${strDt}`),
    {
      staleTime: Infinity,
      cacheTime: Infinity,
      keepPreviousData: true,
    },
  );
};

export const useGetWeight = (endDt: string, strDt: string) => {
  return useQuery<ILog>(
    ['weight', endDt, strDt],
    () => get(`/weight_logs?endDt=${endDt}&strDt=${strDt}`),
    {
      staleTime: Infinity,
      cacheTime: Infinity,
      keepPreviousData: true,
    },
  );
};

export const useGetStep = (endDt: string, strDt: string) => {
  return useQuery<ILog>(
    ['step', endDt, strDt],
    () => get(`/daily_step_logs?endDt=${endDt}&strDt=${strDt}`),
    {
      staleTime: Infinity,
      cacheTime: Infinity,
      keepPreviousData: true,
    },
  );
};

export const useGetExercise = (endDt: string, strDt: string) => {
  return useQuery<ILog>(
    ['exercise', endDt, strDt],
    () => get(`/exercise_logs?endDt=${endDt}&strDt=${strDt}`),
    {
      staleTime: Infinity,
      cacheTime: Infinity,
      keepPreviousData: true,
    },
  );
};
