import { ISections, ITargetData } from './types';

export const needCheckRangeObj: { [key: string]: string } = {
  sbp_min: 'sbp_max',
  dbp_min: 'dbp_max',
  bs_ac_min: 'bs_ac_max',
  bs_pc_min: 'bs_pc_max',
};

export const sections: ISections = {
  혈압: [
    {
      id: 1,
      title: '수축기 혈압',
      type: 'range',
      unit: 'mmHg',
      maxLen: 4,
      keys: ['sbp_min', 'sbp_max'],
      minValue: 1,
      maxValue: 999,
    },
    {
      id: 2,
      title: '이완기 혈압',
      type: 'range',
      unit: 'mmHg',
      maxLen: 4,
      keys: ['dbp_min', 'dbp_max'],
      minValue: 1,
      maxValue: 999,
    },
  ],
  혈당: [
    {
      id: 3,
      title: '식전 혈당',
      type: 'range',
      unit: 'mm/dL',
      maxLen: 4,
      keys: ['bs_ac_min', 'bs_ac_max'],
      minValue: 1,
      maxValue: 999,
    },
    {
      id: 4,
      title: '식후 혈당',
      type: 'range',
      unit: 'mm/dL',
      maxLen: 4,
      keys: ['bs_pc_min', 'bs_pc_max'],
      minValue: 1,
      maxValue: 999,
    },
  ],
  식사: [
    {
      id: 5,
      title: '섭취량',
      type: 'normal',
      unit: 'kcal 이하',
      maxLen: 6,
      keys: ['cal_in'],
      minValue: 1,
      maxValue: 99999,
    },
  ],
  걸음: [
    {
      id: 6,
      title: '걸음 수',
      type: 'normal',
      unit: '걸음 이상',
      maxLen: 6,
      keys: ['step_count'],
      minValue: 1,
      maxValue: 99999,
    },
  ],
  운동: [
    {
      id: 7,
      title: '소모열량',
      type: 'normal',
      unit: 'kcal 이상',
      maxLen: 6,
      keys: ['cal_out'],
      minValue: 1,
      maxValue: 99999,
    },
  ],
  체중: [
    {
      id: 8,
      title: '목표체중',
      type: 'normal',
      unit: 'kg',
      maxLen: 5,
      keys: ['weight'],
      minValue: 1.0,
      maxValue: 200.9,
    },
  ],
};

export const initGoalState: ITargetData = {
  sbp_max: '',
  sbp_min: '',
  dbp_max: '',
  dbp_min: '',
  bs_ac_max: '',
  bs_ac_min: '',
  bs_pc_max: '',
  bs_pc_min: '',
  cal_in: '',
  step_count: '',
  cal_out: '',
  weight: '',
  update_date: '',
};
