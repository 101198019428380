import { Container, Img, Text } from './PopularContentEmpty.styles';
import soonImg from '../../../assets/img/image-contents-soon.svg';

function PopularContentEmpty() {
  return (
    <Container>
      <Img>
        <img src={soonImg} alt="이미지" />
      </Img>
      <Text>인기 콘텐츠의 랭킹을 채점하는 중이에요.</Text>
      <Text>인기 콘텐츠 TOP 10을 곧 만나보세요.</Text>
    </Container>
  );
}

export default PopularContentEmpty;
