import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../../app/store';
import { Button, Tag } from '../../components/Button';
import { Container, Bar, BarItem } from './WeekChartDetail.styles';
import {
  getFormattedDate,
  parseToDate,
  numberWithCommas,
  callFinish,
  ocareCat,
  ocareJson,
  categoryType,
} from '../../lib/utils';
import { alert } from '../lifeLog/lib/alert';
import { legend } from './lib/utils';

interface IStatusBar {
  type?: string;
  currentIndex?: number;
  label?: string;
}

function StatusBar({ type, currentIndex, label }: IStatusBar) {
  if (!type) return null;
  return (
    <Bar>
      {legend[type].map((item) => {
        const itemLabel =
          type === categoryType.weight ? item.replace(' ', '\n') : item;
        return (
          <BarItem
            key={item}
            type={type}
            index={currentIndex}
            isActive={item === label}
          >
            {item === label && (
              <span className="point">
                <span className="a11y">값</span>
              </span>
            )}
            <span className="desc">{itemLabel}</span>
          </BarItem>
        );
      })}
    </Bar>
  );
}

function EmptyStatus({
  label,
  diseaseNumber,
}: {
  label: string;
  diseaseNumber: string;
}) {
  return (
    <Container isEmpty>
      <div className="empty">
        <p className="message">{label}을 입력하고 해석 정보를 확인해 보세요.</p>
        <StatusBar type={diseaseNumber} />
      </div>
      <Button
        type="round"
        onClick={() =>
          callFinish(
            ocareJson({
              cat_code: ocareCat[label],
              params: { page: 'home' },
            }),
          )
        }
      >
        {label} 입력
      </Button>
    </Container>
  );
}

interface IWeekChartDetail {
  label: string;
}
function WeekChartDetail({ label }: IWeekChartDetail) {
  const { diseaseNumber, homeLogsGraph, selectedIndex } = useSelector(
    (state: RootState) => state.lifeLogs,
  );

  const isEmpty =
    !homeLogsGraph.length || selectedIndex === null || selectedIndex < 0;

  const selectedData = !isEmpty && homeLogsGraph[selectedIndex];
  const alerts = !isEmpty && alert[diseaseNumber](selectedData);
  const value: { [key: string]: string[] } = {
    '06': ['beforeEmptyBs', 'afterBs'],
    '05': ['sbp', 'dbp'],
    '02': ['weight'],
  };
  const unit: { [key: string]: string } = {
    '06': 'mg/dL',
    '05': 'mmHg',
    '02': 'kg',
  };

  return (
    <div>
      {selectedData && diseaseNumber ? (
        <Link to={`/main/logs/${diseaseNumber}`}>
          {alerts.map(
            (
              currentAlert: {
                index: number;
                label: string;
                message: { title: string; contents: string };
              },
              idx: number,
            ) => {
              const sugarLabel =
                idx === 0 ? `식전/공복 ${label}` : `식후 ${label}`;
              // emtpy "-" 일 때
              return currentAlert.index === null ? (
                <EmptyStatus label={sugarLabel} diseaseNumber={diseaseNumber} />
              ) : (
                <Container isEmpty={false}>
                  <p className="date">
                    {getFormattedDate(
                      parseToDate(selectedData?.log_dttm),
                      'MM월 dd일 E요일',
                    )}
                  </p>
                  <div className="alert">
                    <dl>
                      <dt>{currentAlert.label}</dt>
                      <dd>
                        {diseaseNumber === '06' && (
                          <Tag type="basic5">
                            {idx === 0 ? '식전/공복' : '식후'}
                          </Tag>
                        )}{' '}
                        <span className="value">
                          {diseaseNumber === '05'
                            ? `${numberWithCommas(
                                selectedData?.[value[diseaseNumber][0]],
                              )} / ${numberWithCommas(
                                selectedData?.[value[diseaseNumber][1]],
                              )}`
                            : `${numberWithCommas(
                                selectedData?.[value[diseaseNumber][idx]],
                              )}`}
                          <span className="unit">{unit[diseaseNumber]}</span>
                        </span>
                      </dd>
                    </dl>
                    <StatusBar
                      type={diseaseNumber}
                      currentIndex={currentAlert.index}
                      label={currentAlert.label}
                    />
                    <p className="message">
                      <strong>{currentAlert.message.title}</strong>
                      {currentAlert.message.contents}
                    </p>
                  </div>
                </Container>
              );
            },
          )}
        </Link>
      ) : (
        <EmptyStatus label={label} diseaseNumber={diseaseNumber} />
      )}
    </div>
  );
}

export default WeekChartDetail;
