import styled from '@emotion/styled';
import { css } from '@emotion/react';
import {
  colors,
  classInterval,
  classPassFail,
} from '../../components/Styles/theme';
import { categoryType } from '../../lib/utils';

export interface Itype {
  type: string;
  totalCal?: number;
}

export interface IByDate {
  type: string;
  totalCal?: number;
  categoryNumber?: string;
}

export interface IRecordItem {
  type?: string;
  categoryNumber?: string;
  alertIndex?: number;
  pass?: number;
  classType: string;
}
export const Container = styled.article<Itype>`
  ${({ type }) =>
    type === 'graph'
      ? css`
          margin: 0 1.5rem;
        `
      : css`
          margin: 1rem 0 0;
        `}
`;

export const Record = styled.div`
  color: ${colors.grey_05_subtext};
  /* margin: 1.5rem 0; */
`;

export const ByDate = styled.div<IByDate>`
  height: 38px;

  ${({ type, totalCal, categoryNumber }) =>
    type === 'status' &&
    css`
      position: relative;
      background-color: ${colors.grey_04_inactive};

      &:before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: ${totalCal}%;
        height: 100%;
        border-top-right-radius: 10rem;
        border-bottom-right-radius: 10rem;
        background-color: ${totalCal && totalCal < 100
          ? colors.black_01_basic
          : categoryNumber && classInterval[categoryNumber]['3']};
      }
    `}

  .wrapper {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    width: 100%;
    padding-top: 1rem;
    padding-bottom: 1rem;

    h4 {
      font-size: 1.2rem;
      font-weight: normal;

      color: ${({ type }) => type === 'status' && `${colors.white_01_basic}`};
    }

    ${({ type }) =>
      (type === 'graph' &&
        css`
          border-top: 1px solid ${colors.grey_04_inactive};
          padding-left: 0.5rem;
          padding-right: 0.5rem;
        `) ||
      (type === 'list' &&
        css`
          background-color: ${colors.grey_01_bg};
          padding-left: 2rem;
          padding-right: 2rem;
        `) ||
      (type === 'status' &&
        css`
          padding-left: 2rem;
          padding-right: 2rem;
          position: absolute;
          left: 0;
          top: 0;

          .value {
            font-size: 1.2rem;
            color: ${colors.white_01_basic};

            strong {
              font-weight: bold;
            }
            .unit {
              color: ${colors.white_01_basic};
            }
          }
        `)}
  }
`;

export const RecordItem = styled.dl<IRecordItem>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid ${colors.grey_02_line};

  ${({ type }) =>
    (type === 'graph' &&
      css`
        padding: 0.5rem;
      `) ||
    (type === 'list' &&
      css`
        padding: 0.5rem 2rem;
        &:first-of-type {
          border-top: none;
        }
      `) ||
    (type === 'empty' &&
      css`
        background-color: ${colors.sub_red_01_highlight};
      `)}

  dt,
  dd {
    display: inline-block;
    padding: 0.5rem 0;
  }

  dt {
    /* width: 35%; */
    font-size: 1.6rem;
    display: flex;
    justify-content: flex-start;
    align-items: ${({ categoryNumber }) =>
      categoryNumber === categoryType.meal ? 'center' : 'flex-start'};
    flex-direction: ${({ categoryNumber }) =>
      categoryNumber === categoryType.meal ? 'row' : 'column'};

    .time {
      font-size: 1rem;
    }
    .status {
      font-size: 1.2rem;
      font-weight: bold;
      color: ${({ categoryNumber, alertIndex }) => {
        if (categoryNumber === categoryType.step && alertIndex === 1) {
          return `${colors.black_01_basic}`;
        }
        return (
          categoryNumber &&
          alertIndex &&
          `${classInterval[categoryNumber][alertIndex]}`
        );
      }};
    }
    .mealImg {
      width: 5.2rem;
      height: 5.2rem;
      margin-left: 1rem;
    }
  }
  dd {
    /* width: 65%; */
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .wrapper {
      min-width: ${({ categoryNumber }) =>
        categoryNumber === categoryType.blood ? '13rem' : '9rem'};
      text-align: right;

      .value {
        position: relative;

        &:before {
          content: '';
          position: absolute;
          left: -0.4rem;
          top: 0.2rem;
          width: 0.4rem;
          height: 0.4rem;
          border-radius: 50%;

          background: ${({ classType, categoryNumber, alertIndex, pass }) =>
            (classType === 'interval' &&
              categoryNumber &&
              alertIndex &&
              `${classInterval[categoryNumber][alertIndex]}`) ||
            (classType === 'no' && `transparent`) ||
            (classType === 'pass' && pass === 0
              ? `${classPassFail[0]}`
              : `${classPassFail[1]}`)};
        }

        display: inline-block;
        font-size: 1.8rem;
        font-weight: bold;
        color: ${({ categoryNumber, alertIndex }) =>
          categoryNumber === categoryType.sugar && alertIndex === 5
            ? classInterval[categoryNumber][alertIndex]
            : colors.black_01_basic};

        .goal {
          font-size: 1.2rem;
          font-weight: normal;
        }
      }
    }
  }
`;
export const FoodName = styled.p`
  padding: 0 2rem 1rem;
  color: ${colors.grey_05_subtext};
  font-size: 1rem;
`;

export const RecordItemDetail = styled.div`
  border-top: 1px solid ${colors.grey_04_inactive};
  padding-top: 0.5rem;

  dl {
    border-top: none;
    padding: 0rem 0.5rem;

    dt,
    dd {
      display: inline-block;
      padding: 0.5rem 0;
    }

    dt {
      width: 30%;
      font-size: 1.4rem;
      color: ${colors.grey_05_subtext};
    }

    dd {
      width: 70%;
      text-align: right;
      padding: 0;

      .value {
        font-size: 1.6rem;
        font-weight: bold;
        color: ${colors.black_01_basic};
      }
      .unit {
        min-width: 2rem;
      }
    }
  }
`;

export const DoughnutLegends = styled.ul`
  display: flex;
  justify-content: center;
  align-items: top;
  border-top: 1px solid ${colors.grey_04_inactive};
`;

export interface IDoughnutLegend {
  alertIndex?: number;
}
export const DoughnutLegend = styled.li<IDoughnutLegend>`
  margin: 1rem 0;
  padding: 0 1rem;
  border-left: 1px solid ${colors.grey_02_line};

  &:first-of-type {
    border-left: 0;
  }

  &:nth-of-type(1) {
    dl {
      dt {
        :before {
          background-color: ${colors.category_1};
        }
      }
    }
  }
  &:nth-of-type(2) {
    dl {
      dt {
        :before {
          background-color: ${colors.primary_03_basic};
        }
      }
    }
  }
  &:nth-of-type(3) {
    dl {
      dt {
        :before {
          background-color: ${colors.category_2};
        }
      }
    }
  }

  dl {
    display: flex;
    justify-content: space-between;
    align-items: top;
    padding-left: 1.3rem;

    dt,
    dd {
      display: inline-block;
      position: relative;

      &:before {
        content: '';
        position: absolute;
        left: -1.3rem;
        top: 0.5rem;
        width: 1rem;
        height: 1rem;
      }
    }

    dt {
      font-size: 1.2rem;
    }
    dd {
      .value {
        position: relative;
        margin-left: 1rem;

        &:before {
          content: '';
          position: absolute;
          left: -0.7rem;
          top: 0.2rem;
          width: 0.4rem;
          height: 0.4rem;
          border-radius: 50%;
          background: ${({ alertIndex }) =>
            alertIndex
              ? `${classInterval[categoryType.meal][alertIndex]}`
              : `transparent`};
        }

        display: inline-block;
        font-size: 1.4rem;
        font-weight: bold;
        color: ${colors.black_01_basic};
      }

      text-align: right;
    }
  }
  .standard {
    font-size: 1rem;
    text-align: center;
  }
`;

export const Empty = styled.article`
  position: absolute;
  left: 50%;
  bottom: 50%;
  margin-left: -50%;
  width: 100%;
  padding-left: 2rem;
  text-align: center;
  font-size: 1.2rem;
  font-weight: bold;
`;

export const DoughnutChartWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 3rem;
`;
