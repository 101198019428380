import styled from '@emotion/styled';
import { colors } from '../../../components/Styles/theme';
import { IContentContainer, ILock } from '../lib/types';

export const ContentContainer = styled.div<ILock>`
  width: calc(100% - 2rem);
  height: 7.4rem;
  margin: 0 1rem 1rem 1rem;
  border-radius: 1.6rem;
  background-color: ${(props) =>
    props.isLock ? colors.grey_01_bg : colors.white_01_basic};
  display: flex;
  align-items: center;
  font-size: 1.4rem;
  position: relative;
  box-shadow: ${(props) =>
    props.isLock ? 'none' : '0 0.2rem 1.2rem 0 rgba(0, 0, 0, 0.15)'};
  cursor: ${(props) => (props.isLock ? null : 'pointer')};
`;

export const ContentInfo = styled.div<IContentContainer>`
  padding: 1.7rem 2rem 1.7rem 1.7rem;
  display: flex;
  align-items: center;

  h3 {
    font-size: 1.4rem;
    font-weight: bold;
    color: ${(props) =>
      props.isLock ? colors.grey_03_icon : colors.primary_03_basic};
    text-align: center;
    margin-right: 1rem;
  }

  span {
    font-weight: ${(props) => (props.isRead ? 'normal' : 'bold')};
    color: ${(props) => (props.isLock ? colors.grey_03_icon : null)};
  }
`;

export const ReadCircle = styled.div`
  width: 0.6rem;
  height: 0.6rem;
  background-color: ${colors.primary_03_basic};
  border-radius: 50%;
  position: absolute;
  right: 1rem;
  top: 1rem;
`;
