import { useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import { get } from '../lib/request';
import { getWeekInfo } from '../lib/utils';
import { setWeekLabel } from '../features/lifeLog/lifeLogSlice';

export interface IUser {
  next_questionnaire_id: number;
  question_cnt: number;
  questionnaire_cnt: number;
  user: {
    user_id: number;
    name: string;
    email: string | null;
    height: number;
    weight: number;
    birthday: string;
    gender: number;
    enc_address: string | null;
    enc_phone_number: string | null;
    bmi: number;
    profile_image: string | null;
    site_id: number;
    disease_type: number;
    disease_info: string;
    second_disease_type: number;
    third_disease_type: number;
    loginid: string;
    current_point: null;
    is_deleted: boolean;
    service_start_dt: string;
    service_status: number;
    elapsed_week: number;
    service_group_id: number;
    token: string;
    registration_dttm: string;
  };
}

export const useGetLogin = () => {
  const dispatch = useDispatch();

  return useQuery<IUser>(['user'], () => get(`/user`), {
    staleTime: Infinity,
    cacheTime: Infinity,
    onSuccess: (data) => {
      const { service_start_dt: serviceStartDate } = data.user;

      const today = new Date();

      const { label: weekLabel } = getWeekInfo(
        new Date(serviceStartDate),
        today,
      );
      dispatch(setWeekLabel({ weekLabel }));
    },
    onError: () => {
      console.log('login local error');
    },
  });
};
