import axios from 'axios';
import { MutationCache, QueryCache, QueryClient } from 'react-query';

const location = new URL(document.location);
const { pathname } = location;
const queryParams = location.searchParams;
const token = queryParams.get('token');
if (pathname === '/' && token)
  sessionStorage.setItem('token-a', decodeURIComponent(token));

export const getQueryClient = (setError) => {
  return new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        retry: 0, // 실패시 재호출 횟수
      },
    },
    // 쿼리에 대한 성공, 실패 전처리
    queryCache: new QueryCache({
      onError: (error, query) => {
        // TODO: example
        if (error.status === 401) {
          console.log('refresh token');
        }
        if (query.state.data !== undefined) {
          console.log('wrong!');
        }
        console.log('error...', error, query);
        setError(true);
      },
      onSuccess: (data) => {
        console.log('success', data);
      },
    }),
    // 뮤테이션에 대한 성공, 실패 전처리
    mutationCache: new MutationCache({
      onError: () => {
        setError(true);
      },
      onSuccess: () => {},
    }),
  });
};

export const client = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

export const get = async (url, params = {}) => {
  const result = await client.get(url, {
    params,
    headers: {
      Authorization: sessionStorage.getItem('token-a'),
    },
  });
  sessionStorage.setItem('token-a', result.headers.authorization);
  return result.data;
};

export const post = async (url, payload) => {
  const result = await client.post(url, payload, {
    headers: {
      Authorization: sessionStorage.getItem('token-a'),
    },
  });
  sessionStorage.setItem('token-a', result.headers.authorization);
  return result.data;
};

export const multipartPost = (url, payload) => {
  const formData = new FormData();
  formData.append('responses', JSON.stringify(payload));
  return post(url, formData);
};
