import {
  GuideContainer,
  GuideInfo,
  GuideItem,
  GuideItemWrapper,
  GuideTitle,
} from './index.styles';

interface IRiskColorGuide {
  factor: string;
}

export default function RiskColorGuide({ factor }: IRiskColorGuide) {
  return (
    <GuideContainer>
      <GuideTitle>{factor} 건강 위험도 기준</GuideTitle>
      <GuideInfo>
        국가건강검진 건강위험평가 기준에 따라 혈당 평가정보는 안전,주의,위험
        단계로 구분됩니다.
      </GuideInfo>
      <GuideItemWrapper>
        <GuideItem type={1}>
          <div />
          <div>안전</div>
        </GuideItem>
        <GuideItem type={2}>
          <div />
          <div>주의</div>
        </GuideItem>
        <GuideItem type={3}>
          <div />
          <div>위험</div>
        </GuideItem>
      </GuideItemWrapper>
    </GuideContainer>
  );
}
