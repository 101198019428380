import { useState } from 'react';
import { IWeekInfo } from '../calendar/lib/types';
import { getWeekInfo } from '../../../lib/utils';
import { useUser } from '../../../hooks';

export function useRangeState(fromDate: Date) {
  const {
    user: { service_start_dt: serviceStartDate },
  } = useUser();
  const lastWeekDate = new Date(new Date().getTime() - 7 * 1000 * 60 * 60 * 24);

  const currentWeekInfo = getWeekInfo(
    new Date(`${serviceStartDate}T00:00:00`),
    new Date(),
  );
  const selectedWeekInfo = getWeekInfo(
    new Date(`${serviceStartDate}T00:00:00`),
    fromDate,
  );
  const lastWeekInfo = getWeekInfo(
    new Date(`${serviceStartDate}T00:00:00`),
    lastWeekDate,
  );

  const [weekInfo, setWeekInfo] = useState<IWeekInfo>(
    selectedWeekInfo.label <= 0 ||
      selectedWeekInfo.label >= currentWeekInfo.label
      ? lastWeekInfo
      : selectedWeekInfo,
  );

  const changeWeek = (selectedDate: Date, prevWeek: IWeekInfo) => {
    const newWeek = getWeekInfo(
      new Date(`${serviceStartDate}T00:00:00`),
      selectedDate,
    );
    const currentWeek = getWeekInfo(
      new Date(`${serviceStartDate}T00:00:00`),
      new Date(),
    );
    if (
      prevWeek.label === newWeek.label ||
      currentWeek.label <= newWeek.label ||
      newWeek.label <= 0
    ) {
      return;
    }

    setWeekInfo(newWeek);
  };

  return { weekInfo, changeWeek };
}
