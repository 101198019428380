import { usePostAdviceMsgs } from '../../services/useMsgs';
import { Button } from '../../components/Button';
import Heart from '../../assets/svg/Heart';
import { colors } from '../../components/Styles/theme';

interface ILike {
  id: number;
  liked: boolean;
  isDetail?: boolean;
}

function Like({ id, liked, isDetail = false }: ILike) {
  const { mutate: adviceMsgsMutate } = usePostAdviceMsgs(id);

  const handleHeartIcon = () => {
    adviceMsgsMutate({
      check_dttm: false,
      liked: !liked,
    });
  };
  return (
    <Button
      type="icon"
      icon={
        <Heart
          color={liked ? colors.sub_red_03_basic : colors.grey_03_icon}
          size={isDetail ? '24' : '16'}
        />
      }
      onClick={handleHeartIcon}
    >
      <span className="a11y">Like</span>
    </Button>
  );
}

export default Like;
