import { useEffect } from 'react';
import {
  IRead,
  useGetHealthInfoContentsDetail,
  usePostRead,
} from '../../../services/useHealthInfo';

export function useContentRead(contentid: number) {
  const { mutate } = usePostRead();

  useEffect(() => {
    const readContent: IRead = {
      mission_id: contentid,
      completion_type: 1,
    };
    mutate(readContent);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}

export interface IMissionData {
  weekName: string;
  missionName: string;
  category: string;
  description: string;
}

export function useContentDetail(
  weekid: number,
  contentid: number,
): IMissionData {
  const { data, isIdle, isLoading, isError } = useGetHealthInfoContentsDetail(
    Number(weekid),
  );

  if (isIdle || isLoading || isError)
    return {
      weekName: '',
      missionName: '',
      category: '',
      description: '로딩중입니다',
    };
  const { missions } = data;
  let [curMission] = missions.filter((item) => item.id === Number(contentid));
  if (!curMission)
    [curMission] = missions.filter(
      (item) => item.mission_contents[0].id === Number(contentid),
    );
  const [contents] = curMission.mission_contents;

  return {
    weekName: curMission.week_name,
    missionName: curMission.mission_name,
    category: curMission.description,
    description: contents.description,
  };
}
