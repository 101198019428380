import { toast } from 'react-toastify';

export const showToastBottom = (content: string) => {
  toast.clearWaitingQueue();
  toast.dark(content, {
    position: toast.POSITION.BOTTOM_CENTER,
    autoClose: 1000,
    icon: false,
    hideProgressBar: true,
    toastId: content,
  });
};

export const showToastTop = (content: string) => {
  toast.clearWaitingQueue();
  return toast.dark(content, {
    position: toast.POSITION.TOP_CENTER,
    autoClose: 1000,
    icon: false,
    hideProgressBar: true,
    toastId: content,
  });
};
