interface IIcon {
  color?: string;
}

function Icon({ color }: IIcon) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <g fill="none" fillRule="evenodd">
        <path fill="#FFF" d="M0 0h24v24H0z" />
        <path
          fill="#FFF"
          fillRule="nonzero"
          d="M9.5 11a.5.5 0 100-1 .5.5 0 000 1zm3 0a.5.5 0 100-1 .5.5 0 000 1zm3 0a.5.5 0 100-1 .5.5 0 000 1zm-7 1a.5.5 0 100-1 .5.5 0 000 1zm3 0a.5.5 0 100-1 .5.5 0 000 1zm3 0a.5.5 0 100-1 .5.5 0 000 1z"
        />
        <g fill={color}>
          <g transform="translate(3 4)">
            <rect width="15" height="12" rx="3" />
            <path
              fillRule="nonzero"
              d="M6.666 11H3.333a.333.333 0 00-.333.333v3.333c0 .297.359.446.569.236l3.333-3.333A.333.333 0 006.666 11z"
            />
          </g>
          <g transform="matrix(-1 0 0 1 21 12)">
            <rect
              width="10"
              height="7"
              x="-0.5"
              y="-0.5"
              stroke={color}
              rx="2"
            />
            <path
              fillRule="nonzero"
              d="M4.75 5h-2.5a.25.25 0 00-.25.25v2.5c0 .222.27.334.427.176l2.5-2.5A.25.25 0 004.75 5z"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}

export default Icon;
