import { useSelector } from 'react-redux';
import { RootState } from '../../../app/store';
import { IContentList } from '../../../services/useHealthInfo';
import WeekContent from './WeekContent';
import WeekContentEmpty from './WeekContentEmpty';
import { CustomLink } from './WeekContentList.styles';

interface IWeekContents {
  week: number;
  data: IContentList;
}

function WeekContentList({ week, data }: IWeekContents) {
  const displayWeek = useSelector(
    (state: RootState) => state.healthInfo.swipeWeek,
  );

  if (
    data.mission_weeks.length === 0 ||
    week < 0 ||
    data.mission_weeks === undefined
  )
    return <WeekContentEmpty />;

  const missions = data.mission_weeks.filter(
    (item) => item.week === displayWeek,
  );
  const [{ mission_previews: contents = [], id: weekId }] = missions;

  if (contents.length === 0 || contents === undefined)
    return <WeekContentEmpty />;

  if (displayWeek <= week) {
    return (
      <>
        {contents.map((item, idx) => (
          <CustomLink
            key={item.id}
            to={`/contents/detail/${weekId}/${item.id}`}
          >
            <WeekContent
              week={week}
              id={idx}
              missionName={item.mission_name}
              isRead={item.is_read}
            />
          </CustomLink>
        ))}
      </>
    );
  }

  return (
    <>
      {contents.map((item, idx) => (
        <WeekContent
          key={item.id}
          week={week}
          id={idx}
          missionName={item.mission_name}
          isRead={item.is_read}
        />
      ))}
    </>
  );
}

export default WeekContentList;
