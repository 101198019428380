import styled from '@emotion/styled';

export const Container = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 1rem 2rem 0;

  h3 {
    font-size: 1.8rem;
  }
  a {
    padding: 0;
  }
`;
