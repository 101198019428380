import { useState } from 'react';
import styled from '@emotion/styled';
import { MoreHorizontal } from 'react-feather';
import { Button, Tag } from '../Button';
import { ReactComponent as CircleBack } from '../../assets/img/icon-circle-back.svg';

const GuideStyle = styled.div`
  margin: 0 3rem;
  padding: 1.5rem;

  h2 {
    font-size: 2rem;
    padding-bottom: 2rem;
  }
  h3 {
    font-size: 1.2rem;
    padding-bottom: 1rem;
  }

  article {
    padding-top: 2rem;

    section {
      border-top: 1px solid #ddd;
      padding: 2rem 0;
    }

    button,
    a {
      margin: 1rem 0;
    }
  }
`;

function Guide() {
  const [loading, setLoading] = useState<boolean>(false);

  return (
    <GuideStyle>
      <article>
        <h2>Button</h2>

        <section>
          <h3>Button tags</h3>
          <div>
            <Button type="round">button</Button>
            <Button type="round" tag="a">
              a
            </Button>
          </div>
        </section>

        <section>
          <h3>Button types</h3>
          <div>
            <Button type="round">round</Button>
            <Button type="rectangle">rectangle</Button>
            <Button type="rectangle2">rectangle2</Button>
            <div>
              <Button type="category" size="small">
                혈당
              </Button>
              <Button type="category" size="small" className="active">
                혈당
              </Button>
            </div>
            <Button type="text" tag="a">
              a tag & text
            </Button>
            <Button type="rectangle" style={{ color: '#f00' }}>
              inline style
            </Button>
            <Button type="text2" tag="a" icon={<MoreHorizontal size={10} />}>
              자세히보기
            </Button>
            <Button type="rectangle" icon={<MoreHorizontal size={10} />}>
              with icon
            </Button>
          </div>

          <div>
            <Button type="arrowLeft">이전</Button>
            <Button type="arrowRight" disabled>
              다음
            </Button>
            <Button type="arrowRight">다음</Button>
            <Button type="arrowComplete">완료</Button>
          </div>
        </section>

        <section>
          <h3>Button sizes</h3>
          <Button type="round" size="small">
            small
          </Button>
          &nbsp;
          <Button type="round" size="medium">
            medium
          </Button>
          &nbsp;
          <Button type="round" size="large">
            Large
          </Button>
        </section>

        <section>
          <h3>Button states</h3>
          <Button
            type="round"
            onClick={() => setLoading(true)}
            loading={loading}
          >
            click and loading
          </Button>
          &nbsp;
          <Button type="round" disabled loading={false}>
            disabled
          </Button>
        </section>

        <section>
          <h3>Button only by Icon</h3>
          <Button type="icon" icon={<CircleBack />}>
            <span className="a11y">Back</span>
          </Button>
        </section>
      </article>
      <article>
        <h2>Tag</h2>

        <section>
          <h3>Tag types</h3>
          <div>
            <Tag type="basic1">basic1</Tag>
            <Tag type="basic2">basic2</Tag>
            <Tag type="basic3">basic3</Tag>
            <Tag type="basic4">basic4</Tag>
            <Tag type="basic5">basic5</Tag>
            <Tag type="basic6">basic6</Tag>
            <Tag type="basic7">basic7</Tag>
            <Tag type="basic1" style={{ color: '#f00' }}>
              basic1 & inline style
            </Tag>
          </div>
        </section>
      </article>
    </GuideStyle>
  );
}

export default Guide;
