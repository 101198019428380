import { IFactor } from './lib/types';
import { Factors } from './Summary.styles';

interface ISummary {
  factors: IFactor[];
  userName: string;
}

export default function Summary({ factors, userName }: ISummary) {
  const mainFactors = factors
    .filter((item) => item.factor_type === 3)
    .map((item) => item.title);

  return (
    <Factors>
      {mainFactors.length > 0 ? (
        <>
          <p>{userName}님의 심뇌혈관 질환 위험요인은</p>
          <p>
            <span>&apos;{mainFactors.join(', ')}&apos;</span>
            이며,
          </p>
          <p>지금 당장 생활 습관 개선이 필요합니다.</p>
        </>
      ) : (
        <div>
          {userName}님은 심뇌혈관 위험요인이 없습니다. 나의건강 상태를 유지해
          주세요.
        </div>
      )}
    </Factors>
  );
}
