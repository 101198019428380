import styled from '@emotion/styled';
import { colors } from '../../../components/Styles/theme';

export const ItemWrapper = styled.div`
  padding: 0.9rem 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ItemLabel = styled.span`
  color: ${colors.grey_05_subtext};
  font-size: 1.6rem;
  width: 5.7rem;
`;

export const ItemRange = styled.span`
  color: ${colors.grey_05_subtext};
  font-size: 1.2rem;
`;
