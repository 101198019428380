import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../app/store';
import useGetCategory from './lib/useGetCategory';
import { useWeekList } from '../../hooks';
import DoughnutChart from './Chart/DoughnutChart';
import {
  Container,
  Record,
  ByDate,
  RecordItem,
  RecordItemDetail,
  DoughnutLegends,
  DoughnutLegend,
  DoughnutChartWrapper,
} from './Records.styles';
import { Button } from '../../components/Button';
import {
  getFormattedDate,
  parseToDate,
  numberToCategory,
  categoryType,
  numberWithCommas,
} from '../../lib/utils';
import { alert } from './lib/alert';
import { recordTarget } from './lib/record';
import { ReactComponent as Question } from '../../assets/img/icon-question.svg';
import { BottomSheet } from '../../components/Modal';
import useBottomSheet from '../../hooks/useBottomSheet';
import RiskColorGuide from '../../components/RiskColorGuide';

interface IdayLog {
  index: number;
  label: string;
  value: number | string;
  alertIndex: number;
}
interface IdayDetailLog {
  index: number;
  label: string;
  value: any;
  unit: any;
}
interface IweekLog {
  index: number;
  label: string;
  value: number;
}
interface IData {
  dayLog: IdayLog[];
  dayDetailLog: IdayDetailLog[] | undefined;
  weekLog: IweekLog[] | undefined;
}
interface InutrientsLog {
  label: string;
  value: number | string | undefined;
  note: string;
  alertIndex: number;
}

function Records() {
  const { categoryNumber, weekLabel, logsGraph, stat, selectedIndex } =
    useSelector((state: RootState) => state.lifeLogs);
  const dispatch = useDispatch();
  const categories = useGetCategory();
  const [data, setData] = useState<IData>({
    dayLog: [],
    dayDetailLog: [],
    weekLog: [],
  });
  const [nutrients, setNutrients] = useState<InutrientsLog[]>([]);
  const { weeks } = useWeekList();
  const currentWeek = weeks.find((week) => week.label === weekLabel);
  const category = categories[categoryNumber];
  const { visible, open, close } = useBottomSheet();

  const isWeek =
    categoryNumber &&
    Object.prototype.hasOwnProperty.call(recordTarget[categoryNumber], 'week');

  const isDayDetail =
    categoryNumber &&
    Object.prototype.hasOwnProperty.call(
      recordTarget[categoryNumber],
      'dayDetail',
    );

  useEffect(() => {
    if (logsGraph.length === 0 || selectedIndex === null) return;

    const dayLog = categoryNumber
      ? recordTarget[categoryNumber].day.map((set, index) => {
          const alertKey =
            (categoryNumber === categoryType.blood ||
              categoryNumber === categoryType.sugar) &&
            index < 2
              ? `${categoryNumber}_${set.key}`
              : `${categoryNumber}`;

          return {
            index,
            label: set.label,
            value:
              selectedIndex >= 0 ? logsGraph[selectedIndex]?.[set.key] : '-',
            alertIndex:
              selectedIndex >= 0 &&
              logsGraph[selectedIndex]?.[set.key] &&
              logsGraph[selectedIndex]?.[set.key] !== '-'
                ? alert[alertKey](
                    logsGraph[selectedIndex][set.key],
                    categories[categoryNumber].goalMax,
                  )
                : 9,
          };
        })
      : [];

    const dayDetailLog = isDayDetail
      ? recordTarget[categoryNumber].dayDetail?.map((set, index) => {
          if (categoryNumber === categoryType.weight && index === 2) {
            return {
              index,
              label: set.label,
              value:
                selectedIndex >= 0
                  ? set.key.map(
                      (item: string) => logsGraph[selectedIndex][item],
                    )
                  : '-',
              unit: set.unit,
            };
          }
          return {
            index,
            label: set.label,
            value: selectedIndex >= 0 ? logsGraph[selectedIndex][set.key] : '-',
            unit: set.unit,
          };
        })
      : [];

    const weekLog = isWeek
      ? recordTarget[categoryNumber].week?.map((set, index) => {
          return {
            index,
            label: set.label,
            value: stat?.[set.key],
          };
        })
      : [];

    if (categoryNumber)
      setData({
        dayLog,
        dayDetailLog,
        weekLog,
      });

    if (categoryNumber === categoryType.meal) {
      setNutrients([
        {
          label: '탄수화물',
          value:
            selectedIndex >= 0 ? logsGraph[selectedIndex].total_carbs_rto : '-',
          note: '(표준 50 - 60%)',
          alertIndex:
            selectedIndex >= 0
              ? alert[`${categoryType.meal}_nut`](
                  logsGraph[selectedIndex].total_carbs,
                  60,
                )
              : 9,
        },
        {
          label: '단백질',
          value:
            selectedIndex >= 0
              ? logsGraph[selectedIndex].total_protein_rto
              : '-',
          note: '(표준 10 - 20%)',
          alertIndex:
            selectedIndex >= 0
              ? alert[`${categoryType.meal}_nut`](
                  logsGraph[selectedIndex].total_protein,
                  20,
                )
              : 9,
        },
        {
          label: '지방',
          value:
            selectedIndex >= 0 ? logsGraph[selectedIndex].total_fat_rto : '-',
          note: '(표준 20 - 30%)',
          alertIndex:
            selectedIndex >= 0
              ? alert[`${categoryType.meal}_nut`](
                  logsGraph[selectedIndex].total_fat,
                  30,
                )
              : 9,
        },
      ]);
    }
  }, [
    dispatch,
    categories,
    categoryNumber,
    selectedIndex,
    stat,
    weekLabel,
    logsGraph,
    isDayDetail,
    isWeek,
  ]);

  return selectedIndex !== null && categoryNumber && currentWeek ? (
    <Container type="graph">
      {/* day */}
      <Record>
        <ByDate type="graph">
          <div className="wrapper">
            <h4>
              {selectedIndex >= 0
                ? `${getFormattedDate(
                    parseToDate(logsGraph[selectedIndex].log_dttm),
                    'yyyy년 MM월 dd일 E요일',
                  )}`
                : `${getFormattedDate(
                    currentWeek.startDate,
                    'yyyy년 MM월 dd일 E요일',
                  )} -
                  ${getFormattedDate(
                    currentWeek.endDate,
                    'yyyy년 MM월 dd일 E요일',
                  )}`}
            </h4>
            {category.isRiskGuide && (
              <Button type="icon" icon={<Question />} onClick={open}>
                <span className="a11y">RiskColorGuide</span>
              </Button>
            )}
          </div>
        </ByDate>

        {data.dayLog.map((log) => (
          <RecordItem
            key={log.index}
            type="graph"
            classType="interval"
            categoryNumber={categoryNumber}
            alertIndex={log.alertIndex}
          >
            <dt>{log.label}</dt>
            <dd>
              <div className="wrapper">
                {categoryNumber === categoryType.blood ||
                categoryNumber === categoryType.sugar ? (
                  <span className="value">{log.value}</span>
                ) : (
                  <span className="value">
                    {numberWithCommas(log.value)}
                    <span className="goal">
                      {' '}
                      / {category && numberWithCommas(category.goalMax)}
                    </span>
                  </span>
                )}

                <span className="unit">{category && category.unit[0]}</span>
              </div>
            </dd>
          </RecordItem>
        ))}

        {isDayDetail && (
          <RecordItemDetail>
            {data.dayDetailLog &&
              data.dayDetailLog.map((log) => {
                return (
                  <dl key={log.index}>
                    <dt>{log.label}</dt>
                    <dd>
                      {/* 기존 요건에서 벗어나는 구조로 인한 에외처리 */}
                      <div className="wrapper">
                        {log.label === '허리둘레' &&
                        Array.isArray(log.value) ? (
                          <>
                            {log.value.map((item: number, index: number) => {
                              return (
                                <>
                                  <span className="value">
                                    {index === 1 && <span> </span>}
                                    {item === 0 ? '-' : numberWithCommas(item)}
                                    {/* {index === 1
                                      ? ` ${numberWithCommas(item)}`
                                      : numberWithCommas(item)} */}
                                  </span>
                                  <span className="unit">
                                    {log.unit[index]}
                                    {index === 0 && <span> / </span>}
                                  </span>
                                </>
                              );
                            })}
                          </>
                        ) : (
                          <>
                            <span className="value">
                              {log.value === 0
                                ? '-'
                                : numberWithCommas(log.value)}
                            </span>
                            <span className="unit">{log.unit}</span>
                          </>
                        )}
                      </div>
                    </dd>
                  </dl>
                );
              })}
          </RecordItemDetail>
        )}
      </Record>

      {/* meal */}
      {categoryNumber === categoryType.meal && nutrients.length > 0 && (
        <>
          <DoughnutLegends>
            {nutrients.map((log) => (
              <DoughnutLegend key={log.label} alertIndex={log.alertIndex}>
                <dl>
                  <dt>{log.label}</dt>
                  <dd>
                    <span className="value">{numberWithCommas(log.value)}</span>
                    <span className="unit">{category && category.unit[1]}</span>
                  </dd>
                </dl>
                <div className="standard">{log.note}</div>
              </DoughnutLegend>
            ))}
          </DoughnutLegends>
          <DoughnutChartWrapper>
            <DoughnutChart nutrients={nutrients} />
          </DoughnutChartWrapper>
        </>
      )}

      {/* week */}
      {isWeek && (
        <Record>
          <ByDate type="graph">
            <div className="wrapper">
              <h4>
                {getFormattedDate(
                  currentWeek.startDate,
                  'yyyy년 MM월 dd일 E요일',
                )}{' '}
                -{' '}
                {getFormattedDate(
                  currentWeek.endDate,
                  'yyyy년 MM월 dd일 E요일',
                )}
              </h4>
            </div>
          </ByDate>

          {data.weekLog &&
            data.weekLog.map((log, index) => {
              // 혈당, 혈압 횟수
              if (index < 2) {
                return (
                  <RecordItem
                    key={log.index}
                    type="graph"
                    classType="pass"
                    pass={log.value}
                  >
                    <dt>{log.label}</dt>
                    <dd>
                      <div className="wrapper">
                        <span className="value">
                          {numberWithCommas(log.value)}
                        </span>
                        <span className="unit">회</span>
                      </div>
                    </dd>
                  </RecordItem>
                );
              }
              // 혈당 평균 (컬리표시 제거)
              return (
                <RecordItem
                  key={log.index}
                  type="graph"
                  classType="interval"
                  categoryNumber={categoryNumber}
                  alertIndex={9}
                >
                  <dt>{log.label}</dt>
                  <dd>
                    <div className="wrapper">
                      <span className="value">
                        {numberWithCommas(log.value)}
                      </span>
                      <span className="unit">mg/dL</span>
                    </div>
                  </dd>
                </RecordItem>
              );
            })}
        </Record>
      )}

      <BottomSheet visible={visible} close={close}>
        <RiskColorGuide factor={numberToCategory[categoryNumber]} />
      </BottomSheet>
    </Container>
  ) : null;
}

export default Records;
