import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { RootState } from '../../app/store';
import Header from './Header';
import { Tag } from '../../components/Button';
import SlickSlider from './SlickSlider';
import Like from '../healthManager/Like';
import { Container, Item, Message } from './Messages.styles';
import { IMessages } from './lib/types';
import { getFormattedDate, parseToDate } from '../../lib/utils';

function Messages() {
  const { coach, messages } = useSelector((state: RootState) => state.home);

  return (
    <Container>
      <Header to="/msgs">헬스 메시지</Header>

      {messages.length > 0 ? (
        <SlickSlider opts={{ centerPadding: '16' }}>
          {messages.map((message: IMessages) => {
            const {
              id,
              category,
              liked,
              delegate_admin_profile_image: delegateAdminProfileImage,
              admin_name: adminName,
              sent_dttm: sentDttm,
              title,
            } = message;
            return (
              <Item key={id}>
                <div className="tags">
                  <Tag type="basic5">{category}</Tag>
                  <Like id={id} liked={liked} />
                </div>
                <Link to={`/msgs/advice/detail/${id}`}>
                  <Message>
                    <dt>
                      <img src={delegateAdminProfileImage} alt={adminName} />
                    </dt>
                    <dd>
                      <p className="date">
                        {getFormattedDate(
                          parseToDate(sentDttm),
                          'MM월 dd일, a hh:mm',
                        )}
                      </p>
                      <p className="message">{title}</p>
                    </dd>
                  </Message>
                </Link>
              </Item>
            );
          })}
        </SlickSlider>
      ) : (
        <Item isEmpty>
          <Message>
            <dt>
              <img src={coach} alt="담당코치" />
            </dt>
            <dd>
              <p className="empty">도착한 헬스 메시지가 없습니다.</p>
            </dd>
          </Message>
        </Item>
      )}
    </Container>
  );
}

export default Messages;
