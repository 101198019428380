import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useBottomSheet } from 'src/hooks';
import { BottomSheet } from 'src/components/Modal';
import { RootState } from '../../app/store';
import Header from './Header';
import { Tag } from '../../components/Button';
import {
  Container,
  ItemWrap,
  Item,
  Empty,
  OcareButton,
  BottomSheetItem,
} from './TodayLogs.styles';
import { ITodayLogs } from './lib/types';
import {
  getFormattedDate,
  parseToDate,
  categoryToNumber,
  numberWithCommas,
  callFinish,
  ocareCat,
  ocareJson,
} from '../../lib/utils';
import { mealType } from './lib/utils';

function Logs() {
  const { todayLogs } = useSelector((state: RootState) => state.home);
  const { visible, open, close } = useBottomSheet();

  const openBottomSheet: any = () => {
    open();
  };

  const goToOcare: any = (name: string, type: string = '') => {
    if (name === '식사') {
      if (type === '') return;
      callFinish(
        ocareJson({
          cat_code: ocareCat[name],
          cat_code_detail: type,
          params: {
            page: 'logs',
            categoryNumber: categoryToNumber[name],
          },
        }),
      );
    } else {
      callFinish(
        ocareJson({
          cat_code: ocareCat[name],
          params: {
            page: 'logs',
            categoryNumber: categoryToNumber[name],
          },
        }),
      );
    }
  };

  return (
    <Container>
      {/* 데이터유무와 관계없이 0번째 링크로 연결 */}
      <Header>오늘의 건강기록</Header>

      {todayLogs.map((log: ITodayLogs) => {
        const { id, log_nm: logName, time, value, unit, period, goal } = log;
        const link = `/main/logs/${categoryToNumber[logName]}`;
        return value && time ? (
          <Link key={id} to={link}>
            <ItemWrap>
              <Item>
                <dt>{logName}</dt>
                <dd>
                  <span className="time">
                    {getFormattedDate(parseToDate(time), 'a hh:mm')}
                  </span>
                  <span className="val">
                    {logName === '혈당' && period && (
                      <Tag type="basic5">{period}</Tag>
                    )}{' '}
                    <strong>
                      {logName === '혈압' ? value : numberWithCommas(value)}
                    </strong>
                    {logName !== '혈당' &&
                      logName !== '혈압' &&
                      ` / ${numberWithCommas(goal)}`}
                    <span className="unit">{unit}</span>
                  </span>
                </dd>
              </Item>
            </ItemWrap>
          </Link>
        ) : (
          <OcareButton
            key={id}
            type="button"
            onClick={() => goToOcare(logName)}
          >
            <ItemWrap onClick={logName === '식사' && openBottomSheet}>
              <Empty>
                {logName}
                {logName === '식사' ? '를' : '을'} 입력해주세요.
              </Empty>
            </ItemWrap>
          </OcareButton>
        );
      })}
      <BottomSheet visible={visible} close={close}>
        {mealType.map((item) => (
          <BottomSheetItem
            key={item.name}
            onClick={() => goToOcare('식사', item.detailCode)}
          >
            {item.name}
          </BottomSheetItem>
        ))}
      </BottomSheet>
    </Container>
  );
}

export default Logs;
