import { LegendItem, LegendWrapper, LegnedList } from './Legend.styles';
import { legendMarker, categoryObj } from './lib/utils';
import { ICategory } from './lib/types';

function Legend({ category }: ICategory) {
  return (
    <LegendWrapper>
      <LegnedList>
        {categoryObj[category] &&
          categoryObj[category].legend.map((item) => (
            <LegendItem key={item.id} marker={legendMarker[item.marker]}>
              {item.content}
            </LegendItem>
          ))}
      </LegnedList>
    </LegendWrapper>
  );
}

export default Legend;
