import { categoryType } from '../../../lib/utils';

interface Ialert {
  [key: string]: Function;
}

// TODO: any
export function alertIndexBlood(data: any) {
  if (data.sbp >= 160 || data.dbp >= 110) {
    return [
      {
        index: 5,
        label: '고혈압 2단계',
        message: {
          title: '주의가 필요해요!',
          contents:
            '정상범위 보다 높은 혈압 수치 입니다. 혈압 안정범위는 수축기 120mmHg 미만, 이완기 80mmHg 미만입니다.',
        },
      },
    ];
  }

  if (
    (data.sbp >= 140 && data.sbp <= 159) ||
    (data.dbp >= 90 && data.dbp <= 99)
  ) {
    return [
      {
        index: 4,
        label: '고혈압 1단계',
        message: {
          title: '주의가 필요해요!',
          contents:
            '정상범위 보다 높은 혈압 수치 입니다. 혈압 안정범위는 수축기 120mmHg 미만, 이완기 80mmHg 미만입니다.',
        },
      },
    ];
  }

  if (
    (data.sbp >= 120 && data.sbp <= 139) ||
    (data.dbp >= 80 && data.dbp <= 89)
  ) {
    return [
      {
        index: 3,
        label: '고혈압 전단계',
        message: {
          title: '조금만 더 노력하면 정상범위에요!',
          contents:
            '평소보다 높다면 충분히 휴식을 취한 후 혈압을 다시 측정해보세요. 혈압 안정범위는 수축기 120mmHg 미만, 이완기 80mmHg 미만입니다.',
        },
      },
    ];
  }

  if (data.sbp < 120 && data.dbp < 80) {
    return [
      {
        index: 2,
        label: '정상',
        message: {
          title: '잘하고 있어요!',
          contents:
            '수축기 80~120mmHg 미만 이완기 60~80mmHg 미만으로 안정범위에 있습니다. 지금의 컨디션을 유지해주세요.',
        },
      },
    ];
  }

  if (data.sbp < 90 && data.dbp < 60) {
    return [
      {
        index: 1,
        label: '저혈압',
        message: {
          title: '주의가 필요해요!',
          contents:
            '안정범위 혈압 수치인 수축기 80~120mmHg 미만, 이완기60~ 80mmHg 미만 보다 낮은 범위에 있습니다. 충분한 수분을 섭취하고 휴식을 취하신 후 다시 측정해 보세요.',
        },
      },
    ];
  }

  return [
    {
      index: 6,
      label: '고혈압 2단계',
      message: {
        title: '주의가 필요해요!',
        contents:
          '정상범위 보다 높은 혈압 수치 입니다. 혈압 안정범위는 수축기 120mmHg 미만, 이완기 80mmHg 미만입니다.',
      },
    },
  ];
}

export function alertIndexBloodSbp(value: number) {
  if (value < 90) {
    return 1;
  }
  if (value < 120) {
    return 2;
  }
  if (value >= 120 && value <= 139) {
    return 3;
  }
  return 4;
}

export function alertIndexBloodDbp(value: number) {
  if (value < 60) {
    return 1;
  }
  if (value < 80) {
    return 2;
  }
  if (value >= 80 && value <= 89) {
    return 3;
  }
  return 4;
}

export function alertIndexSugarBefore(value: number) {
  if (value <= 70) {
    return 1;
  }
  if (value >= 71 && value <= 129) {
    return 2;
  }
  if (value >= 130 && value <= 199) {
    return 3;
  }
  // if (value > 200) {
  //   return 4;
  // }
  return 4;
}

export function alertIndexSugarAfter(value: number) {
  if (value < 89) {
    return 1;
  }
  if (value >= 90 && value <= 179) {
    return 2;
  }
  if (value >= 180 && value <= 199) {
    return 3;
  }
  // if (value > 200) {
  //   return 4;
  // }
  return 4;
}

export function alertIndexMeal(value: number, goal: number) {
  if (value > goal) {
    return 3;
  }
  return 1;
}

export function alertIndexMealNut(value: number, goal: number) {
  if (value <= 0) {
    return 1;
  }
  if (value < goal) {
    return 2;
  }
  return 3;
}

export function alertIndexSugar(data: any) {
  const { beforeEmptyBs, afterBs } = data;
  const alertArr = [];

  // 식전/공복 혈당
  if (beforeEmptyBs === '-')
    alertArr.push({
      index: null,
    });
  if (beforeEmptyBs <= 70) {
    alertArr.push({
      index: 1,
      label: '저혈당',
      message: {
        title: '주의가 필요해요!',
        contents:
          '정상범위보다 낮은 혈당 수치 입니다. 어지럼증, 기운 빠짐, 식은땀 등 저혈당 증상이 느껴진다면 사탕 등의 간식을 먹어 보세요.',
      },
    });
  }
  if (beforeEmptyBs >= 71 && beforeEmptyBs <= 129) {
    alertArr.push({
      index: 2,
      label: '정상',
      message: {
        title: '잘하고 있어요!',
        contents: '식전/공복혈당은 71~129mg/dl 사이로 안정범위 입니다.',
      },
    });
  }
  if (beforeEmptyBs >= 130 && beforeEmptyBs <= 199) {
    alertArr.push({
      index: 3,
      label: '고혈당 위험',
      message: {
        title: '조금만 더 노력하면 정상범위에요!',
        contents:
          '혈당 안정 범위인 식전/공복 71~130mg/dl 미만, 식후 180mg/dl 미만을 초과하였습니다. 식사조절과 운동을 통해 혈당을 조절해 보세요.',
      },
    });
  }
  if (beforeEmptyBs > 200) {
    alertArr.push({
      index: 4,
      label: '고혈당',
      message: [
        {
          title: '주의가 필요해요!',
          contents:
            '혈당 안정 범위인 식전/공복 71~130mg/dl, 식후 180mg/dl 를 초과하였습니다. 식사와 운동 등 혈당 수치에 영향을 미친 항목을 확인해 보세요.',
        },
      ],
    });
  }

  // 식후 혈당
  if (afterBs === '-')
    alertArr.push({
      index: null,
    });
  if (afterBs < 89) {
    alertArr.push({
      index: 1,
      label: '저혈당',
      message: {
        title: '주의가 필요해요!',
        contents:
          '정상범위 보다 낮은 식후 혈당 수치입니다. 재측정에도 같은 수치가 나오거나 인슐린 또는 약물 복용 중이시라면 의료진과 꼭 상의하세요.',
      },
    });
  }
  if (afterBs >= 90 && afterBs <= 179) {
    alertArr.push({
      index: 2,
      label: '정상',
      message: {
        title: '잘하고 있어요!',
        contents: '식후혈당은 180mg/dl 미만으로 안정 범위 입니다.',
      },
    });
  }
  if (afterBs >= 180 && afterBs <= 199) {
    alertArr.push({
      index: 3,
      label: '고혈당 위험',
      message: {
        title: '조금만 더 노력하면 정상범위에요!',
        contents:
          '혈당 안정 범위인 식전/공복 71~130mg/dl 미만, 식후 180mg/dl 미만을 초과하였습니다. 혈당을 높이는 식사였다면 다음 식사는 주의해 보세요.',
      },
    });
  }
  if (afterBs > 200) {
    alertArr.push({
      index: 4,
      label: '고혈당',
      message: [
        {
          title: '주의가 필요해요!',
          contents:
            '혈당 안정 범위인 식전/공복 71~130mg/dl, 식후 180mg/dl 를 초과하였습니다. 식사와 운동 등 혈당 수치에 영향을 미친 항목을 확인해 보세요.',
        },
      ],
    });
  }

  return alertArr;
}

export function alertIndexWeight(data: any) {
  if (data.bmi < 18.5) {
    return [
      {
        index: 1,
        label: '저체중',
        message: {
          title: '주의가 필요해요!',
          contents:
            '정상 체중보다 낮은 편입니다. 무리하지 않고 충분한 휴식을 취하고 열량이 많은 음식과 수분을 많이 섭취하시는걸 권장 드립니다.',
        },
      },
    ];
  }
  if (data.bmi >= 18.5 && data.bmi <= 22.9) {
    return [
      {
        index: 2,
        label: '정상',
        message: {
          title: '잘하고 있어요!',
          contents: '표준 체중입니다. 현재상태를 계속 유지하세요.',
        },
      },
    ];
  }
  if (data.bmi >= 23 && data.bmi <= 24.9) {
    return [
      {
        index: 3,
        label: '비만 전단계',
        message: {
          title: '조금만 더 노력하면 정상범위에요!',
          contents:
            '평균 체중보다 높은 편 입니다. 채소와 과일을 충분히 섭취하고 적정한 운동이 필요합니다.',
        },
      },
    ];
  }
  if (data.bmi >= 25 && data.bmi <= 29.9) {
    return [
      {
        index: 4,
        label: '1단계 비만',
        message: {
          title: '주의가 필요해요!',
          contents:
            '1단계 비만에 해당하는 체중입니다. 적절한 운동으로 체중 감량을 하시는 것을 권장드립니다.',
        },
      },
    ];
  }
  if (data.bmi >= 30 && data.bmi <= 34.9) {
    return [
      {
        index: 4,
        label: '2단계 비만',
        message: {
          title: '주의가 필요해요!',
          contents:
            '2단계 비만에 해당하는 체중입니다. 적절한 운동과 식이요법을 병행 하시는 것을 권장드립니다.',
        },
      },
    ];
  }
  // if (data.bmi >= 35) {
  // return {index: 4, label: '3단계 비만'};
  // }
  return [
    {
      index: 4,
      label: '3단계 비만',
      message: {
        title: '매우 주의가 필요해요!',
        contents:
          '고도 비만에 해당하는 체중입니다. 체중 조절을 위해 운동과 식이요법을 병행하시기 바랍니다.',
      },
    },
  ];
}

export function alertIndexWeightBmi(value: number) {
  if (value < 18.5) {
    return 1;
  }
  if (value >= 18.5 && value <= 22.9) {
    return 2;
  }
  if (value >= 23 && value <= 24.9) {
    return 3;
  }
  if (value >= 25 && value <= 29.9) {
    return 4;
  }
  if (value >= 30 && value <= 34.9) {
    return 4;
  }
  // if (value > 35) {
  //   return 4;
  // }
  return 4;
}
export function alertIndexStep(data: any, goal: number) {
  if (data.step_count > goal) {
    return { index: 3, label: '목표 달성' };
  }
  return { index: 1, label: '목표 미달' };
}

export function alertIndexExercise(value: number, goal: number) {
  if (value > goal) {
    return 3;
  }
  return 1;
}

export const alert: Ialert = {
  [categoryType.blood]: alertIndexBlood,
  [`${categoryType.blood}_sbp`]: alertIndexBloodSbp,
  [`${categoryType.blood}_dbp`]: alertIndexBloodDbp,
  [categoryType.sugar]: alertIndexSugar,
  [`${categoryType.sugar}_beforeEmptyBs`]: alertIndexSugarBefore,
  [`${categoryType.sugar}_afterBs`]: alertIndexSugarAfter,
  [categoryType.meal]: alertIndexMeal,
  [`${categoryType.meal}_nut`]: alertIndexMealNut,
  [categoryType.weight]: alertIndexWeight,
  [`${categoryType.weight}_bmi`]: alertIndexWeightBmi,
  [categoryType.step]: alertIndexStep,
  [categoryType.exercise]: alertIndexExercise,
};
