import { Circle, FactorItem, FactorUnit, Table } from './FactorsTable.styles';
import { IFactor } from './lib/types';

interface IFactorsTable {
  factors: IFactor[];
}

export default function FactorsTable({ factors }: IFactorsTable) {
  return (
    <Table>
      <thead>
        <tr>
          <th>건강위험요인</th>
          <th>현재</th>
          <th>목표</th>
        </tr>
      </thead>
      <tbody>
        {factors.map((factor) => {
          return (
            <tr key={factor.display_order}>
              <td>
                <FactorItem>
                  <Circle type={factor.factor_type} />
                  <div>
                    <p>{factor.title}</p>
                    <FactorUnit>
                      {factor.value_unit && `(${factor.value_unit})`}
                    </FactorUnit>
                  </div>
                </FactorItem>
              </td>
              <td>{factor.user_value}</td>
              <td>{factor.normal_value}</td>
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
}
