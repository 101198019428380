import styled from '@emotion/styled';
import { colors } from '../../components/Styles/theme';

export const Container = styled.div`
  width: calc(100% - 4rem);
  margin: 2rem;
`;

export const Title = styled.div`
  font-size: 2rem;
  font-weight: bold;
  color: ${colors.black_01_basic};
`;

export const CategoryStyle = {
  fontSize: '1.2rem',
  marginTop: '1rem',
  marginBottom: '4rem',
  padding: '0',
};

export const Description = styled.div`
  & > img {
    width: calc(100vw - 4rem);
    margin: 2rem 0;
  }

  font-size: 1.8rem;
  line-height: 170%;
  color: ${colors.grey_05_subtext};
`;
