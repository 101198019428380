import { useSelector } from 'react-redux';
import { RootState } from '../../app/store';
import Header from './Header';
import { Tag } from '../../components/Button';
import SlickSlider from './SlickSlider';
import { Container, Item, Empty } from './Reports.styles';
import { IReports } from './lib/types';
import {
  getFormattedDate,
  parseToDate,
  numberWithCommas,
} from '../../lib/utils';
import { useUser, useWeekList } from '../../hooks';

function Reports() {
  const { reports } = useSelector((state: RootState) => state.home);
  const { weeks } = useWeekList();
  const { user } = useUser();
  if (!user) return null;

  return (
    <Container>
      <Header
        {...(reports.length > 0
          ? { to: `/etc/calendar/06/${weeks[1]?.startDate.getTime()}` }
          : {})}
      >
        오늘의 주간리포트
      </Header>

      {reports.length > 0 ? (
        <SlickSlider opts={{ centerPadding: '16' }}>
          {reports.map((report: IReports) => {
            const {
              report_nm: reportName,
              report_ment: reportMent,
              start_date: startDate,
              end_date: endDate,
              first_value: firstValue,
              second_value: secondValue,
              unit,
            } = report;
            return (
              <Item>
                <div className="tags">
                  <Tag type="basic1">
                    {weeks.length > 0 && weeks[1].label}주차
                  </Tag>
                  <Tag type="basic5">{reportName}</Tag>
                  <span className="date">
                    {getFormattedDate(parseToDate(startDate), 'MM월 dd일')} -{' '}
                    {getFormattedDate(parseToDate(endDate), 'MM월 dd일')}
                  </span>
                </div>
                <dl>
                  <dt>{reportMent}</dt>
                  <dd>
                    <span className="val">
                      <strong>{numberWithCommas(firstValue)}</strong>
                      {!(reportName === '혈압' || reportName === '체중') &&
                        ` / ${numberWithCommas(secondValue)}`}
                      <span className="unit">{unit}</span>
                    </span>
                  </dd>
                </dl>
              </Item>
            );
          })}
        </SlickSlider>
      ) : (
        // 0주차일 때 리포트 발행되지 않음
        <Empty>도착한 주간리포트가 없습니다.</Empty>
      )}
    </Container>
  );
}

export default Reports;
