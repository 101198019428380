import Chart from './Chart';
import Records from './Records';
import { Container } from './Index.styles';

function Graph() {
  return (
    <Container>
      <Chart />
      <Records />
    </Container>
  );
}

export default Graph;
