import { Modal } from '../../components/Modal';

interface IQuestionnairesModal {
  leftButtonHandler: () => void;
  rightButtonHandler: () => void;
  isOpen: boolean;
}

export default function ReconnectionModal({
  isOpen,
  leftButtonHandler,
  rightButtonHandler,
}: IQuestionnairesModal) {
  return (
    <div>
      <Modal
        isOpen={isOpen}
        leftButtonTitle="아니오"
        rightButtonTitle="예"
        leftButtonHandler={leftButtonHandler}
        rightButtonHandler={rightButtonHandler}
      >
        <div>
          설문을 완료해야 <br /> 앱을 사용할 수 있습니다.
          <br /> 설문을 이어서 진행하시겠습니까?
        </div>
      </Modal>
    </div>
  );
}
