import styled from '@emotion/styled';
import { colors } from '../../components/Styles/theme';

export const TabWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 1.9rem 2rem;
  background-color: ${colors.white_01_basic};
  font-size: 1.6rem;
  line-height: 1.38;
  color: ${colors.grey_05_subtext};
`;
