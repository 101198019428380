import styled from '@emotion/styled';
import { colors } from '../../components/Styles/theme';

export interface ItemType {
  isEmpty: boolean;
}
export const Container = styled.article`
  .slick-list {
    padding-left: 0 !important;

    .slick-track {
      margin-left: inherit;

      .slick-slide {
        padding: 0 0.4rem;
        &:first-of-type {
          padding-left: 1rem;
        }
      }
    }
  }
`;

interface IItem {
  level: number;
}
export const Item = styled.div<IItem>`
  border-radius: 1.6rem;
  padding: 2.2rem;
  margin: 1rem 0;
  height: 18rem;
  font-size: 1.6rem;
  color: ${colors.white_01_basic};

  background-color: ${({ level }) => colors[`contents_${level}`]};
`;

export const Empty = styled.p<{ src: string }>`
  margin: 1rem 1rem 2rem;
  font-size: 1.4rem;
  font-weight: bold;
  color: ${colors.black_01_basic};
  border-radius: 1.6rem;
  background: ${colors.primary_01_highlight} url(${(props) => props.src})
    no-repeat center 2rem;
  text-align: center;
  padding: 6rem 0 2rem;
`;
