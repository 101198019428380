import { Link } from 'react-router-dom';
import { IWeekInfo } from '../../report/calendar/lib/types';
import { ReactComponent as Next } from '../../../assets/img/icon-next-icon.svg';
import { ItemLabel, ItemRange, ItemWrapper } from './reportItem.styles';
import { toStringByFormatting } from './lib/utils';
import { useUser } from '../../../hooks';
import { categoryToNumber } from '../../../lib/utils';

function ReportItem({ info }: { info: IWeekInfo }) {
  const { user, userDisease } = useUser();
  if (!user) return null;
  const category = userDisease.categories[0];
  const categoryNumber = categoryToNumber[category];
  return (
    <Link to={`/etc/calendar/${categoryNumber}/${info.startDate.getTime()}`}>
      <ItemWrapper>
        <ItemLabel>{info.label}주차</ItemLabel>
        <ItemRange>
          {toStringByFormatting(info.startDate, '/')} -{' '}
          {toStringByFormatting(info.endDate, '/')}
        </ItemRange>
        <Next />
      </ItemWrapper>
    </Link>
  );
}

export default ReportItem;
