import styled from '@emotion/styled';
import { colors } from '../../../components/Styles/theme';

export const TargetContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

export const UpdatedTime = styled.p`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 2rem;
  margin: 2.1rem 0 0;
  background-color: ${colors.grey_02_line};
  font-size: 1rem;
  color: ${colors.grey_05_subtext};
`;
