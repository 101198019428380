import styled from '@emotion/styled';
import { colors } from '../../components/Styles/theme';

export const Container = styled.article`
  margin-top: 2rem;
  .heading {
    margin: 0 2rem 1rem;

    h3 {
      font-size: 2.4rem;
      span {
        display: block;
        font-size: 1rem;
        color: ${colors.grey_05_subtext};
      }
    }
  }
`;
