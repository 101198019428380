interface IIcon {
  color?: string;
}

function Icon({ color }: IIcon) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="24"
      viewBox="0 0 25 24"
    >
      <g fill="none" fillRule="evenodd" transform="translate(.5)">
        <path fill="#FFF" d="M0 0h24v24H0z" />
        <path
          fill={color}
          d="M4.563 10.375v7.799c0 .503.348.949.84 1.053 2.176.462 4.428.71 6.74.71 2.207 0 4.36-.227 6.444-.649.498-.1.85-.547.85-1.054v-7.859L12.11 4l-7.546 6.375z"
        />
        <rect width="4.25" height="7" x="9.875" y="14.625" fill="#FFF" rx="2" />
        <path
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
          d="M3 12l9-8 9 8"
        />
      </g>
    </svg>
  );
}

export default Icon;
