import { useState, useEffect, ReactNode } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { BottomSheet } from 'src/components/Modal';
import { RootState } from '../../app/store';
import useGetCategory from './lib/useGetCategory';
import { useBottomSheet, useWeekList } from '../../hooks';
import { IWeekInfo } from '../../hooks/useWeekList';
import WeekPagination from './WeekPagination';
import FixedBottom from '../../components/Contents/FixedBottom';
import { Button } from '../../components/Button';
import Graph from './Graph';
import List from './List';
import { setCategoryNumber, setLogs } from './lifeLogSlice';
import {
  getFormattedDate,
  callFinish,
  ocareCat,
  ocareJson,
  categoryType,
  categoryToNumber,
} from '../../lib/utils';
import { BottomSheetItem, Container } from './Index.styles';
import { mealType } from '../home/lib/utils';

function LifeLog() {
  const dispatch = useDispatch();
  const { type, weekLabel } = useSelector((state: RootState) => state.lifeLogs);
  const categories = useGetCategory();
  const { categoryNumber } = useParams<{ categoryNumber: string }>();
  const { weeks } = useWeekList();
  const [currentWeek, setCurrentWeek] = useState<IWeekInfo>({
    label: 0,
    startDate: weeks?.[0]?.startDate || new Date(),
    endDate: weeks?.[0]?.endDate || new Date(),
  });
  const { visible, open, close } = useBottomSheet();

  const { isPreviousData, data } =
    currentWeek &&
    categoryNumber &&
    categories[categoryNumber].useGet(
      getFormattedDate(currentWeek?.endDate, 'yyyyMMdd'),
      getFormattedDate(currentWeek?.startDate, 'yyyyMMdd'),
    );
  const render: { [key: string]: ReactNode } = {
    list: <List />,
    graph: <Graph />,
  };
  useEffect(() => {
    const current = weeks.find((week) => week.label === weekLabel);
    if (current) setCurrentWeek(current);
  }, [weeks, weekLabel]);

  useEffect(() => {
    if (!categoryNumber) return;
    dispatch(
      setCategoryNumber({
        categoryNumber,
      }),
    );
  }, [dispatch, categoryNumber]);

  useEffect(() => {
    if (data) {
      dispatch(
        setLogs({
          isPreviousData,
          data,
          currentWeek,
        }),
      );
    }
  }, [dispatch, currentWeek, data, isPreviousData]);

  const isMeal = (logName: string) => {
    if (logName === '식사') open();
    else {
      callFinish(
        ocareJson({
          cat_code: logName,
          params: {
            page: 'logs',
            categoryNumber: categoryToNumber[logName],
          },
        }),
      );
    }
  };

  const goToOcare: any = (mealCode: string) => {
    callFinish(
      ocareJson({
        cat_code: '식사',
        cat_code_detail: mealCode,
        params: {
          page: 'logs',
          categoryNumber: categoryToNumber['식사'],
        },
      }),
    );
  };

  if (!categoryNumber) return null;
  const fixedNode =
    categoryNumber === categoryType.step ? null : (
      <Button
        type="rectangle"
        onClick={() => isMeal(ocareCat[categories[categoryNumber].label])}
      >
        {categories[categoryNumber].label} 입력
      </Button>
    );

  return data ? (
    <FixedBottom fixedNode={fixedNode}>
      <Container>
        <WeekPagination />
        {render[type]}
        <BottomSheet visible={visible} close={close}>
          {mealType.map((item) => (
            <BottomSheetItem
              key={item.name}
              onClick={() => goToOcare(item.detailCode)}
            >
              {item.name}
            </BottomSheetItem>
          ))}
        </BottomSheet>
      </Container>
    </FixedBottom>
  ) : null;
}

export default LifeLog;
