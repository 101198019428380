import circleBlue from '../../../../assets/img/icon-calendar-circle-blue.svg';
import circleGreen from '../../../../assets/img/icon-calendar-circle-green.svg';
import circlePrimary from '../../../../assets/img/icon-calendar-circle-primary.svg';
import circleRed from '../../../../assets/img/icon-calendar-circle-red.svg';
import rectangle from '../../../../assets/img/icon-calendar-rectangle.svg';
import triUp from '../../../../assets/img/icon-calendar-up.svg';
import triDown from '../../../../assets/img/icon-calendar-down.svg';
import { ICalendarDates, ICategoryItem, IWeekInfo } from './types';

export const categoryObj: ICategoryItem = {
  혈압: {
    legend: [
      {
        id: 1,
        marker: 'rectangle',
        content: '횟수',
        objKey: 'cnt',
        unit: '회',
      },
      { id: 2, marker: 'triUp', content: '최고 혈압', objKey: 'max_bp' },
      { id: 3, marker: 'triDown', content: '최저 혈압', objKey: 'min_bp' },
    ],
    statisticLabel: '최고 혈압',
    unit: 'mmHg',
    inputLabel: '혈압입력',
    inputType: 'gap',
    left: 'sbp',
    right: 'dbp',
  },
  체중: {
    legend: [
      { id: 4, marker: 'rectangle', content: 'kg', objKey: 'recent_weight' },
    ],
    statisticLabel: '최근 체중',
    unit: 'kg',
    inputLabel: '체중입력',
    inputType: 'current',
    current: 'recent_weight',
    goal: 'goal',
  },
  식사: {
    legend: [
      { id: 5, marker: 'rectangle', content: 'kcal', objKey: 'total_calories' },
      { id: 6, marker: 'circlePrimary', content: '아침', objKey: 'breakfast' },
      { id: 7, marker: 'circleBlue', content: '점심', objKey: 'lunch' },
      { id: 8, marker: 'circleGreen', content: '저녁', objKey: 'dinner' },
      { id: 9, marker: 'circleRed', content: '간식', objKey: 'snack' },
    ],
    statisticLabel: '평균 섭취 칼로리',
    unit: 'kcal',
    inputLabel: '식사입력',
    inputType: 'current',
    current: 'calories',
    goal: 'goal',
  },
  걸음: {
    legend: [
      { id: 10, marker: 'rectangle', content: '걸음', objKey: 'step_count' },
    ],
    statisticLabel: '평균 걸음 수',
    unit: '걸음',
    inputLabel: '걸음입력',
    inputType: 'current',
    current: 'step_count',
    goal: 'goal',
  },
  운동: {
    legend: [
      { id: 11, marker: 'rectangle', content: 'kcal', objKey: 'calories_out' },
      {
        id: 12,
        marker: 'circleBlue',
        content: '총 운동시간',
        objKey: 'duration',
        unit: '분',
      },
    ],
    statisticLabel: '평균 소모 칼로리',
    unit: 'kcal',
    inputLabel: '운동입력',
    inputType: 'current',
    current: 'calories_out',
    goal: 'goal',
  },
  혈당: {
    legend: [
      {
        id: 13,
        marker: 'rectangle',
        content: '횟수',
        objKey: 'cnt',
        unit: '회',
      },
      {
        id: 14,
        marker: 'circleBlue',
        content: '식전 최고',
        objKey: 'before_bs',
      },
      {
        id: 15,
        marker: 'circleGreen',
        content: '식후 최고',
        objKey: 'after_bs',
      },
    ],
    statisticLabel: '평균 식전/식후 혈당',
    unit: 'mg/dL',
    inputLabel: '혈당입력',
    inputType: 'gap',
    left: 'before_bs',
    right: 'after_bs',
  },
};

export const legendMarker: { [key: string]: string } = {
  rectangle,
  circleBlue,
  circleGreen,
  triUp,
  triDown,
  circlePrimary,
  circleRed,
};

export const calcRange = (nowDate: Date, week: IWeekInfo) => {
  if (week.label <= 0) return '';
  if (nowDate.getTime() === week.startDate.getTime()) return 'rangeStart range';
  if (nowDate.getTime() === week.endDate.getTime()) return 'rangeEnd range';
  if (nowDate >= week.startDate && nowDate <= week.endDate) return 'range';
  const removedBorderDate = new Date(
    week.startDate.getFullYear(),
    week.startDate.getMonth(),
    week.startDate.getDate() - 7,
  );
  if (nowDate >= removedBorderDate && nowDate <= week.startDate)
    return 'removeBorder';
  return '';
};

function getIsToday(today: Date, year: number, month: number, day: number) {
  if (
    today.getFullYear() === year &&
    today.getMonth() === month &&
    today.getDate() === day
  )
    return true;
  return false;
}

export function getCalendar(selectedDate: Date, category: string) {
  const prevLast = new Date(
    selectedDate.getFullYear(),
    selectedDate.getMonth(),
    0,
  );
  const thisLast = new Date(
    selectedDate.getFullYear(),
    selectedDate.getMonth() + 1,
    0,
  );
  const thisYear = selectedDate.getFullYear();
  const thisMonth = selectedDate.getMonth();
  const prevYear = prevLast.getFullYear();
  const prevMonth = prevLast.getMonth();
  const nextMonth = selectedDate.getMonth() + 1;
  const prevLastDate = prevLast.getDate();
  const prevLastDay = prevLast.getDay();
  const thisLastDate = thisLast.getDate();
  const thisLastDay = thisLast.getDay();

  const prevDates: ICalendarDates[] = [];
  const thisDates: ICalendarDates[] = [];
  const nextDates: ICalendarDates[] = [];

  const today = new Date();

  for (let i = 1; i <= thisLastDate; i += 1) {
    thisDates.push({
      date: new Date(thisYear, thisMonth, i),
      selectedMonth: true,
      isToday: getIsToday(today, thisYear, thisMonth, i),
      contents: categoryObj[category].legend.map((key) => {
        return {
          id:
            category + new Date(thisYear, thisMonth, i).getTime() + key.objKey,
          marker: key.marker,
        };
      }),
    });
  }

  if (prevLastDay !== 6) {
    for (let i = 0; i < prevLastDay + 1; i += 1) {
      prevDates.unshift({
        date: new Date(prevYear, prevMonth, prevLastDate - i),
        selectedMonth: false,
        isToday: getIsToday(today, prevYear, prevMonth, prevLastDate - i),
      });
    }
  }

  for (let i = 1; i < 7 - thisLastDay; i += 1) {
    nextDates.push({
      date: new Date(thisYear, nextMonth, i),
      selectedMonth: false,
      isToday: getIsToday(today, thisYear, nextMonth, i),
    });
  }

  return prevDates.concat(thisDates, nextDates);
}
