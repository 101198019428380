import styled from '@emotion/styled';
import { colors } from '../../../components/Styles/theme';
import { IGuideColor } from '../../../components/RiskColorGuide/lib/types';
import { FactorColors } from '../../../components/RiskColorGuide/lib/utils';

export const Table = styled.table`
  table-layout: fixed;
  width: 100%;
  text-align: center;
  border-bottom: 1px solid ${colors.grey_02_line};
  border-top: 1px solid ${colors.grey_04_inactive};
  th {
    color: ${colors.grey_05_subtext};
    font-size: 1.2rem;
    height: 3.6rem;
    border-bottom: 1px solid ${colors.grey_02_line};
  }

  td {
    padding: 0.8rem 0;
    height: 3.4rem;
    font-size: 1.2rem;
  }

  th:nth-of-type(1),
  td:nth-of-type(1) {
    text-align: left;
    width: 50%;
    padding-left: 1rem;
  }

  th:nth-of-type(2) {
    width: 20%;
  }
  th:nth-of-type(3) {
    width: 30%;
  }
`;

export const FactorItem = styled.div`
  font-weight: bold;
  display: flex;
  align-items: center;
  font-size: 1.2rem;
  color: ${colors.black_01_basic};
`;

export const FactorUnit = styled.p`
  font-size: 1rem;
  font-weight: normal;
  color: ${colors.grey_04_inactive};
`;

export const Circle = styled.div<IGuideColor>`
  width: 0.8rem;
  height: 0.8rem;
  background-color: ${(props) => {
    return FactorColors?.[props.type];
  }};
  border-radius: 50%;
  margin-right: 1rem;
`;
