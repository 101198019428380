import { useState } from 'react';

export default function useModalState(modalName: string) {
  const [modalOpen, setModalOpen] = useState(
    localStorage.getItem(modalName) !== 'hide',
  );
  function closeModal({ permanent }: { permanent: boolean }) {
    setModalOpen(false);
    if (permanent) localStorage.setItem(modalName, 'hide');
  }
  return { modalOpen, closeModal };
}
