import styled from '@emotion/styled';
import { colors } from '../../../components/Styles/theme';

export const WeekContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-around;
  font-size: 1rem;
  border-bottom: 0.1rem solid ${colors.grey_02_line};
  padding: 1rem 0;
`;
