import {
  Container,
  QnaList,
  QuestionDttm,
  Date as DateConatiner,
  Question,
} from '../HealthManager.styles';
import msgQna from '../../../assets/img/image-message-q-a.svg';
import QnAItem from './QnAItem';
import { Button, Tag } from '../../../components/Button';
import { IQna, usePostQnasRead } from '../../../services/useMsgs';
import { getFormattedDate, parseToDate, FORMAT } from '../../../lib/utils';

interface IQnAContents {
  noQnasData: boolean;
  qnas: IQna[];
}

export default function QnAContents({ noQnasData, qnas }: IQnAContents) {
  const { mutate } = usePostQnasRead();
  if (qnas === undefined || qnas === null) return null;

  const onClickQnAHandler = (id: number) => {
    const dttm = getFormattedDate(new Date(), FORMAT);
    mutate({ id, dttm });
  };

  return (
    <Container>
      {noQnasData ? (
        <div className="no-list">
          <img src={msgQna} alt="" />
          <span>질문&답변이 없습니다.</span>
        </div>
      ) : (
        <div className="list-container">
          {qnas.map((item) => {
            const { question_dttm: qustionDttm, answer_dttm: answerDttm } =
              item;
            return (
              <div
                key={item.id}
                onClick={() =>
                  answerDttm !== null ? onClickQnAHandler(item.id) : {}
                }
                aria-hidden="true"
              >
                <QnaList active={answerDttm !== null}>
                  <QuestionDttm>
                    {answerDttm ? (
                      <Tag type="basic3">답변완료</Tag>
                    ) : (
                      <Tag type="basic8">답변대기</Tag>
                    )}
                    <DateConatiner>
                      {getFormattedDate(
                        parseToDate(qustionDttm),
                        'MM월 dd일, a hh:mm',
                      )}
                    </DateConatiner>
                  </QuestionDttm>
                  <Question>
                    {item.question.split('<br>').map((str) => (
                      <span key={str}>
                        {str}
                        <br />
                      </span>
                    ))}
                  </Question>
                </QnaList>
                {answerDttm && <QnAItem {...item} />}
              </div>
            );
          })}
        </div>
      )}
      <Button type="fixed" tag="a" to="/qna/write">
        질문하기
      </Button>
    </Container>
  );
}
