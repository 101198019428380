import { useMutation, useQuery, useQueryClient } from 'react-query';
import { get, post } from '../lib/request';
import { IQuestions, IResponse } from '../features/questionnaires/lib/types';

// 공통 인터페이스

interface ISections {
  id: number;
  title: string;
  questions: IQuestions[];
}
interface IQuestionnaire {
  id: number;
  sections: ISections[];
}
export interface IQuestionnaires {
  questionnaire: IQuestionnaire;
}

// # 기초설문
export const useGetQuestionnaires = (id: number | string | undefined) => {
  return useQuery<IQuestionnaires, Error>(
    ['question', id],
    () => get(`/questionnaires/${id}`),
    {
      enabled: Boolean(id),
      onError: () => {
        console.log('local error');
      },
    },
  );
};

export interface IPostQuestionnaires {
  responses: IResponse[];
}

export const usePostQuestionnaires = (id: number) => {
  const queryClient = useQueryClient();
  return useMutation(
    (responses: IResponse[]) =>
      post(`/questionnaires/?questionnaire_id=${id}`, { responses }),
    {
      onSuccess: (val) => {
        console.log('onSuccess :: ', val);
        queryClient.invalidateQueries(['question']);
        queryClient.invalidateQueries(['user']);
      },
    },
  );
};
