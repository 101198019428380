import styled from '@emotion/styled';

interface IContainer {
  height: number | undefined;
}
interface IFixed {
  isMain: boolean;
}

export const Container = styled.div<IContainer>`
  padding-bottom: ${({ height }) => `${height}rem`};
`;

export const Fixed = styled.div<IFixed>`
  position: fixed;
  bottom: ${({ isMain }) => (isMain ? '6.6rem' : 0)};
  width: 100%;
`;
