import { numberWithCommas } from 'src/lib/utils';
import { Item, ResultContainer, Title } from './ResultCard.styles';

interface IResult {
  title: string;
  age: number;
  percent: number;
}

export default function Result({ title, age, percent }: IResult) {
  return (
    <ResultContainer>
      <Title>{title}</Title>
      <Item>
        <p>심뇌혈관 나이</p>
        <p>
          <b>{age}</b>세
        </p>
      </Item>
      <Item>
        <p>
          심뇌혈관 질환 발생 확률<span>(10년 이내)</span>
        </p>
        <p>
          <b>{numberWithCommas(percent)}</b>%
        </p>
      </Item>
    </ResultContainer>
  );
}
