import { css } from '@emotion/react';
import { colors } from './theme';

export const ToastStyles = css`
  .Toastify__toast-body {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;

    padding: 0px;
    width: 100%;
    font-weight: 400;

    & div {
      color: ${colors.white_01_basic};
      font-size: 1.6rem;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .Toastify__toast-theme--dark {
    background-color: ${colors.black_03_floating};
  }

  .Toastify__toast {
    width: 34rem;
    height: 4.8rem;
    border-radius: 12rem;
  }
  .Toastify__toast-container--bottom-center {
    margin-bottom: 1rem;
    display: flex;
    justify-content: center;
  }

  .Toastify__toast-container--top-center {
    display: flex;
    justify-content: center;
  }
`;
