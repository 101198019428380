import styled from '@emotion/styled';
import { colors } from '../../../components/Styles/theme';

export const ResultContainer = styled.div`
  padding: 0 0 1rem;
  border-radius: 1.6rem;
  border: solid 1px ${colors.grey_02_line};
  display: flex;
  flex-direction: column;
`;

export const Title = styled.p`
  color: ${colors.white_01_basic};
  font-size: 1.4rem;
  height: 3.8rem;
  background-color: ${colors.black_01_basic};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 1.6rem 1.6rem 0 0;
`;

export const Item = styled.div`
  margin: 0.5rem 2rem;
  height: 3rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  & > p:nth-of-type(1) {
    font-size: 1.6rem;
    color: ${colors.grey_05_subtext};
  }
  & > p:nth-of-type(2) {
    font-size: 1.2rem;
    color: ${colors.black_01_basic};
  }
  b {
    font-size: 1.8rem;
    margin-right: 0.5rem;
  }
  span {
    margin-left: 0.3rem;
    font-size: 1rem;
    color: ${colors.grey_05_subtext};
  }
`;
