import { useSelector } from 'react-redux';
import { RootState } from 'src/app/store';
import {
  ProcessText,
  SubText,
  SwipeContainer,
  WeekCircle,
  WeekContainer,
} from './WeekSwiper.styles';
import SlickSlider from './SlickSlider';

interface IWeek {
  week: number;
}

function WeekSwiper({ week }: IWeek) {
  const displayWeek = useSelector(
    (state: RootState) => state.healthInfo.swipeWeek,
  );
  return (
    <SwipeContainer>
      <WeekContainer>
        <WeekCircle />
        <SlickSlider week={week} />
      </WeekContainer>
      <SubText>주차</SubText>
      {displayWeek <= week ? (
        <ProcessText isLock>진행중</ProcessText>
      ) : (
        <ProcessText isLock={false}>잠김</ProcessText>
      )}
    </SwipeContainer>
  );
}

export default WeekSwiper;
