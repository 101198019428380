import styled from '@emotion/styled';
import { colors, classInterval } from '../../components/Styles/theme';

export interface ItemType {
  isEmpty: boolean;
}

export const Container = styled.article<ItemType>`
  box-shadow: ${({ isEmpty }) =>
    isEmpty ? 'none' : '0 0.2rem 1.2rem 0 rgba(0, 0, 0, 0.15)'};
  background-color: ${({ isEmpty }) =>
    isEmpty ? colors.grey_01_bg : colors.white_01_basic};
  padding: ${({ isEmpty }) => (isEmpty ? '2rem 1rem' : '2rem')};
  margin: 1rem 1rem 2rem;
  border-radius: 1.6rem;
  font-size: 1.4rem;
  font-weight: bold;

  .date {
    font-size: 1rem;
    color: ${colors.grey_05_subtext};
  }

  .alert {
    margin-top: 2rem;
    &:first-of-type {
      margin-top: 0;
    }
  }

  dl {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 0.5rem;

    dt {
      font-size: 1.8rem;
      font-weight: bold;
    }
    dd {
      span {
        display: inline-block;
        vertical-align: middle;
      }

      .value {
        min-width: 6.5rem;
        text-align: right;
        font-size: 1.8rem;
        font-weight: bold;
      }
    }
  }

  .message {
    font-size: 1.4rem;
    font-weight: normal;

    strong {
      display: block;
      font-weight: bold;
      color: ${colors.sub_red_03_basic};
    }
  }

  .empty {
    padding: 0 1rem;
  }
`;

export const Bar = styled.ul`
  position: relative;
  margin: 1rem 0;
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  flex-wrap: nowrap;
`;

export interface BarItemType {
  type: string;
  index: number | undefined;
  isActive: boolean;
}
export const BarItem = styled.li<BarItemType>`
  width: 100%;
  text-align: center;
  position: relative;

  .point {
    position: absolute;
    top: -0.2rem;
    left: 50%;
    transform: translateX(-50%);
    width: 1.2rem;
    height: 1.2rem;
    border-radius: 100%;
    border: 1px solid ${colors.white_01_basic};
    background-color: ${({ type, index }) =>
      index ? classInterval[type][index] : colors.grey_02_line};
  }

  &:first-of-type:before {
    border-top-left-radius: 0.4rem;
    border-bottom-left-radius: 0.4rem;
  }

  &:last-of-type:before {
    border-top-right-radius: 0.4rem;
    border-bottom-right-radius: 0.4rem;
  }

  &:before {
    content: '';
    display: block;
    height: 0.8rem;
    background-color: ${({ type, isActive, index }) =>
      isActive && index ? classInterval[type][index] : colors.grey_02_line};
    margin-left: 0.1rem;
    margin-bottom: 0.3rem;
  }

  .desc {
    display: block;
    font-size: 0.9rem;
    font-weight: normal;
    color: ${colors.grey_04_inactive};
    white-space: pre-wrap;
  }
`;
