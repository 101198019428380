import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../app/store';
import { useWeekList } from '../../hooks';
import { getFormattedDate } from '../../lib/utils';
import { Pagination } from '../../components/Contents';
import { setWeekLabel } from './lifeLogSlice';

function WeekPagination() {
  const [isRangeWeek, setIsRangeWeek] = useState({ first: false, last: false });
  const { weekLabel } = useSelector((state: RootState) => state.lifeLogs);

  const dispatch = useDispatch();
  const { weeks } = useWeekList();
  const currentWeek = weeks.find((week) => week.label === weekLabel);

  const weekHandler = (direction: number) => {
    dispatch(
      setWeekLabel({
        weekLabel: weekLabel + direction,
      }),
    );
  };

  useEffect(() => {
    setIsRangeWeek({
      first: weekLabel === 0,
      last: weekLabel >= weeks.length - 1,
    });
  }, [weeks, weekLabel]);

  return currentWeek ? (
    <Pagination
      prevHandler={() => weekHandler(-1)}
      nextHandler={() => weekHandler(1)}
      prevDisabled={isRangeWeek.first}
      nextDisabled={isRangeWeek.last}
    >
      <h3>
        {weekLabel}주차
        <span>
          {getFormattedDate(currentWeek?.startDate, 'MM.dd')} -{' '}
          {getFormattedDate(currentWeek?.endDate, 'MM.dd')}
        </span>
      </h3>
    </Pagination>
  ) : null;
}

export default WeekPagination;
