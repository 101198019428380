import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
  usePostAdviceMsgs,
  useGetAdviceMsgsDetail,
} from '../../../services/useMsgs';
import { Tag } from '../../../components/Button';
import Like from '../Like';
import { getFormattedDate, parseToDate } from '../../../lib/utils';
import {
  MessageDetailContainer,
  MessageDetailTitle,
  MessageDetailContent,
  AttachedFileContainer,
  AttachedFile,
} from '../HealthManager.styles';
import HealthManagerHeader from '../HealthManagerHeader';

interface IDetail {
  id: number;
}

export default function AdviceMsgsDetail() {
  const { id } = useParams<keyof IDetail>();
  const [isLoaded, setIsLoaded] = useState(false);
  const { data: adviceMsgsData } = useGetAdviceMsgsDetail(Number(id));
  const {
    created_at: createdAt,
    title,
    category,
    message,
    liked,
    is_read: isRead,
    images,
  } = adviceMsgsData?.message || {};

  const { mutate: adviceMsgsMutate } = usePostAdviceMsgs(Number(id));
  useEffect(() => {
    setIsLoaded(true);
    if (isLoaded && typeof liked !== 'undefined' && !isRead) {
      adviceMsgsMutate({
        check_dttm: true,
        liked,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoaded, liked]);
  if (message === undefined || message === null) return null;

  return (
    <MessageDetailContainer>
      <HealthManagerHeader />
      <MessageDetailTitle>
        <div className="flex-row">
          <div className="create-at">
            {createdAt &&
              getFormattedDate(parseToDate(createdAt), 'MM월 dd일, a hh:mm')}
          </div>
          <Like id={Number(id)} liked={liked!} isDetail />
        </div>
        <div className="title">{title}</div>
        <Tag type="basic5">{category}</Tag>
      </MessageDetailTitle>
      <MessageDetailContent dangerouslySetInnerHTML={{ __html: message }} />
      {images && (
        <AttachedFileContainer>
          <AttachedFile>
            {images.map((img) => {
              const { url } = img;
              return <img src={url} alt="" />;
            })}
          </AttachedFile>
        </AttachedFileContainer>
      )}
    </MessageDetailContainer>
  );
}
