import { ReactComponent as ReportUp } from '../../../assets/img/icon-report-up.svg';
import { ReactComponent as ReportDown } from '../../../assets/img/icon-report-down.svg';
import Tag from '../../../components/Button/Tag';
import { PrevWeekWrapper } from './PrevInput.styles';

interface IPrevInput {
  cnt: number;
  empty: boolean;
}

const makeMarker = (cnt: number) => {
  if (cnt > 0) {
    return <ReportUp />;
  }
  if (cnt < 0) {
    return <ReportDown />;
  }
  return null;
};

export default function PrevInput({ cnt, empty }: IPrevInput) {
  return (
    <Tag type={empty || !cnt ? 'basic8' : 'basic5'}>
      <PrevWeekWrapper>
        <span>전주대비</span>
        <span>{empty || !cnt ? '-' : Math.abs(cnt)}회</span>
        {empty || !cnt ? null : makeMarker(cnt)}
      </PrevWeekWrapper>
    </Tag>
  );
}
