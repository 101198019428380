import { useEffect, useState, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ScriptableContext,
} from 'chart.js';
import { setIndex } from '../lifeLogSlice';
import { RootState } from '../../../app/store';
import {
  getFormattedDate,
  parseToDate,
  categoryType,
} from '../../../lib/utils';
import useGetCategory from '../lib/useGetCategory';
import { alert } from '../lib/alert';
import { Container, Empty, Unit } from './Chart.styles';
import { colors, classInterval } from '../../../components/Styles/theme';
import { ILog } from '../lib/types';

const BeforeDraw = {
  id: 'beforeDraw',
  beforeDraw: (chart: any, args: any, opts: any) => {
    const { color, categoryNumber, chartMax, max, min } = opts;

    const {
      ctx,
      chartArea: { left, top, bottom, width, height },
    } = chart;

    ctx.fillStyle = color;

    const ratio = height / chartMax;

    if (
      categoryNumber === categoryType.blood ||
      categoryNumber === categoryType.sugar
    ) {
      ctx.fillRect(left, top, width, ratio * (chartMax - max));
      ctx.fillRect(left, bottom - ratio * min, width, ratio * min);
    }

    if (
      categoryNumber === categoryType.meal ||
      categoryNumber === categoryType.weight
    ) {
      ctx.fillRect(left, top, width, ratio * (chartMax - max));
    }

    if (
      categoryNumber === categoryType.step ||
      categoryNumber === categoryType.exercise
    ) {
      ctx.fillRect(left, bottom - ratio * max, width, ratio * max);
    }
  },
};

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
  BeforeDraw,
);

ChartJS.defaults.font.family = 'Noto Sans KR';
ChartJS.defaults.font.size = 10;

interface IChart {
  isHome?: boolean;
}
function Chart({ isHome = false }: IChart) {
  const [data, setData] = useState({});

  const categories = useGetCategory();
  const dispatch = useDispatch();
  const { categoryNumber, logsGraph, homeLogsGraph, selectedIndex, avgWeight } =
    useSelector((state: RootState) => state.lifeLogs);

  const logsData = isHome ? homeLogsGraph : logsGraph;
  const category = categories[categoryNumber];

  const CHART_MAX =
    categoryNumber === categoryType.weight
      ? avgWeight && avgWeight + 10
      : category && category.chart.max;
  const CHART_MIN =
    categoryNumber === categoryType.weight
      ? avgWeight && avgWeight - 10
      : category && category.chart.min;

  const filteredData = logsData.filter((log) =>
    Object.prototype.hasOwnProperty.call(log, 'id'),
  );
  const isEmpty = !filteredData.length;

  const options = {
    responsive: true,
    plugins: {
      beforeDraw: {
        color: `${colors.grey_01_bg}`,
        categoryNumber,
        chartMax: CHART_MAX,
        chartMin: CHART_MIN,
        max: category && category.goalMax,
        min: category && category.goalMin,
      },
      legend: {
        display:
          categoryNumber === categoryType.blood ||
          categoryNumber === categoryType.sugar,
        position: 'top' as const,
        align: 'end' as const,
        labels: {
          color: `${colors.grey_05_subtext}`,
          generateLabels(chart: any) {
            const { datasets } = chart.data;
            return datasets.map((item: any) => ({
              text: `${item.label}`,
              fillStyle: '#000',
              pointStyle: `${item.pointStyle}`,
            }));
          },
          usePointStyle: true,
          pointStyleWidth: 15,
        },
        onClick: null,
      },
    },
    scales: {
      x: {
        grid: {
          color: (context: any) =>
            context.index === selectedIndex
              ? `${colors.black_01_basic}`
              : `${colors.grey_02_line}`,
          drawTicks: false,
          offset: false,
        },
        ticks: {
          display: true,
          autoSkip: false,
          color: (context: any) => {
            if (context.index === selectedIndex)
              return `${colors.black_01_basic}`;
            return `${colors.grey_04_inactive}`;
          },
          padding: 15,
        },
      },
      y: {
        max: CHART_MAX,
        min: CHART_MIN,
        grid: {
          display: false,
          borderWidth: 0,
        },
        ticks: {
          padding: 10,
          color: `${colors.grey_05_subtext}`,
        },
        title: {
          display: false,
        },
      },
    },
    onClick: (e: any, item: any) => {
      if (item[0]) {
        const { index: currIndex } = item[0];
        dispatch(setIndex(currIndex));
      }
    },
  };
  interface IyTarget {
    [key: string]: {
      label: string;
      key: string;
      backgroundColor: Function;
      pointStyle?: string;
    }[];
  }

  const yTarget: IyTarget = useMemo(() => {
    return {
      [categoryType.blood]: [
        {
          label: '수축기 혈압',
          key: 'sbp',
          backgroundColor: (context: ScriptableContext<'line'>) => {
            const alertIndex = alert[`${categoryType.blood}_sbp`](context.raw);
            return `${classInterval[categoryType.blood][alertIndex]}`;
          },
        },
        {
          label: '이완기 혈압',
          key: 'dbp',
          backgroundColor: (context: ScriptableContext<'line'>) => {
            const alertIndex = alert[`${categoryType.blood}_dbp`](context.raw);
            return `${classInterval[categoryType.blood][alertIndex]}`;
          },
        },
      ],
      [categoryType.sugar]: [
        {
          label: '식전/공복 혈당',
          key: 'beforeEmptyBs',
          backgroundColor: (context: ScriptableContext<'line'>) => {
            const alertIndex = alert[`${categoryType.sugar}_beforeEmptyBs`](
              context.raw,
            );
            return `${classInterval[categoryType.sugar][alertIndex]}`;
          },
        },
        {
          label: '식후 혈당',
          key: 'afterBs',
          backgroundColor: (context: ScriptableContext<'line'>) => {
            const alertIndex = alert[`${categoryType.sugar}_afterBs`](
              context.raw,
            );
            return `${classInterval[categoryType.sugar][alertIndex]}`;
          },
        },
      ],
      [categoryType.meal]: [
        {
          label: '칼로리',
          key: 'total_calories',
          backgroundColor: (context: any, goal: number) => {
            // backgroundColor: (context: ScriptableContext<'bar'>) => {
            if (context.raw > goal) {
              return `${categoryNumber && classInterval[categoryNumber][3]}`;
            }
            return `${colors.black_01_basic}`;
          },
        },
      ],
      [categoryType.weight]: [
        {
          label: '체중',
          key: 'weight',
          backgroundColor: (context: ScriptableContext<'line'>) => {
            const alertIndex = alert[`${categoryType.weight}_bmi`](context.raw);
            return `${classInterval[categoryType.weight][alertIndex]}`;
          },
        },
      ],
      [categoryType.step]: [
        {
          label: '걸음수',
          key: 'step_count',
          backgroundColor: (context: any, goal: number) => {
            // backgroundColor: (context: ScriptableContext<'bar'>) => {
            if (context.raw > goal) {
              return `${categoryNumber && classInterval[categoryNumber][3]}`;
            }
            return `${colors.black_01_basic}`;
          },
        },
      ],
      [categoryType.exercise]: [
        {
          label: '소모열량',
          key: 'calories_out',
          backgroundColor: (context: any, goal: number) => {
            // backgroundColor: (context: ScriptableContext<'bar'>) => {
            if (context.raw > goal) {
              return `${categoryNumber && classInterval[categoryNumber][3]}`;
            }
            return `${colors.black_01_basic}`;
          },
        },
      ],
    };
  }, [categoryNumber]);

  const ChartType = category && category.chart.element;

  useEffect(() => {
    const dataFunc = () => {
      const datasets = yTarget[categoryNumber].map((set, idx) => {
        const dataset = {
          label: set.label,
          data: logsData.map((log: ILog) => log[set.key]),
          backgroundColor: (context: ScriptableContext<'line'>) =>
            set.backgroundColor(context, category && category.goalMax),
        };

        if (
          categoryNumber === categoryType.blood ||
          categoryNumber === categoryType.sugar
        ) {
          return {
            ...dataset,
            borderWidth: 1,
            pointRadius: 5,
            pointStyle: idx === 0 ? 'rectRot' : 'circle',
          };
        }

        if (categoryNumber === categoryType.weight) {
          return {
            ...dataset,
            borderWidth: 1,
            pointRadius: 5,
            pointStyle: 'circle',
          };
        }

        if (
          categoryNumber === categoryType.meal ||
          categoryNumber === categoryType.step ||
          categoryNumber === categoryType.exercise
        ) {
          return {
            ...dataset,
            barPercentage: 0.2,
          };
        }

        return {
          ...dataset,
        };
      });

      return {
        datasets: !isEmpty ? datasets : [],
        labels: logsData.map((log) => [
          getFormattedDate(parseToDate(log.log_dttm), 'MM-dd'),
          getFormattedDate(parseToDate(log.log_dttm), 'E'),
        ]),
      };
    };

    if (categoryNumber) setData(dataFunc());
  }, [isEmpty, logsData, categoryNumber, category, yTarget]);

  return Object.keys(data).length > 0 ? (
    <Container>
      <ChartType options={options} data={data} />
      {isEmpty && (
        <Empty>
          이번 주 기록이 없습니다. <br />
          기록만 해도 수치 관리에 도움이 됩니다.
        </Empty>
      )}
      <Unit
        isLegend={
          categoryNumber === categoryType.blood ||
          categoryNumber === categoryType.sugar
        }
        isEmpty={isEmpty}
      >
        ({category && category.unit[0]})
      </Unit>
    </Container>
  ) : null;
}

export default Chart;
