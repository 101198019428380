import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Container } from './SlickSlider.styles';

interface ISlickSlider {
  children: any;
  opts: { centerPadding: string };
}

const settings = {
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  centerMode: true,
  arrows: false,
};
function SlickSlider({ children, opts }: ISlickSlider) {
  const setting = { ...settings, ...opts };
  return (
    <Container>
      <Slider {...setting}>{children}</Slider>
    </Container>
  );
}

export default SlickSlider;
