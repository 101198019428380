import { Dispatch, SetStateAction } from 'react';
import { Button } from '../../../components/Button';
import { Item } from './CategoryItem.styles';

interface ICategoryItem {
  name: string;
  active: boolean;
  setCategory: Dispatch<SetStateAction<string>>;
}

function CategoryItem({ name, active, setCategory }: ICategoryItem) {
  const clickCategory = (clickedItem: string) => {
    setCategory(clickedItem);
  };
  return (
    <Item>
      <Button
        onClick={() => clickCategory(name)}
        type="category"
        size="small"
        className={active ? 'active' : ''}
      >
        {name}
      </Button>
    </Item>
  );
}

export default CategoryItem;
