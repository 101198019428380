import styled from '@emotion/styled';
import { colors } from '../../../components/Styles/theme';
import { ILock } from '../lib/types';

export const SwipeContainer = styled.div`
  width: 100vw;
  margin-bottom: 3.95rem;
  margin-top: 4.95rem;
`;

export const WeekContainer = styled.div`
  width: 100%;
  position: relative;
`;

export const WeekCircle = styled.div`
  width: 8rem;
  height: 8rem;
  box-shadow: 0 0.2rem 1.2rem 0 rgba(0, 0, 0, 0.15);
  border-radius: 50%;
  position: absolute;
  left: calc(50% - 4rem);
`;

export const SubText = styled.span`
  display: inline-block;
  width: 100%;
  height: 1.3rem;
  text-align: center;
  font-size: 1rem;
  font-weight: bold;
  color: ${colors.grey_05_subtext};
  margin-bottom: 2.5rem;
`;

export const ProcessText = styled.span<ILock>`
  display: inline-block;
  width: 100%;
  height: 2.2rem;
  text-align: center;
  font-size: 1.6rem;
  font-weight: bold;
  color: ${(props) =>
    props.isLock ? colors.primary_03_basic : colors.grey_04_inactive};
`;
