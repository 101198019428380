export const tabList = [
  {
    id: 1,
    name: '상담메시지',
    pathname: '/msgs',
  },
  {
    id: 2,
    name: '질문&답변',
    pathname: '/qna',
  },
];

export const sortList = [
  '전체보기',
  '건강정보',
  '생활습관미션',
  '동기부여',
  '위기관리',
  '간호지표',
  '영양지표',
  '웰컴 메시지',
  '좋아요',
];
