import { ReactComponent as ReportEmptyIMG } from '../../../assets/img/image-empty-report.svg';
import { Container } from './reportListEmpty.styles';

export default function ReportListEmpty() {
  return (
    <Container>
      <ReportEmptyIMG />
      <p>
        발행된 리포트가 없습니다.
        <br />
        1주일의 기록이 쌓이면 리포트가 발행됩니다.
      </p>
    </Container>
  );
}
