import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import _ from 'lodash';
import {
  Wrapper,
  HeaderStikyContainer,
  ListContainer,
} from './HealthManager.styles';
import QnAContents from './qna/QnAContents';
import magCounsel from '../../assets/img/image-message-counsel.svg';
import { useGetAdviceMsgs, useQnas } from '../../services/useMsgs';
import { tabList, sortList } from './constants';
import TabsContainer from './TabsContainer';
import AdviceMsgs from './adviceMsgs/AdviceMsgs';
import Filter from '../../components/Filter';
import HealthManagerHeader from './HealthManagerHeader';

export default function HealthManager() {
  const location = useLocation();
  const [tab, setTab] = useState<string>(location.pathname);
  const [adviceMsgsCategory, setAdviceMsgsCategory] =
    useState<string>('전체보기');
  const adviceMassegeList = tab === '/msgs';
  const { data: adviceMsgsData } = useGetAdviceMsgs(adviceMsgsCategory);
  const { data: qnaData } = useQnas(!adviceMassegeList);
  const adviceMsgs = _.sortBy(adviceMsgsData?.messages, 'created_at').reverse();
  const filterData = adviceMsgs.filter((item) => {
    return item.category === adviceMsgsCategory;
  });
  // # 상담메시지 정렬
  const sortingData =
    adviceMsgsCategory === '전체보기' ? adviceMsgs : filterData;
  // # 카테고리 소팅 / 좋아요 소팅
  const filterLikedData = adviceMsgsData?.messages.filter((item) => {
    return item.liked;
  });
  const list = adviceMsgsCategory === '좋아요' ? filterLikedData : sortingData;
  // # 질문 & 답변 리스트 정렬
  const filterQnaData = _.sortBy(qnaData?.qnas, 'question_dttm').reverse();
  const noMsgData = adviceMsgsData?.messages.length === 0;
  const noQnasData = qnaData?.qnas.length === 0;
  return (
    <Wrapper>
      <HealthManagerHeader />
      <HeaderStikyContainer>
        <TabsContainer tabList={tabList} tab={tab} setTab={setTab} />
        {adviceMassegeList && (
          <Filter
            filter={adviceMsgsCategory}
            filterList={sortList}
            setFilter={setAdviceMsgsCategory}
            margin="top"
          />
        )}
      </HeaderStikyContainer>
      <ListContainer isAdviceMessage={adviceMassegeList}>
        {adviceMassegeList ? (
          <div style={{ height: '100%' }}>
            {noMsgData ? (
              <div className="no-list">
                <img src={magCounsel} alt="" />
                코치에게 도착한 메시지가 없습니다.
              </div>
            ) : (
              list?.map((item) => <AdviceMsgs key={item.id} {...item} />)
            )}
          </div>
        ) : (
          <QnAContents noQnasData={noQnasData} qnas={filterQnaData} />
        )}
      </ListContainer>
    </Wrapper>
  );
}
