import styled from '@emotion/styled';
import { colors } from '../../components/Styles/theme';

export const Container = styled.article`
  h3 {
    font-size: 1.4rem;
    color: ${colors.black_01_basic};
    text-align: center;
    padding: 0.4rem 2rem;

    span {
      display: block;
      font-size: 1rem;
      color: ${colors.grey_05_subtext};
    }
  }
`;

export const BottomSheetItem = styled.div`
  width: 100%;
  height: 5.6rem;
  line-height: 5.6rem;
  text-align: center;
  color: ${colors.black_01_basic};
  font-size: 1.6rem;
  background-color: ${colors.white_01_basic};

  &:hover {
    background-color: ${colors.grey_01_bg};
  }
`;
