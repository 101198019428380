import { useState } from 'react';
import { useNavigate } from 'react-router';
import { usePostQnas } from '../../../services/useMsgs';
import { TextArea, FixedFuncButton } from '../HealthManager.styles';

export default function Write() {
  const [question, setQuestion] = useState<string>('');
  const [disabled, setDisabled] = useState(true);
  const navigate = useNavigate();
  const handleComplete = () => {
    navigate('/qna');
    setDisabled(true);
  };
  const removeSpace = question
    .split(' ')
    .filter((n) => n)
    .join(' ')
    .replace(/\n/g, '<br>');

  const { mutate: postQnasMutate } = usePostQnas(
    handleComplete,
    encodeURIComponent(removeSpace),
  );
  const handleWriteButton = () => {
    setDisabled(true);
    if (!removeSpace.length || disabled) return;
    postQnasMutate();
  };

  return (
    <>
      <TextArea
        placeholder="질문을 입력해주세요."
        name="question"
        id="question"
        defaultValue={question}
        onChange={(e) => {
          if (e.target.value.length > 0) {
            setQuestion(e.target.value);
            setDisabled(false);
          } else {
            setDisabled(true);
          }
        }}
      />
      <FixedFuncButton
        type="rectangle"
        disabled={disabled}
        onClick={() => {
          handleWriteButton();
        }}
      >
        완료
      </FixedFuncButton>
    </>
  );
}
