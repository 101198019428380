import React, {
  useState,
  useRef,
  useEffect,
  useCallback,
  Dispatch,
  SetStateAction,
} from 'react';

function toTwoDigitString(number: number) {
  if (number < 10) {
    return `0${number}`;
  }
  return `${number}`;
}

interface IDatePickerColumn {
  // eslint-disable-next-line no-unused-vars
  onChange: (num: number) => void;
  cellHeight?: number;
  value: number;
  minValue: number;
  maxValue: number;
  reset?: boolean;
  setReset?: Dispatch<SetStateAction<boolean>>;
  resetNum?: number;
}

function DatePickerColumn({
  onChange,
  minValue,
  maxValue,
  cellHeight = 51,
  value,
  reset = false,
  setReset,
  resetNum,
}: IDatePickerColumn) {
  const numCells = maxValue - minValue + 1; // 숫자 개수
  const [offsetState, setOffsetState] = useState(() => {
    const numbers: Array<null | number> = [null];
    for (let i = 0; i < numCells; i += 1) {
      numbers.push(minValue + i);
    }
    const initialIdx = numbers.findIndex((item) => item === value);
    const initialOffset = -1 * (initialIdx - 1) * cellHeight;
    return {
      offset: initialOffset,
      cellContents: numbers,
    };
  });

  const [lastClientY, setLastClientY] = useState(undefined);
  const lastClientYRef = useRef(undefined);
  const offsetStateRef = useRef(offsetState);
  const slideyRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const [isMouseDown, setIsMouseDown] = useState(false);
  const isMouseDownRef = useRef(false);
  const [isTouchInProgress, setIsTouchInProgress] = useState(false);
  const isTouchInProgressRef = useRef(false);

  // ********* EVENT HANDLERS ********* //

  const startHandler = (e: any) => {
    // e.preventDefault();
    if (e.touches) {
      setIsTouchInProgress(true);
    }
    setLastClientY(e.touches ? e.touches[0].clientY : e.clientY);
  };

  const moveHandler = useCallback(
    (e: any) => {
      // e.preventDefault();
      if (!e.touches) return;
      const position = e.touches[0].clientY;
      setOffsetState((prevOffsetState) => {
        if (!lastClientYRef.current) return prevOffsetState;
        const newOffset =
          prevOffsetState.offset + position - lastClientYRef.current;
        if (newOffset > cellHeight / 2) return prevOffsetState; // 0번쨰 인덱스보다 위로가는거 방지
        const lastOffset = -1 * cellHeight * (numCells - 1);
        if (newOffset < lastOffset - cellHeight / 2) return prevOffsetState; // 마지막 선택지보다 아래로 가는거 방지
        return {
          ...prevOffsetState,
          offset: prevOffsetState.offset + position - lastClientYRef.current,
        };
      });
      setLastClientY(position);
    },
    [cellHeight, numCells],
  );

  const endHandler = useCallback((e: any) => {
    e.preventDefault();
    setIsTouchInProgress(false);
  }, []);

  const mouseDownHandler = useCallback((e: any) => {
    startHandler(e);
    setIsMouseDown(true);
  }, []);

  const mouseMoveHandler = useCallback(
    (e: any) => {
      if (isMouseDownRef.current) {
        moveHandler(e);
      }
    },
    [moveHandler],
  );

  const mouseUpHandler = useCallback(
    (e: any) => {
      setIsMouseDown(false);
      if (isMouseDownRef.current) {
        endHandler(e);
      }
    },
    [endHandler],
  );

  const alignOffsetToCell = useCallback(
    // 인덱스 넣으면 위치 옮겨줌
    (cellIndex: number) => {
      setOffsetState((prevOffsetState) => {
        return {
          ...prevOffsetState,
          offset: -1 * (cellIndex - 1) * cellHeight,
        };
      });
    },
    [cellHeight],
  );

  const getCurrentSelectionIndex = useCallback(
    (offset: number) => {
      // 오프셋을 입력 받으면 몇번쨰  인덱스인지 알려줌
      return Math.abs(Math.round(offset / cellHeight)) + 1;
    },
    [cellHeight],
  );

  // ********* HELPER FUNCTIONS ********* //
  const getCurrentSelection = useCallback(
    // cells 배열에서 현재 값 리턴함
    (offset: number, numbers: Array<number | null>) => {
      return numbers[Math.abs(Math.round(offset / cellHeight)) + 1];
    },
    [cellHeight],
  );

  useEffect(() => {
    const numbers: Array<null | number> = [null];
    for (let i = 0; i < numCells; i += 1) {
      numbers.push(minValue + i);
    }
    if (JSON.stringify(numbers) !== JSON.stringify(offsetState.cellContents)) {
      setOffsetState((prev) => {
        return { ...prev, cellContents: numbers };
      });

      if (reset && setReset && resetNum) {
        const newIdx = numbers.findIndex((item) => item === resetNum);
        alignOffsetToCell(newIdx);
        setReset(false);
      }
    }
  }, [
    alignOffsetToCell,
    minValue,
    numCells,
    offsetState.cellContents,
    reset,
    resetNum,
    setOffsetState,
    setReset,
    value,
  ]);

  useEffect(() => {
    offsetStateRef.current = offsetState;
    const currentSelection = getCurrentSelection(
      offsetState.offset,
      offsetState.cellContents,
    );
    if (currentSelection !== null && currentSelection !== value) {
      onChange(currentSelection);
    }
  }, [getCurrentSelection, value, offsetState, onChange]);

  useEffect(() => {
    lastClientYRef.current = lastClientY;
  }, [lastClientY]);

  useEffect(() => {
    if (!isTouchInProgress && isTouchInProgressRef.current) {
      const { offset } = offsetStateRef.current;
      const currentSelectionIndex = getCurrentSelectionIndex(offset);
      alignOffsetToCell(currentSelectionIndex);
    }
    isTouchInProgressRef.current = isTouchInProgress;
  }, [alignOffsetToCell, getCurrentSelectionIndex, isTouchInProgress]);

  useEffect(() => {
    if (!isMouseDown && isMouseDownRef.current) {
      const { offset } = offsetStateRef.current;
      const currentSelectionIndex = getCurrentSelectionIndex(offset);
      alignOffsetToCell(currentSelectionIndex);
    }
    isMouseDownRef.current = isMouseDown;
  }, [alignOffsetToCell, getCurrentSelectionIndex, isMouseDown]);

  // ********* MOUSE AND KEYBOARD EFFECTS ********* //

  useEffect(() => {
    // set up and teardown listeners for keyboard and mouse input
    window.addEventListener('mousemove', mouseMoveHandler);
    window.addEventListener('mouseup', mouseUpHandler);
    return () => {
      window.removeEventListener('mousemove', mouseDownHandler);
      window.removeEventListener('mouseup', mouseUpHandler);
    };
  }, [mouseDownHandler, mouseMoveHandler, mouseUpHandler]);

  // ********* RENDER ********* //

  const cells = offsetState.cellContents.map((item) => {
    return (
      <div className="rdp-cell" key={item}>
        {item === null ? null : toTwoDigitString(item)}
      </div>
    );
  });
  return (
    <div
      className="rdp-column-container"
      onTouchMove={moveHandler}
      onTouchStart={startHandler}
      onTouchEnd={endHandler}
      onMouseDown={mouseDownHandler}
      ref={containerRef}
      role="slider"
      aria-valuemax={maxValue}
      aria-valuemin={minValue}
      aria-valuenow={value}
      tabIndex={0}
    >
      <div className="rdp-masked-div">
        <hr className="rdp-reticule" style={{ top: cellHeight - 1 }} />
        <hr className="rdp-reticule" style={{ top: cellHeight * 2 - 1 }} />
        <div
          className="rdp-column"
          style={{ top: offsetState.offset || 0 }}
          ref={slideyRef}
        >
          {cells}
        </div>
      </div>
    </div>
  );
}

export default DatePickerColumn;
